import { TemplateStep } from '@/@typespaces/interfaces/template.interface';
import { Query } from '@/@typespaces/types/query.types';
import TemplateCategoriesClient from '@/api/template-categories.api';
import TemplatesClient from '@/api/templates.api';
import { RootState } from '@/store';
import { TemplatesActionTypes } from '@/store/modules/templates/action-types';
import { TemplatesMutationTypes } from '@/store/modules/templates/mutation-types';
import { ActionContext, ActionTree } from 'vuex';
import { Mutations } from './mutations';
import { State } from './state';

const templateClient = new TemplatesClient();
const categoriesClient = new TemplateCategoriesClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [TemplatesActionTypes.FETCH_TEMPLATES]({ commit }: AugmentedActionContext, payload: Query): void;
  [TemplatesActionTypes.FETCH_TEMPLATE_CATEGORIES]({ commit }: AugmentedActionContext): void;
  [TemplatesActionTypes.SAVE_TEMPLATE_STEP]({ commit }: AugmentedActionContext, payload: TemplateStep): void;
  [TemplatesActionTypes.CLEAR_TEMPLATE_STEP]({ commit }: AugmentedActionContext): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [TemplatesActionTypes.FETCH_TEMPLATES]({ commit }, payload: Query) {
    commit(TemplatesMutationTypes.TEMPLATES_LOADING);
    try {
      const data = await templateClient.fetchTemplates(payload);
      commit(TemplatesMutationTypes.FETCH_TEMPLATES, data);
      commit(TemplatesMutationTypes.TEMPLATES_SUCCEEDED);
    } catch (error) {
      commit(TemplatesMutationTypes.TEMPLATES_ERROR, String(error));
      throw new Error(String(error));
    }
  },

  async [TemplatesActionTypes.FETCH_TEMPLATE_CATEGORIES]({ commit }) {
    commit(TemplatesMutationTypes.TEMPLATES_LOADING);
    try {
      const data = await categoriesClient.fetchTemplateCategories();
      commit(TemplatesMutationTypes.FETCH_TEMPLATE_CATEGORIES, data);
      commit(TemplatesMutationTypes.TEMPLATES_SUCCEEDED);
    } catch (error) {
      commit(TemplatesMutationTypes.TEMPLATES_ERROR, String(error));
      throw new Error(String(error));
    }
  },

  [TemplatesActionTypes.SAVE_TEMPLATE_STEP]({ commit }, payload) {
    commit(TemplatesMutationTypes.SAVE_TEMPLATE_STEP, payload);
  },

  [TemplatesActionTypes.CLEAR_TEMPLATE_STEP]({ commit }) {
    commit(TemplatesMutationTypes.CLEAR_TEMPLATE_STEP);
  },
};
