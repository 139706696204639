export enum ThirdPartyCodeModalTitles {
  BLOCK = 'Вы уверены, что хотите заблокировать сторонний код?',
  UNBLOCK = 'Вы уверены, что хотите разблокировать сторонний код?',
  BLOCK_ALL = 'Вы уверены, что хотите заблокировать выбранный сторонний код?',
  UNBLOCK_ALL = 'Вы уверены, что хотите разблокировать выбранный сторонний код?',
  DELETE = 'Вы уверены, что хотите удалить сторонний код?',
  DELETE_ALL = 'Вы уверены, что хотите удалить выбранный сторонний код?',
}

export enum RolesAndAccessModalTitles {
  DELETE = 'Вы уверены, что хотите удалить пользователя?',
  BLOCK = 'Вы уверены, что хотите заблокировать пользователя?',
  UNBLOCK = 'Вы уверены, что хотите разблокировать пользователя?',
  BLOCK_ALL = 'Вы уверены, что хотите заблокировать выбранных пользователей?',
  UNBLOCK_ALL = 'Вы уверены, что хотите разблокировать выбранных пользователей?',
  DELETE_ALL = 'Вы уверены, что хотите удалить выбранных пользователей?',
}

export enum CustomersModalTitles {
  DELETE = 'Вы уверены, что хотите удалить клиента?',
  BLOCK = 'Вы уверены, что хотите заблокировать клиента?',
  UNBLOCK = 'Вы уверены, что хотите разблокировать клиента?',
  BLOCK_ALL = 'Вы уверены, что хотите заблокировать выбранных клиентов?',
  UNBLOCK_ALL = 'Вы уверены, что хотите разблокировать выбранных клиентов?',
  DELETE_ALL = 'Вы уверены, что хотите удалить выбранных клиентов?',
}

export enum OrdersModalTitles {
  DELETE = 'Вы уверены, что хотите удалить заказ?',
  BLOCK = 'Вы уверены, что хотите заблокировать заказ?',
  UNBLOCK = 'Вы уверены, что хотите разблокировать заказ?',
  BLOCK_ALL = 'Вы уверены, что хотите заблокировать выбранные заказы?',
  UNBLOCK_ALL = 'Вы уверены, что хотите разблокировать выбранные заказы?',
  DELETE_ALL = 'Вы уверены, что хотите удалить выбранные заказы?',
}

export enum CategoriesTitles {
  DELETE = 'Вы уверены, что хотите удалить категорию?',
}
export enum FeaturesTitles {
  DELETE = 'Вы уверены, что хотите удалить характеристику?',
}

export enum VariantsTitles {
  DELETE = 'Вы уверены, что хотите удалить вариант?',
}

export enum OrderTitles {
  DELETE = 'Вы уверены, что хотите удалить заказ?',
  DELETE_ALL = 'Вы уверены, что хотите удалить заказы?',
}

export enum ProductsTitles {
  DELETE_ALL = 'Вы уверены, что хотите удалить товары?',
  DELETE = 'Вы уверены, что хотите удалить товар?',
  SHOW_MANY = 'Вы уверены, что хотите показать выбранные товары?',
  HIDE_MANY = 'Вы уверены, что хотите скрыть выбранные товары?',
  SHOW = 'Вы уверены, что хотите показать товар?',
  HIDE = 'Вы уверены, что хотите скрыть товар?',
  COPY = 'Вы уверены, что хотите дублировать товар?',
}

export enum ContactsModalTitles {
  DELETE_CONTACT = 'Вы уверены, что хотите удалить контакт?',
  DELETE_ADDRESS = 'Вы уверены, что хотите удалить адрес?',
  DELETE_EMAIL = 'Вы уверены, что хотите удалить дополнительный email?',
}

export enum ProjectModalTitles {
  DELETE = 'Вы уверены, что хотите удалить проект?',
  BLOCK = 'Вы уверены, что хотите заблокировать проект?',
  COPY = 'Вы уверены, что хотите дублировать проект?',
  UNBLOCK = 'Вы уверены, что хотите разблокировать проект?',
  BLOCK_ALL = 'Вы уверены, что хотите заблокировать выбранные проекты?',
  UNBLOCK_ALL = 'Вы уверены, что хотите разблокировать выбранные проекты?',
  DELETE_ALL = 'Вы уверены, что хотите удалить выбранные проекты?',
  DISCONNECT_DOMAIN = 'Вы уверены, что хотите удалить и отключить домен?',
  CHANGE_MAIN_DOMAIN = 'Вы уверены, что хотите сделать домен основным?',
}

export enum SettingsModalTitles {
  DELETE_ADDITIONAL_DOMAIN = 'Вы уверены, что хотите удалить дополнительный домен?',
  DOMAIN = 'Вы уверены, что хотите удалить домен?',
}

export enum WarehouseModalTitles {
  DELETE = 'Вы уверены, что хотите удалить склад?',
  BLOCK = 'Вы уверены, что хотите заблокировать склад?',
  UNBLOCK = 'Вы уверены, что хотите разблокировать склад?',
  BLOCK_ALL = 'Вы уверены, что хотите заблокировать выбранные склады?',
  UNBLOCK_ALL = 'Вы уверены, что хотите разблокировать выбранные склады?',
  DELETE_ALL = 'Вы уверены, что хотите удалить выбранные склады?',
}

export enum NewsAndBlogModalTitles {
  DELETE = 'Вы уверены, что хотите удалить пост?',
  COPY = 'Вы уверены, что хотите создать копию поста?',
  DELETE_ALL = 'Вы уверены, что хотите удалить выбранные посты?',
  TRASH = 'Вы уверены, что хотите отправить пост в корзину?',
  DELETE_AUTHOR = 'Вы уверены, что хотите удалить автора?',
}

export enum RubricsModalTitles {
  DELETE = 'Вы уверены, что хотите удалить рубрику?',
  DELETE_ALL = 'Вы уверены, что хотите удалить выбранные рубрики?',
}
export enum CommentsModalTitles {
  DELETE = 'Вы уверены, что хотите удалить комментарий?',
  PUBLIC = 'Вы уверены, что хотите опубликовать комментарий?',
  UN_PUBLIC = 'Вы уверены, что хотите снять с публикации комментарий?',
}

export enum MediaModalTitles {
  DELETE = 'Вы уверены, что хотите удалить файл?',
}
export enum DeliveryModalTitles {
  DELETE = 'Вы уверены, что хотите удалить доставку',
}

export enum ReviewModalTitles {
  DELETE = 'Вы уверены, что хотите удалить отзыв',
  PUBLIC = 'Вы уверены, что хотите опубликовать отзыв?',
  UN_PUBLIC = 'Вы уверены, что хотите снять с публикации отзыв?',
}
