export enum AuthActionTypes {
  SIGN_IN = 'SIGN_IN',
  LOG_OUT = 'LOG_OUT',
  REGISTRATION = 'REGISTRATION',
  SIGN_UP = 'SIGN_UP',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  TAKE_TOKEN_FOR_DASHBOARD = 'TAKE_TOKEN_FOR_DASHBOARD',
  TAKE_TOKEN_FOR_PROJECT = 'TAKE_TOKEN_FOR_PROJECT',
  INVITATION = 'INVITATION',
  RECOVERY_PASSWORD = 'RECOVERY_PASSWORD',
  RESTORE_PASSWORD = 'RESTORE_PASSWORD',
  RE_CONFIRMATION_REGISTER = 'RE_CONFIRMATION_REGISTER',
  CONFIRM_ACCOUNT = 'CONFIRM_ACCOUNT',
}
