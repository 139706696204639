import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { AuthActionTypes } from '@/store/modules/auth/action-types';
import store from '@/store';
import router from '@/router';

export default abstract class InstanceHttpClient {
  protected readonly instance: AxiosInstance;

  public constructor(parameter: string) {
    const API_STRING = `${window.location.origin}/api/${parameter}`;
    this.instance = axios.create({
      baseURL: API_STRING,
    });
    this.instance.interceptors.request.use((config) => {
      // eslint-disable-next-line no-param-reassign
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${store().getters.getAccessToken}`,
        'x-tenant-id': store().getters.getCurrentProjectTenant || '',
      };
      return config;
    });
    this.instance.interceptors.response.use(
      (value) => value,
      async (error) => {
        const originalRequest = error.config;
        const isAccessRefreshRequest =
          error.response.status === 401 && error.config && !error.config._isRetry && error.config.url !== 'scs/login';
        if (isAccessRefreshRequest) {
          originalRequest._isRetry = true;
          try {
            const currentProjectId = store().getters.getCurrentProject?._id || null;
            const response = await axios.post(
              `${window.location.origin}/api/auth/scs/refresh-token`,
              {
                project_id: currentProjectId,
              },
              {
                withCredentials: true,
              }
            );
            // @ts-ignore
            store().dispatch(AuthActionTypes.REFRESH_TOKEN, response.data.access_token);
            return this.instance.request(originalRequest);
          } catch (e) {
            // @ts-ignore
            store().dispatch(AuthActionTypes.LOG_OUT);
            router.push({ name: 'SignIn' });
          }
        }
        if (typeof error.response.data.message === 'object') {
          if (Array.isArray(error.response.data.message.message)) {
            throw error.response.data.message.message.join(', ');
          } else {
            throw error.response.data.message.message;
          }
        }
        throw error.response.data.message;
      }
    );
  }

  async apiCall<T>(config: AxiosRequestConfig) {
    try {
      const { data } = await this.instance.request<T>(config);
      return data;
    } catch (error) {
      throw new Error(error as string);
    }
  }
}
