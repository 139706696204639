import { CustomerData } from '@/@typespaces/interfaces/customer.interface';
import { Query } from '@/@typespaces/types/query.types';
import InstanceHttpClient from '../instance';

export default class CustomersClient extends InstanceHttpClient {
  constructor() {
    super('shop/customers');
  }

  async getCustomers(data: { projectId: string; query: Query }): Promise<CustomerData> {
    const { query } = data;
    try {
      return await this.apiCall({
        method: 'GET',
        params: query,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async editCustomer(data: { userId: string; location: string }) {
    try {
      await this.apiCall({
        method: 'PATCH',
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
