import { Query } from '@/@typespaces/types/query.types';
import { IAuthor, INewsAndBlog, INewsAndBlogData } from '@/@typespaces/interfaces/newsAndBlog.interfaces';
import InstanceHttpClient from './instance';

export default class NewsAndBlogApi extends InstanceHttpClient {
  constructor() {
    super('content/');
  }

  async fetchNewsAndBlog(data: { projectId: string; query: Query }): Promise<INewsAndBlogData> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: 'posts',
        params: data.query,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async getOneBlog(id: string): Promise<INewsAndBlog> {
    try {
      return await this.apiCall({
        method: 'GET',
        headers: {
          post_id: id,
        },
        url: `posts/${id}`,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async setStatus(data: { blogId: string; status: string }) {
    try {
      await this.apiCall({
        method: 'PATCH',
        headers: {
          post_id: data.blogId,
        },
        url: `posts/${data.blogId}/set-status`,
        data: { status: data.status },
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async deleteBlog(id: string) {
    try {
      return await this.apiCall({
        method: 'DELETE',
        headers: {
          post_id: id,
        },
        url: `posts/${id}`,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async editBlog(data: INewsAndBlog) {
    try {
      await this.apiCall({
        method: 'PUT',
        headers: {
          post_id: data._id,
        },
        url: `posts/${data._id}`,
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async createBlog(data: INewsAndBlog): Promise<INewsAndBlog> {
    try {
      return await this.apiCall({
        method: 'POST',
        url: '/posts',
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async copyPost(post_id: string): Promise<INewsAndBlog> {
    try {
      return await this.apiCall({
        method: 'POST',
        url: `/posts/copy-post`,
        data: { post_id },
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  // Authors
  async fetchNewsAndBlogAuthors(data: { query: Query }): Promise<IAuthor[]> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: '/authors',
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async newsAndBlogEditAuthor(data: Partial<IAuthor>) {
    try {
      await this.apiCall({
        method: 'PUT',
        url: `authors/${data._id}`,
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async deleteAuthor(id: string) {
    try {
      await this.apiCall({
        method: 'DELETE',
        headers: {
          author_id: id,
        },
        url: `authors/${id}`,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async updateCover(data: { postId: string; fileId: string }) {
    const { postId, fileId } = data;
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `posts/${postId}/update-cover`,
        data: { fileId },
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
