import Status from '@/@typespaces/enum/status.enum';
import { User } from '@/@typespaces/interfaces/users.interface';

export interface State {
  users: User[];
  error: string | null;
  status: Status;
}

export const state: State = {
  users: [],
  error: null,
  status: Status.IDLE,
};
