import Status from '@/@typespaces/enum/status.enum';
import { IRubric, IRubricsData } from '@/@typespaces/interfaces/rubric.interfaces';
import { RubricsMutationTypes } from '@/store/modules/rubrics/mutation-types';
import { MutationTree } from 'vuex';
import { State } from './state';

export type Mutations<S = State> = {
  [RubricsMutationTypes.FETCH_RUBRICS](state: S, payload: IRubricsData): void;
  [RubricsMutationTypes.EDIT_RUBRIC](state: S, payload: IRubric): void;
  [RubricsMutationTypes.CREATE_RUBRIC](state: S, payload: IRubric): void;
  [RubricsMutationTypes.REMOVE_RUBRIC](state: S, payload: string): void;
  [RubricsMutationTypes.REMOVE_MANY_RUBRICS](state: S, payload: string[]): void;
  // Status
  [RubricsMutationTypes.RUBRICS_ERROR](state: S, payload: string): void;
  [RubricsMutationTypes.RUBRICS_LOADING](state: S): void;
  [RubricsMutationTypes.RUBRICS_SUCCEEDED](state: S, payload: string): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [RubricsMutationTypes.FETCH_RUBRICS](state: State, payload: IRubricsData) {
    state.data = payload.data.categories;
    state.dataIds = payload.data.categoriesIds;
    state.total = payload.total;
    state.page = payload.page;
    state.pageSize = payload.pageSize;
  },
  [RubricsMutationTypes.EDIT_RUBRIC](state: State, payload: IRubric) {
    state.data = state.data.map((item) => {
      if (item._id === payload._id) {
        return payload;
      }
      return item;
    });
  },
  [RubricsMutationTypes.CREATE_RUBRIC](state: State, payload: IRubric) {
    state.data.push(payload);
    state.total += 1;
  },
  [RubricsMutationTypes.REMOVE_RUBRIC](state: State, payload: string) {
    const removeIndex = state.data.findIndex((item) => item._id === payload);
    if (removeIndex !== -1) {
      state.data.splice(removeIndex, 1);
      state.total -= 1;
    }
  },
  [RubricsMutationTypes.REMOVE_MANY_RUBRICS](state: State, payload: string[]) {
    state.data = state.data.filter((item) => !payload.includes(item._id));
    state.total -= payload.length;
  },
  // Status
  [RubricsMutationTypes.RUBRICS_ERROR](state: State, payload: string) {
    state.error = payload;
    state.status = Status.IDLE;
  },
  [RubricsMutationTypes.RUBRICS_SUCCEEDED](state: State) {
    state.status = Status.SUCCEEDED;
  },
  [RubricsMutationTypes.RUBRICS_LOADING](state: State) {
    state.status = Status.LOADING;
  },
};
