import { Customer } from '@/@typespaces/interfaces/customer.interface';
import Status from '@/@typespaces/enum/status.enum';

export interface State {
  data: Customer[];
  ids: string[];
  error: null | string;
  status: Status;
  page: number;
  pageSize: number;
  total: number;
}

export const state: State = {
  data: [],
  ids: [],
  page: 1,
  pageSize: 10,
  total: 0,
  error: null,
  status: Status.IDLE,
};
