import { Identity } from '@/@typespaces/interfaces/identity.interface';
import { RootState } from '@/store';
import { GetterTree } from 'vuex';
import { State } from './state';

export type Getters = {
  getIdentity(state: State): Identity | null;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getIdentity: (state) => state.identity,
};
