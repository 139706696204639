<template>
  <div class="blank-layout">
    <slot />
  </div>
</template>
<script setup lang="ts"></script>
<style scoped>
.blank-layout {
  height: 100vh;
}
</style>
