import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { FiltersQuery } from '@/@typespaces/types/query.types';
import { State } from './state';

export type Getters = {
  getSearchString(state: State): string;
  getFilter(state: State): FiltersQuery[];
  getCollapsedMenu(state: State): boolean;
  getConfirmModal(state: State): boolean;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getSearchString: (state) => state.searchString,
  getFilter: (state) => state.filter,
  getCollapsedMenu: (state) => state.collapsed,
  getConfirmModal: (state) => state.confirmModal,
};
