import { FolderOutlined, HomeOutlined, SettingOutlined, WalletOutlined } from '@ant-design/icons-vue';
import { SideBar } from '@/@typespaces/types/sidebar.types';

const COMMERCE_MENU = [
  {
    name: 'CustomersPage',
    title: 'Пользователи',
    query: {
      page: 1,
      pageSize: 10,
      _sort: 'surname:ASC',
    },
    doIt: 'read',
    subject: 'Users',
  },
  {
    name: 'Orders',
    title: 'Заказы',
    query: {
      page: 1,
      pageSize: 10,
      _sort: 'created_at:ASC',
    },
    doIt: 'read',
    subject: 'Orders',
  },
  {
    name: 'Products',
    title: 'Товары',
    query: {
      page: 1,
      limit: 10,
    },
    doIt: 'read',
    subject: 'Products',
  },
  {
    name: 'Warehouses',
    title: 'Склады',
    doIt: 'read',
    subject: 'Stores',
    query: {
      page: 1,
      limit: 10,
    },
  },
  {
    name: 'Delivery',
    title: 'Доставка',
    doIt: 'read',
    subject: 'Stores',
  },
  {
    name: 'ProjectReviews',
    title: 'Отзывы',
    doIt: 'read',
    subject: 'Reviews',
  },
];

const CONTENT_MENU = [
  {
    name: 'NewsAndBlog',
    title: 'Новости и блог',
    doIt: 'read',
    subject: 'Media',
    query: {
      page: 1,
      pageSize: 10,
      _sort: 'title:ASC',
    },
  },
  {
    name: 'Rubrics',
    title: 'Рубрики',
    doIt: 'read',
    subject: 'Media',
    query: {
      page: 1,
      pageSize: 10,
      _sort: 'title:ASC',
    },
  },
  {
    name: 'ProjectComment',
    title: 'Комментарии',
    query: {
      page: 1,
      pageSize: 10,
      _sort: 'createdAt:ASC',
    },
    doIt: 'read',
    subject: 'Comments',
  },
];

const SETTINGS_MENU = [
  {
    name: 'GeneralSettings',
    title: 'Общие настройки',
    doIt: 'read',
    subject: 'Site_Settings',
  },
  {
    name: 'RolesAndAccess',
    title: 'Роли и доступ',
    query: {
      page: 1,
      pageSize: 10,
      _sort: 'identifier:ASC',
    },
    doIt: 'read',
    subject: 'Roles',
  },
  {
    name: 'Integration',
    title: 'Интеграции',
    doIt: 'read',
    subject: 'Integrations',
  },
  {
    name: 'Notification',
    title: 'Уведомления',
    doIt: 'read',
    subject: 'Notifications',
  },
  {
    name: 'Contacts',
    title: 'Контакты и соц.сети',
    doIt: 'read',
    subject: 'Contacts',
  },
  {
    name: 'Seo',
    title: 'SEO',
    doIt: 'read',
    subject: 'Seo',
  },
  {
    name: 'ThirdPartyCode',
    title: 'Сторонний код',
    doIt: 'read',
    subject: 'Third_Party',
  },
  {
    name: 'Media',
    title: 'Файлы и медиа',
    doIt: 'read',
    subject: 'Media',
    query: {
      page: 1,
      pageSize: 10,
    },
  },
  {
    name: 'ConfidentialPolicy',
    title: 'Конфиденциальность',
    doIt: 'read',
    subject: 'Media',
  },
  {
    name: 'UserAgreement',
    title: 'Пользовательское соглашение',
    doIt: 'read',
    subject: 'Media',
  },
];

// eslint-disable-next-line import/prefer-default-export
export const sidebarRouteNames: SideBar[] = [
  {
    name: 'ProjectEdit',
    title: 'Проект',
    icon: HomeOutlined,
  },
  {
    name: '',
    title: 'Коммерция',
    icon: WalletOutlined,
    subMenu: COMMERCE_MENU,
  },

  {
    name: '',
    title: 'Контент',
    icon: FolderOutlined,
    subMenu: CONTENT_MENU,
  },
  {
    name: '',
    title: 'Настройки сайта',
    icon: SettingOutlined,
    subMenu: SETTINGS_MENU,
  },
];
