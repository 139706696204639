import { IWarehouse } from '@/@typespaces/interfaces/warehouses.interface';
import { Query } from '@/@typespaces/types/query.types';
import InstanceHttpClient from '../instance';

export default class WarehousesClient extends InstanceHttpClient {
  constructor() {
    super('shop/warehouses');
  }

  async getWarehouses(data?: { projectId: string; query: Query }): Promise<IWarehouse[]> {
    try {
      return await this.apiCall({
        method: 'GET',
        params: data?.query,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async getOneWarehouse(id: string): Promise<IWarehouse> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: id,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async removeWarehouse(id: string) {
    try {
      await this.apiCall({
        method: 'DELETE',
        url: id,
      });
      return { success: true };
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async createWarehouse(body: IWarehouse): Promise<IWarehouse> {
    try {
      return await this.apiCall({
        method: 'POST',
        data: body,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async editWarehouse(body: IWarehouse): Promise<{ success: true }> {
    try {
      return await this.apiCall({
        method: 'PUT',
        url: body.id,
        data: body,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
