/* eslint-disable */
import {
  baseQueryOperatorSelectOptions,
  dateQueryOperatorSelectOptions,
  statusQueryOperatorSelectOptions,
} from '@/@constants/queryOperatorSelectOptions.constants';
import { Ref } from 'vue';

export const queryOperatorInterpreter = (value: string, operator: Ref) => {
  switch (value) {
    case 'date':
      operator.value = '_lt';
      return dateQueryOperatorSelectOptions;
    case 'processed':
    case 'order_status':
    case 'status':
    case 'blocked':
    case 'published':
    case 'payment':
      operator.value = '_eq';
      return statusQueryOperatorSelectOptions;
    default:
      return baseQueryOperatorSelectOptions;
  }
};
