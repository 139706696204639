import { IProjectSettings } from '@/@typespaces/interfaces/projectSettings.interface';
import InstanceHttpClient from './instance';

export default class ProjectSettingsClient extends InstanceHttpClient {
  constructor() {
    super('project-settings');
  }

  async getSettings(id: string): Promise<IProjectSettings> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: id,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async editSettings(settings: { id: string; data: IProjectSettings }): Promise<{ success: true }> {
    const { id, data } = settings;
    try {
      return await this.apiCall({
        method: 'PUT',
        url: id,
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
