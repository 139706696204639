import { Profile } from '@/@typespaces/interfaces/profile.interface';
import { IRole } from '@/@typespaces/interfaces/rolesAndAccess.interface';

export interface State {
  data: Profile | null;
  role: IRole | null;
}

export const state: State = {
  data: null,
  role: null,
};
