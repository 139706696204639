import { Variant } from '@/@typespaces/interfaces/category.interface';
import { CreateVariantPayload } from '@/@typespaces/types/form/variants.types';
import InstanceHttpClient from '../instance';

export default class VariantsApi extends InstanceHttpClient {
  constructor() {
    super('shop/variants');
  }

  async createVariant(data: CreateVariantPayload): Promise<Variant> {
    try {
      return await this.apiCall({
        method: 'POST',
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async editVariant(payload: { id: string; data: any }): Promise<Variant> {
    const { id, data } = payload;
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: id,
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async removeVariant(data: { id: string; category_id: string }) {
    const { id, category_id } = data;
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `${id}/remove-from-category`,
        data: { category_id },
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
