import Status from '@/@typespaces/enum/status.enum';
import { MutationTree } from 'vuex';
import { State } from './state';
import { AuthMutationTypes } from './mutation-types';

export type Mutations<S = State> = {
  [AuthMutationTypes.SIGN_IN](state: S, payload: { email: string; access_token: string }): void;
  [AuthMutationTypes.SIGN_UP](state: S, payload: { email: string; access_token: string }): void;
  [AuthMutationTypes.INVITATION](state: S, payload: { access_token: string }): void;
  [AuthMutationTypes.LOG_OUT](state: S): void;
  [AuthMutationTypes.AUTH_LOADING](state: S): void;
  [AuthMutationTypes.AUTH_SUCCEEDED](state: S): void;
  [AuthMutationTypes.AUTH_ERROR](state: S): void;
  [AuthMutationTypes.REFRESH_TOKEN](state: S, payload: string): void;
  [AuthMutationTypes.TAKE_TOKEN_FOR_PROJECT](state: S, payload: string): void;
  [AuthMutationTypes.TAKE_TOKEN_FOR_DASHBOARD](state: S, payload: string): void;
  [AuthMutationTypes.REGISTRATION](
    state: S,
    payload: {
      email: string;
      access_token: string;
    }
  ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [AuthMutationTypes.SIGN_IN](state: State, payload) {
    state.accessToken = payload.access_token;
    state.email = payload.email;
  },

  [AuthMutationTypes.SIGN_UP](state: State, payload) {
    state.accessToken = payload.access_token;
    state.email = payload.email;
  },
  [AuthMutationTypes.INVITATION](state: State, payload) {
    state.accessToken = payload.access_token;
  },
  [AuthMutationTypes.REGISTRATION](state: State, payload) {
    state.accessToken = payload.access_token;
    state.email = payload.email;
  },
  [AuthMutationTypes.LOG_OUT](state: State) {
    state.accessToken = null;
  },
  [AuthMutationTypes.REFRESH_TOKEN](state: State, payload: string) {
    state.accessToken = payload;
  },
  [AuthMutationTypes.TAKE_TOKEN_FOR_PROJECT](state: State, payload: string) {
    state.accessToken = payload;
  },
  [AuthMutationTypes.TAKE_TOKEN_FOR_DASHBOARD](state: State, payload: string) {
    state.accessToken = payload;
  },

  // Status
  [AuthMutationTypes.AUTH_LOADING](state: State) {
    state.status = Status.LOADING;
  },
  [AuthMutationTypes.AUTH_SUCCEEDED](state: State) {
    state.status = Status.SUCCEEDED;
  },
  [AuthMutationTypes.AUTH_ERROR](state: State) {
    state.status = Status.FAILED;
  },
};
