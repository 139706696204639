import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store';
import { ProductsActionTypes } from '@/store/modules/products/action-types';
import { ProductsMutationTypes } from '@/store/modules/products/mutation-types';
import ProductsApi from '@/api/shop/products.api';
import { Product } from '@/@typespaces/interfaces/products.interface';
import { Query } from '@/@typespaces/types/query.types';
import { Mutations } from './mutations';
import { State } from './state';

const api = new ProductsApi();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [ProductsActionTypes.GET_PRODUCTS](
    { commit }: AugmentedActionContext,
    payload: { projectId: string; query: Query }
  ): void;

  [ProductsActionTypes.EDIT_PRODUCT]({ commit }: AugmentedActionContext, payload: Product): void;

  [ProductsActionTypes.CREATE_PRODUCT]({ commit }: AugmentedActionContext, payload: Product): void;

  [ProductsActionTypes.DELETE_PRODUCT]({ commit }: AugmentedActionContext, id: string): void;

  [ProductsActionTypes.GET_ONE_PRODUCT]({ commit }: AugmentedActionContext, id: string): void;

  [ProductsActionTypes.DELETE_MANY_PRODUCTS]({ commit }: AugmentedActionContext, payload: string[]): void;

  [ProductsActionTypes.HIDE_MANY_PRODUCTS]({ commit }: AugmentedActionContext, payload: string[]): void;

  [ProductsActionTypes.SHOW_MANY_PRODUCTS]({ commit }: AugmentedActionContext, payload: string[]): void;

  [ProductsActionTypes.HIDE_PRODUCT]({ commit }: AugmentedActionContext, payload: string): void;

  [ProductsActionTypes.SHOW_PRODUCT]({ commit }: AugmentedActionContext, payload: string): void;

  [ProductsActionTypes.FILTER_PRODUCT_BY_CATEGORY]({ commit }: AugmentedActionContext, payload: string): void;

  [ProductsActionTypes.SELECT_CATEGORY]({ commit }: AugmentedActionContext, payload: string): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ProductsActionTypes.GET_PRODUCTS]({ commit }, payload: { projectId: string; query: Query }) {
    commit(ProductsMutationTypes.PRODUCTS_LOADING);
    try {
      const data = await api.getProducts(payload);
      commit(ProductsMutationTypes.PRODUCTS_SUCCEEDED);
      commit(ProductsMutationTypes.GET_PRODUCTS, data);
    } catch (e) {
      commit(ProductsMutationTypes.PRODUCTS_ERROR, e as string);
      throw new Error(e as string);
    }
  },
  async [ProductsActionTypes.FILTER_PRODUCT_BY_CATEGORY]({ commit }, payload: string) {
    try {
      commit(ProductsMutationTypes.FILTER_PRODUCT_BY_CATEGORY, payload);
    } catch (e) {
      commit(ProductsMutationTypes.PRODUCTS_ERROR, e as string);
      throw new Error(e as string);
    }
  },

  async [ProductsActionTypes.CREATE_PRODUCT]({ commit }, data: Product) {
    try {
      await api.createProduct(data);
    } catch (e) {
      throw new Error(e as string);
    }
  },

  async [ProductsActionTypes.GET_ONE_PRODUCT]({ commit }, id: string) {
    try {
      const data = await api.getOneProduct(id);
      commit(ProductsMutationTypes.GET_ONE_PRODUCT, data);
    } catch (e) {
      commit(ProductsMutationTypes.PRODUCTS_ERROR, e as string);
      throw new Error(e as string);
    }
  },
  async [ProductsActionTypes.EDIT_PRODUCT]({ commit }, data: Product) {
    try {
      const response = await api.editProduct(data);
      if (response.success) {
        commit(ProductsMutationTypes.EDIT_PRODUCT, data);
      }
    } catch (e) {
      commit(ProductsMutationTypes.PRODUCTS_ERROR, e as string);
      throw new Error(e as string);
    }
  },
  async [ProductsActionTypes.DELETE_PRODUCT]({ commit }, id: string) {
    commit(ProductsMutationTypes.PRODUCTS_LOADING);
    try {
      await api.deleteProduct(id);
      commit(ProductsMutationTypes.DELETE_PRODUCT, id);
      commit(ProductsMutationTypes.PRODUCTS_SUCCEEDED);
    } catch (e) {
      commit(ProductsMutationTypes.PRODUCTS_ERROR, e as string);
      throw new Error(e as string);
    }
  },
  async [ProductsActionTypes.DELETE_MANY_PRODUCTS]({ commit }, data: string[]) {
    commit(ProductsMutationTypes.PRODUCTS_LOADING);
    try {
      await api.deleteManyProducts(data);
      commit(ProductsMutationTypes.DELETE_MANY_PRODUCTS, data);
      commit(ProductsMutationTypes.PRODUCTS_SUCCEEDED);
    } catch (e) {
      commit(ProductsMutationTypes.PRODUCTS_ERROR, e as string);
      throw new Error(e as string);
    }
  },

  async [ProductsActionTypes.HIDE_MANY_PRODUCTS]({ commit }, data: string[]) {
    commit(ProductsMutationTypes.PRODUCTS_LOADING);
    try {
      await api.hideManyProducts(data);
      commit(ProductsMutationTypes.HIDE_MANY_PRODUCTS, data);
      commit(ProductsMutationTypes.PRODUCTS_SUCCEEDED);
    } catch (e) {
      commit(ProductsMutationTypes.PRODUCTS_ERROR, e as string);
      throw new Error(e as string);
    }
  },

  async [ProductsActionTypes.SHOW_MANY_PRODUCTS]({ commit }, data: string[]) {
    commit(ProductsMutationTypes.PRODUCTS_LOADING);
    try {
      await api.showManyProducts(data);
      commit(ProductsMutationTypes.SHOW_MANY_PRODUCTS, data);
      commit(ProductsMutationTypes.PRODUCTS_SUCCEEDED);
    } catch (e) {
      commit(ProductsMutationTypes.PRODUCTS_ERROR, e as string);
      throw new Error(e as string);
    }
  },

  async [ProductsActionTypes.SHOW_PRODUCT]({ commit }, data: string) {
    commit(ProductsMutationTypes.PRODUCTS_LOADING);
    try {
      await api.showProduct(data);
      commit(ProductsMutationTypes.SHOW_PRODUCT, data);
      commit(ProductsMutationTypes.PRODUCTS_SUCCEEDED);
    } catch (e) {
      commit(ProductsMutationTypes.PRODUCTS_ERROR, e as string);
      throw new Error(e as string);
    }
  },

  async [ProductsActionTypes.HIDE_PRODUCT]({ commit }, data: string) {
    commit(ProductsMutationTypes.PRODUCTS_LOADING);
    try {
      await api.hideProduct(data);
      commit(ProductsMutationTypes.HIDE_PRODUCT, data);
      commit(ProductsMutationTypes.PRODUCTS_SUCCEEDED);
    } catch (e) {
      commit(ProductsMutationTypes.PRODUCTS_ERROR, e as string);
      throw new Error(e as string);
    }
  },

  async [ProductsActionTypes.CLEAR_CURRENT_PRODUCT]({ commit }) {
    commit(ProductsMutationTypes.CLEAR_CURRENT_PRODUCT);
  },

  async [ProductsActionTypes.SELECT_CATEGORY]({ commit }, payload: string) {
    commit(ProductsMutationTypes.PRODUCTS_LOADING);
    try {
      const data = await api.getProducts({ query: { category: payload }, projectId: '' });
      commit(ProductsMutationTypes.PRODUCTS_SUCCEEDED);
      commit(ProductsMutationTypes.GET_PRODUCTS, data);
    } catch (e) {
      commit(ProductsMutationTypes.PRODUCTS_ERROR, e as string);
      throw new Error(e as string);
    }
  },
};
