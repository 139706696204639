export enum RolesAndAccessMutationTypes {
  FETCH_USERS = 'FETCH_USERS',
  CLEAR_USER_ROLE = 'CLEAR_USER_ROLE',
  DELETE_ONE_USER = 'DELETE_ONE_USER',
  INVITE_USER = 'INVITE_USER',
  EDIT_USER_ROLE = 'EDIT_USER_ROLE',
  BLOCK_USER = 'BLOCK_USER',
  UNBLOCK_USER = 'UNBLOCK_USER',
  GET_ROLES_PROJECT_GROUP = 'GET_ROLES_PROJECT_GROUP',

  // Status
  USERS_LOADING = 'USERS_LOADING',
  USERS_SUCCEEDED = 'USERS_SUCCEEDED',
  USERS_ERROR = 'USER_ERROR',
}
