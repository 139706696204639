import { IRubricsData } from '@/@typespaces/interfaces/rubric.interfaces';
import { dataMapper } from '@/utils/dataMapper';
import InstanceHttpClient from './instance';

export default class ContentSettingsApi extends InstanceHttpClient {
  constructor() {
    super('content/settings');
  }

  async editSettings(body: { type: string; value: boolean }): Promise<IRubricsData> {
    const data = dataMapper(body, true);
    try {
      return await this.apiCall({
        method: 'PATCH',
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
