import Status from '@/@typespaces/enum/status.enum';
import { IAnswer, IComment, ICommentData } from '@/@typespaces/interfaces/comment.interface';
import {
  ShortTemplate,
  TemplateCategories,
  TemplateData,
  TemplateStep,
} from '@/@typespaces/interfaces/template.interface';
import { MutationTree } from 'vuex';
import { TemplatesMutationTypes } from './mutation-types';
import { State } from './state';

export type Mutations<S = State> = {
  [TemplatesMutationTypes.FETCH_TEMPLATES](state: S, payload: TemplateData): void;
  [TemplatesMutationTypes.FETCH_TEMPLATE_CATEGORIES](state: S, payload: TemplateCategories[]): void;
  [TemplatesMutationTypes.SAVE_TEMPLATE_STEP](state: S, payload: TemplateStep): void;
  [TemplatesMutationTypes.CLEAR_TEMPLATE_STEP](state: S): void;

  // Status
  [TemplatesMutationTypes.TEMPLATES_LOADING](state: S): void;
  [TemplatesMutationTypes.TEMPLATES_SUCCEEDED](state: S): void;
  [TemplatesMutationTypes.TEMPLATES_ERROR](state: S, payload: string): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [TemplatesMutationTypes.FETCH_TEMPLATES](state: State, payload: TemplateData) {
    state.data = payload.data.templates;
    state.total = payload.total;
    state.page = payload.page;
    state.pageSize = payload.pageSize;
  },

  [TemplatesMutationTypes.FETCH_TEMPLATE_CATEGORIES](state: State, payload: TemplateCategories[]) {
    state.categories = payload;
  },
  [TemplatesMutationTypes.SAVE_TEMPLATE_STEP](state: State, payload) {
    state.templateStep = payload;
  },
  [TemplatesMutationTypes.CLEAR_TEMPLATE_STEP](state: State) {
    state.templateStep = null;
  },

  // Status
  [TemplatesMutationTypes.TEMPLATES_LOADING](state: State) {
    state.status = Status.LOADING;
  },
  [TemplatesMutationTypes.TEMPLATES_SUCCEEDED](state: State) {
    state.status = Status.SUCCEEDED;
  },
  [TemplatesMutationTypes.TEMPLATES_ERROR](state: State, payload: string) {
    state.error = payload;
    state.status = Status.FAILED;
  },
};
