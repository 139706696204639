import {
  ICreateProject,
  ICredentialsData,
  Project,
  ShortProject,
  ProjectData,
} from '@/@typespaces/interfaces/projects.interface';
import { BitrixIntegrationPayload } from '@/@typespaces/types/form/bitrixIntegration';
import { DocumentsDataPayload } from '@/@typespaces/types/form/documents.types';
import { UpdateSettingsType } from '@/@typespaces/types/form/settings-form.type';
import { Query } from '@/@typespaces/types/query.types';
import { UpdateFilePayload } from '@/@typespaces/types/updateFile.types';
import InstanceHttpClient from './instance';

export default class UserProjectsClient extends InstanceHttpClient {
  constructor() {
    super('projects');
  }

  async getProjects(query: Query): Promise<ProjectData> {
    try {
      return await this.apiCall({
        method: 'GET',
        params: query,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async getOneProject(id: string): Promise<Project> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: id,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async removeProject(id: string): Promise<void> {
    try {
      return await this.apiCall({
        method: 'DELETE',
        url: id,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async copyProject(id: string): Promise<Project> {
    try {
      return await this.apiCall({
        method: 'POST',
        url: `${id}/copy-project`,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async publishProject(id: string): Promise<{ success: true }> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: `${id}/publish`,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async unPublishProject(id: string): Promise<{ success: true }> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: `${id}/publish`,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async createProjects(body: ICreateProject): Promise<Project> {
    try {
      return await this.apiCall({
        method: 'POST',
        data: body,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async editProjects(body: UpdateSettingsType): Promise<ShortProject> {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: body.projectId,
        data: body.data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async updateProjectFile(data: UpdateFilePayload): Promise<ShortProject> {
    const { fileData, projectId } = data;
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `${projectId}/update-file`,
        data: fileData,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async setYoomoneyCredentials(data: ICredentialsData): Promise<{ success: true }> {
    const { projectId, shop_id, secret_key } = data;
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `${projectId}/set-yoomoney-cred`,
        data: {
          shop_id,
          secret_key,
        },
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async setBitrixCredentials(payload: BitrixIntegrationPayload) {
    const { projectId, data } = payload;
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `${projectId}/set-bitrix-params`,
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async updateDocuments(payload: DocumentsDataPayload) {
    const { projectId, data } = payload;
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `${projectId}/documents`,
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
