import {
  EditEmailTemplatePayload,
  GetEmailTemplate,
  NotificationsRecipientsPayload,
  NotificationSwitcherPayload,
  RestoreEmailTemplatePayload,
  RestoreSmsTemplatePayload,
  SendTestEmailPayload,
  SendTestSmsPayload,
  SmsTemplatePayload,
  UpdateEmailSettingsPayload,
  UpdateSmsPayload,
  UpdateSmsSettingsPayload,
} from '@/@typespaces/types/form/notifications-form.type';
import NotificationApi from '@/api/notifications.api';
import { RootState } from '@/store';
import { NotificationActionTypes } from '@/store/modules/notification/action-types';
import { NotificationsMutationTypes } from '@/store/modules/notification/mutation-types';
import { ActionContext, ActionTree } from 'vuex';
import { Mutations } from './mutations';
import { State } from './state';

const client = new NotificationApi();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [NotificationActionTypes.FETCH_NOTIFICATIONS]({ commit }: AugmentedActionContext, payload: string): void;

  [NotificationActionTypes.EDIT_EMAIL_NOTIFICATION_SWITCHER](
    { commit }: AugmentedActionContext,
    payload: NotificationSwitcherPayload
  ): void;

  [NotificationActionTypes.EDIT_SMS_NOTIFICATION_SWITCHER](
    { commit }: AugmentedActionContext,
    payload: NotificationSwitcherPayload
  ): void;

  [NotificationActionTypes.EDIT_EMAIL_RECIPIENTS](
    { commit }: AugmentedActionContext,
    payload: NotificationsRecipientsPayload
  ): void;

  [NotificationActionTypes.FETCH_EMAIL_TEMPLATE]({ commit }: AugmentedActionContext, payload: GetEmailTemplate): void;

  [NotificationActionTypes.RESTORE_EMAIL_TEMPLATE_SUBJECT](
    { commit }: AugmentedActionContext,
    payload: RestoreEmailTemplatePayload
  ): void;

  [NotificationActionTypes.RESTORE_EMAIL_TEMPLATE_MARKUP](
    { commit }: AugmentedActionContext,
    payload: RestoreEmailTemplatePayload
  ): void;

  [NotificationActionTypes.EDIT_EMAIL_TEMPLATE](
    { commit }: AugmentedActionContext,
    payload: EditEmailTemplatePayload
  ): void;

  [NotificationActionTypes.UPDATE_EMAIL_TEMPLATE_SETTINGS](
    { commit }: AugmentedActionContext,
    payload: UpdateEmailSettingsPayload
  ): void;

  [NotificationActionTypes.SEND_TEST_EMAIL]({ commit }: AugmentedActionContext, payload: SendTestEmailPayload): void;

  [NotificationActionTypes.CLEAR_EMAIL_TEMPLATE]({ commit }: AugmentedActionContext): void;

  // SMS
  [NotificationActionTypes.GET_SMS_TEMPLATE]({ commit }: AugmentedActionContext, payload: SmsTemplatePayload): void;

  [NotificationActionTypes.RESTORE_SMS_TEMPLATE](
    { commit }: AugmentedActionContext,
    payload: RestoreSmsTemplatePayload
  ): void;

  [NotificationActionTypes.SEND_TEST_SMS]({ commit }: AugmentedActionContext, payload: SendTestSmsPayload): void;

  [NotificationActionTypes.UPDATE_SMS_TEMPLATE]({ commit }: AugmentedActionContext, payload: UpdateSmsPayload): void;

  [NotificationActionTypes.UPDATE_SMS_SETTINGS](
    { commit }: AugmentedActionContext,
    payload: UpdateSmsSettingsPayload
  ): void;

  [NotificationActionTypes.CLEAR_SMS_TEMPLATE]({ commit }: AugmentedActionContext): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [NotificationActionTypes.FETCH_NOTIFICATIONS]({ commit }, payload: string) {
    commit(NotificationsMutationTypes.NOTIFICATIONS_LOADING);
    try {
      const response = await client.getNotifications(payload);
      commit(NotificationsMutationTypes.FETCH_NOTIFICATIONS, response);
      commit(NotificationsMutationTypes.NOTIFICATIONS_SUCCEEDED);
    } catch (error) {
      commit(NotificationsMutationTypes.NOTIFICATIONS_ERROR, (error as Error).message);
      throw new Error((error as Error).message);
    }
  },
  async [NotificationActionTypes.EDIT_EMAIL_NOTIFICATION_SWITCHER]({ commit }, payload: NotificationSwitcherPayload) {
    commit(NotificationsMutationTypes.NOTIFICATIONS_LOADING);
    try {
      await client.changeEmailNotificationsSwitchers(payload);
      commit(NotificationsMutationTypes.NOTIFICATIONS_SUCCEEDED);
    } catch (error) {
      commit(NotificationsMutationTypes.NOTIFICATIONS_ERROR, (error as Error).message);
      throw new Error((error as Error).message);
    }
  },
  async [NotificationActionTypes.EDIT_SMS_NOTIFICATION_SWITCHER]({ commit }, payload: NotificationSwitcherPayload) {
    commit(NotificationsMutationTypes.NOTIFICATIONS_LOADING);
    try {
      await client.changeSmsNotificationsSwitchers(payload);
      commit(NotificationsMutationTypes.NOTIFICATIONS_SUCCEEDED);
    } catch (error) {
      commit(NotificationsMutationTypes.NOTIFICATIONS_ERROR, (error as Error).message);
      throw new Error((error as Error).message);
    }
  },
  async [NotificationActionTypes.EDIT_EMAIL_RECIPIENTS]({ commit }, payload: NotificationsRecipientsPayload) {
    commit(NotificationsMutationTypes.NOTIFICATIONS_LOADING);
    try {
      await client.changeNotificationsRecipients(payload);
      commit(NotificationsMutationTypes.NOTIFICATIONS_SUCCEEDED);
    } catch (error) {
      commit(NotificationsMutationTypes.NOTIFICATIONS_ERROR, (error as Error).message);
      throw new Error((error as Error).message);
    }
  },
  async [NotificationActionTypes.FETCH_EMAIL_TEMPLATE]({ commit }, payload: GetEmailTemplate) {
    commit(NotificationsMutationTypes.NOTIFICATIONS_LOADING);
    try {
      const response = await client.fetchEmailTemplate(payload);
      commit(NotificationsMutationTypes.FETCH_EMAIL_TEMPLATE, response);
      commit(NotificationsMutationTypes.NOTIFICATIONS_SUCCEEDED);
    } catch (error) {
      commit(NotificationsMutationTypes.NOTIFICATIONS_ERROR, (error as Error).message);
      throw new Error((error as Error).message);
    }
  },
  async [NotificationActionTypes.RESTORE_EMAIL_TEMPLATE_MARKUP]({ commit }, payload: RestoreEmailTemplatePayload) {
    commit(NotificationsMutationTypes.NOTIFICATIONS_LOADING);
    try {
      const response = await client.restoreEmailTemplate(payload);
      commit(NotificationsMutationTypes.RESTORE_EMAIL_TEMPLATE_MARKUP, response);
      commit(NotificationsMutationTypes.NOTIFICATIONS_SUCCEEDED);
    } catch (error) {
      commit(NotificationsMutationTypes.NOTIFICATIONS_ERROR, (error as Error).message);
      throw new Error((error as Error).message);
    }
  },
  async [NotificationActionTypes.RESTORE_EMAIL_TEMPLATE_SUBJECT]({ commit }, payload: RestoreEmailTemplatePayload) {
    commit(NotificationsMutationTypes.NOTIFICATIONS_LOADING);
    try {
      const response = await client.restoreEmailTemplate(payload);
      commit(NotificationsMutationTypes.RESTORE_EMAIL_TEMPLATE_SUBJECT, response);
      commit(NotificationsMutationTypes.NOTIFICATIONS_SUCCEEDED);
    } catch (error) {
      commit(NotificationsMutationTypes.NOTIFICATIONS_ERROR, (error as Error).message);
      throw new Error((error as Error).message);
    }
  },
  async [NotificationActionTypes.EDIT_EMAIL_TEMPLATE]({ commit }, payload: EditEmailTemplatePayload) {
    commit(NotificationsMutationTypes.NOTIFICATIONS_LOADING);
    try {
      await client.editEmailTemplate(payload);
      commit(NotificationsMutationTypes.NOTIFICATIONS_SUCCEEDED);
      commit(NotificationsMutationTypes.NOTIFICATIONS_SUCCEEDED);
    } catch (error) {
      commit(NotificationsMutationTypes.NOTIFICATIONS_ERROR, (error as Error).message);
      throw new Error((error as Error).message);
    }
  },

  async [NotificationActionTypes.UPDATE_EMAIL_TEMPLATE_SETTINGS]({ commit }, payload: UpdateEmailSettingsPayload) {
    commit(NotificationsMutationTypes.NOTIFICATIONS_LOADING);
    try {
      await client.updateEmailClientSettings(payload);
      commit(NotificationsMutationTypes.UPDATE_EMAIL_TEMPLATE_SETTINGS, payload.data);
      commit(NotificationsMutationTypes.NOTIFICATIONS_SUCCEEDED);
    } catch (error) {
      commit(NotificationsMutationTypes.NOTIFICATIONS_ERROR, (error as Error).message);
      throw new Error((error as Error).message);
    }
  },

  async [NotificationActionTypes.SEND_TEST_EMAIL]({ commit }, payload: SendTestEmailPayload) {
    commit(NotificationsMutationTypes.NOTIFICATIONS_LOADING);
    try {
      await client.sendTestEmail(payload);
      commit(NotificationsMutationTypes.NOTIFICATIONS_SUCCEEDED);
      commit(NotificationsMutationTypes.NOTIFICATIONS_SUCCEEDED);
    } catch (error) {
      commit(NotificationsMutationTypes.NOTIFICATIONS_ERROR, (error as Error).message);
      throw new Error((error as Error).message);
    }
  },

  [NotificationActionTypes.CLEAR_EMAIL_TEMPLATE]({ commit }) {
    commit(NotificationsMutationTypes.CLEAR_EMAIL_TEMPLATE);
  },

  // Sms

  async [NotificationActionTypes.GET_SMS_TEMPLATE]({ commit }, payload: SmsTemplatePayload) {
    commit(NotificationsMutationTypes.NOTIFICATIONS_LOADING);
    try {
      const response = await client.getSmsTemplate(payload);
      commit(NotificationsMutationTypes.NOTIFICATIONS_SUCCEEDED);
      commit(NotificationsMutationTypes.GET_SMS_TEMPLATE, response);
    } catch (error) {
      commit(NotificationsMutationTypes.NOTIFICATIONS_ERROR, (error as Error).message);
      throw new Error((error as Error).message);
    }
  },

  async [NotificationActionTypes.RESTORE_SMS_TEMPLATE]({ commit }, payload: RestoreSmsTemplatePayload) {
    commit(NotificationsMutationTypes.NOTIFICATIONS_LOADING);
    try {
      const response = await client.restoreSmsTemplate(payload);
      commit(NotificationsMutationTypes.NOTIFICATIONS_SUCCEEDED);
      commit(NotificationsMutationTypes.RESTORE_SMS_TEMPLATE, response);
    } catch (error) {
      commit(NotificationsMutationTypes.NOTIFICATIONS_ERROR, (error as Error).message);
      throw new Error((error as Error).message);
    }
  },

  async [NotificationActionTypes.SEND_TEST_SMS]({ commit }, payload: SendTestSmsPayload) {
    commit(NotificationsMutationTypes.NOTIFICATIONS_LOADING);
    try {
      await client.sendTestSms(payload);
      commit(NotificationsMutationTypes.NOTIFICATIONS_SUCCEEDED);
    } catch (error) {
      commit(NotificationsMutationTypes.NOTIFICATIONS_ERROR, (error as Error).message);
      throw new Error((error as Error).message);
    }
  },
  async [NotificationActionTypes.UPDATE_SMS_TEMPLATE]({ commit }, payload: UpdateSmsPayload) {
    commit(NotificationsMutationTypes.NOTIFICATIONS_LOADING);
    try {
      await client.updateSmsTemplate(payload);
      commit(NotificationsMutationTypes.NOTIFICATIONS_SUCCEEDED);
    } catch (error) {
      commit(NotificationsMutationTypes.NOTIFICATIONS_ERROR, (error as Error).message);
      throw new Error((error as Error).message);
    }
  },
  async [NotificationActionTypes.UPDATE_SMS_SETTINGS]({ commit }, payload: UpdateSmsSettingsPayload) {
    commit(NotificationsMutationTypes.NOTIFICATIONS_LOADING);
    try {
      await client.updateSmsSettings(payload);
      commit(NotificationsMutationTypes.NOTIFICATIONS_SUCCEEDED);
    } catch (error) {
      commit(NotificationsMutationTypes.NOTIFICATIONS_ERROR, (error as Error).message);
      throw new Error((error as Error).message);
    }
  },

  [NotificationActionTypes.CLEAR_SMS_TEMPLATE]({ commit }) {
    commit(NotificationsMutationTypes.CLEAR_SMS_TEMPLATE);
  },
};
