import Status from '@/@typespaces/enum/status.enum';
import { IRubric } from '@/@typespaces/interfaces/rubric.interfaces';

export interface State {
  data: IRubric[];
  dataIds: string[];
  status: Status;
  error: string | null;
  page: number;
  pageSize: number;
  total: number;
}

export const state: State = {
  data: [],
  dataIds: [],
  status: Status.IDLE,
  error: null,
  page: 0,
  pageSize: 0,
  total: 0,
};
