export enum NotificationsMutationTypes {
  FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS',
  FETCH_EMAIL_TEMPLATE = 'FETCH_EMAIL_TEMPLATE',
  RESTORE_EMAIL_TEMPLATE_SUBJECT = 'RESTORE_EMAIL_TEMPLATE_SUBJECT',
  RESTORE_EMAIL_TEMPLATE_MARKUP = 'RESTORE_EMAIL_TEMPLATE_MARKUP',
  UPDATE_EMAIL_TEMPLATE_SETTINGS = 'UPDATE_EMAIL_TEMPLATE_SETTINGS',
  GET_SMS_TEMPLATE = 'GET_SMS_TEMPLATE',
  RESTORE_SMS_TEMPLATE = 'RESTORE_SMS_TEMPLATE',
  CLEAR_EMAIL_TEMPLATE = 'CLEAR_EMAIL_TEMPLATE',
  CLEAR_SMS_TEMPLATE = 'CLEAR_SMS_TEMPLATE',

  // Status
  NOTIFICATIONS_LOADING = 'PROJECTS_LOADING',
  NOTIFICATIONS_SUCCEEDED = 'PROJECTS_SUCCEEDED',
  NOTIFICATIONS_ERROR = 'PROJECTS_ERROR',
}
