export enum DomainMutationTypes {
  CONNECT_DOMAIN = 'CONNECT_DOMAIN',
  CHECK_HTTPS_ACCESS = 'CHECK_HTTPS_ACCESS',
  REMOVE_DOMAIN = 'REMOVE_DOMAIN',
  CLEAR_DOMAIN_STORE = 'CLEAR_DOMAIN_STORE',

  // Status
  DOMAIN_LOADING = 'DOMAIN_LOADING',
  DOMAIN_SUCCEEDED = 'DOMAIN_SUCCEEDED',
  DOMAIN_ERROR = 'DOMAIN_ERROR',
}
