import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { Review, ReviewSettings } from '@/@typespaces/interfaces/review';
import { PaginationInfo } from '@/@typespaces/types/paginationInfo';
import Status from '@/@typespaces/enum/status.enum';
import { State } from './state';

export type Getters = {
  getReviews(state: State): Review[];
  getCurrentReview(state: State): Review | null;
  getReviewsPagination(state: State): PaginationInfo;
  getReviewsLoading(state: State): boolean;
  getReviewsSettings(state: State): ReviewSettings | null;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getReviews: (state) => state.data,
  getCurrentReview: (state) => state.currentReview,
  getReviewsPagination: (state) => state.pagination,
  getReviewsLoading: (state) => state.status === Status.LOADING,
  getReviewsSettings: (state) => state.settings,
};
