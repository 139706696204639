export enum RolesAndAccessActionTypes {
  FETCH_USERS = 'FETCH_USERS',
  GET_ROLES_PROJECT_GROUP = 'GET_ROLES_PROJECT_GROUP',
  CLEAR_USER_ROLE = 'CLEAR_USER_ROLE',
  DELETE_ONE_USER = 'DELETE_ONE_USER',
  INVITE_USER = 'INVITE_USER',
  EDIT_USER_ROLE = 'EDIT_USER_ROLE',
  BLOCK_USER = 'BLOCK_USER',
  UNBLOCK_USER = 'UNBLOCK_USER',
}
