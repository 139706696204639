import Status from '@/@typespaces/enum/status.enum';
import { Delivery } from '@/@typespaces/interfaces/delivery.interface';
import { RootState } from '@/store';
import { GetterTree } from 'vuex';
import { State } from './state';

export type Getters = {
  getDeliveryData(state: State): Delivery[];
  getDeliveryDataLoading(state: State): boolean;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getDeliveryData: (state) => state.data,
  getDeliveryDataLoading: (state) => state.status === Status.LOADING,
};
