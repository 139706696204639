import Status from '@/@typespaces/enum/status.enum';
import { ShortTemplate, TemplateCategories, TemplateStep } from '@/@typespaces/interfaces/template.interface';

export interface State {
  data: ShortTemplate[];
  templateStep: TemplateStep | null;
  categories: TemplateCategories[];
  status: Status;
  error: string | null;
  page: number;
  pageSize: number;
  total: number;
}

export const state: State = {
  data: [],
  templateStep: null,
  categories: [],
  status: Status.IDLE,
  error: null,
  page: 0,
  pageSize: 0,
  total: 0,
};
