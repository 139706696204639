import { TemplateCategories } from '@/@typespaces/interfaces/template.interface';
import InstanceHttpClient from './instance';

export default class TemplateCategoriesClient extends InstanceHttpClient {
  constructor() {
    super('template-categories');
  }

  async fetchTemplateCategories(): Promise<TemplateCategories[]> {
    try {
      return await this.apiCall({
        method: 'GET',
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
