import Status from '@/@typespaces/enum/status.enum';
import { IComment } from '@/@typespaces/interfaces/comment.interface';

export interface State {
  data: IComment[];
  comment: IComment | null;
  dataIds: string[];
  status: Status;
  error: string | null;
  page: number;
  pageSize: number;
  total: number;
}

export const state: State = {
  data: [],
  dataIds: [],
  comment: null,
  status: Status.IDLE,
  error: null,
  page: 0,
  pageSize: 0,
  total: 0,
};
