import Status from '@/@typespaces/enum/status.enum';
import { RootState } from '@/store';
import { GetterTree } from 'vuex';
import { State } from './state';

export type Getters = {
  isLoading(state: State): boolean;
  isLoggedIn(state: State): boolean;
  getAccessToken(state: State): string | null;
  getAuthEmail(state: State): string | null;
};

export const getters: GetterTree<State, RootState> & Getters = {
  isLoading: (state: State) => state.status === Status.LOADING,
  isLoggedIn: (state: State) => !!state.accessToken?.length,
  getAccessToken: (state: State) => state.accessToken,
  getAuthEmail: (state: State) => state.email,
};
