// Core
import { computed, ComputedRef } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

// Interfaces
import { ThirdPartyCode } from '@/@typespaces/interfaces/thirdPartyCode.interface';
import { Seo, Template } from '@/@typespaces/interfaces/template.interface';
import { Contacts } from '@/@typespaces/interfaces/contacts.interface';

const useProject = () => {
  const route = useRoute();
  const store = useStore();
  const seo: ComputedRef<Seo | undefined> = computed(() => store.getters.getCurrentProjectSeo);
  const template: ComputedRef<Template | undefined> = computed(() => store.getters.getCurrentProjectTemplate);
  const code: ComputedRef<ThirdPartyCode | undefined> = computed(() => store.getters.getCurrentProjectThirdPartyCode);
  const contacts: ComputedRef<Contacts | undefined> = computed(() => store.getters.getCurrentProjectContacts);
  const templateId = template.value?._id;
  const { projectId } = route.params;

  return {
    seo,
    template,
    projectId,
    templateId,
    code,
    contacts,
  };
};

export default useProject;
