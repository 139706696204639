import { Contacts } from '@/@typespaces/interfaces/contacts.interface';
import { dataMapper } from '@/utils/dataMapper';
import { ContactsPayload } from '@/@typespaces/interfaces/form/contact-form-interface';
import InstanceHttpClient from './instance';

export default class ContactsClient extends InstanceHttpClient {
  constructor() {
    super('contacts');
  }

  async editContacts(payload: { projectId: string; data: ContactsPayload }) {
    const { projectId, data } = payload;
    const remapData = dataMapper(data);
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `/${projectId}`,
        data: remapData,
      });
    } catch (err: any) {
      throw new Error(err as string);
    }
  }
}
