import { MutationTree } from 'vuex';
import Status from '@/@typespaces/enum/status.enum';
import { IWarehouse } from '@/@typespaces/interfaces/warehouses.interface';
import { State } from './state';
import { WarehousesMutationTypes } from './mutation-types';

export type Mutations<S = State> = {
  [WarehousesMutationTypes.FETCH_WAREHOUSES](state: S, payload: IWarehouse[]): void;
  [WarehousesMutationTypes.EDIT_WAREHOUSE](state: S, payload: IWarehouse): void;
  [WarehousesMutationTypes.CREATE_WAREHOUSE](state: S, payload: IWarehouse): void;
  [WarehousesMutationTypes.FETCH_ONE_WAREHOUSE](state: S, payload: IWarehouse): void;
  [WarehousesMutationTypes.DELETE_WAREHOUSE](state: S, id: string): void;
  [WarehousesMutationTypes.BLOCK_WAREHOUSE](state: S, data: string): void;
  [WarehousesMutationTypes.UN_BLOCK_WAREHOUSE](state: S, data: string): void;

  // Status
  [WarehousesMutationTypes.WAREHOUSES_ERROR](state: S, payload: string): void;
  [WarehousesMutationTypes.WAREHOUSES_LOADING](state: S): void;
  [WarehousesMutationTypes.WAREHOUSES_SUCCEEDED](state: S): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [WarehousesMutationTypes.FETCH_WAREHOUSES](state: State, data: IWarehouse[]) {
    state.data = data;
  },
  [WarehousesMutationTypes.DELETE_WAREHOUSE](state: State, id: string) {
    const removeIndex = state.data.map((item) => item.id).indexOf(id);
    state.data.splice(removeIndex, 1);
  },
  [WarehousesMutationTypes.EDIT_WAREHOUSE](state: State, data) {
    state.data = state.data.map((item) => {
      if (item.id === data.id) {
        return data;
      }
      return item;
    });
  },

  [WarehousesMutationTypes.UN_BLOCK_WAREHOUSE](state: State, data) {
    state.data = state.data.map((item) => {
      if (item.id === data) {
        return {
          ...item,
          blocked: false,
        };
      }
      return item;
    });
  },

  [WarehousesMutationTypes.BLOCK_WAREHOUSE](state: State, data) {
    state.data = state.data.map((item) => {
      if (item.id === data) {
        return {
          ...item,
          blocked: true,
        };
      }
      return item;
    });
  },

  [WarehousesMutationTypes.CREATE_WAREHOUSE](state: State, data) {
    state.data.push(data);
  },
  [WarehousesMutationTypes.FETCH_ONE_WAREHOUSE](state: State, data) {
    state.oneWarehouse = data;
  },

  // Status
  [WarehousesMutationTypes.WAREHOUSES_LOADING](state: State) {
    state.status = Status.LOADING;
  },
  [WarehousesMutationTypes.WAREHOUSES_SUCCEEDED](state: State) {
    state.status = Status.SUCCEEDED;
  },
  [WarehousesMutationTypes.WAREHOUSES_ERROR](state: State, data: string) {
    state.error = data;
    state.status = Status.IDLE;
  },
};
