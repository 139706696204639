import Status from '@/@typespaces/enum/status.enum';
import {
  EmailSettings,
  EmailTemplate,
  ProjectNotifications,
  RestoreEmailTemplate,
  SmsTemplate,
} from '@/@typespaces/interfaces/notifications.interface';
import { MutationTree } from 'vuex';
import { NotificationsMutationTypes } from './mutation-types';
import { State } from './state';

export type Mutations<S = State> = {
  [NotificationsMutationTypes.FETCH_NOTIFICATIONS](state: S, payload: ProjectNotifications): void;
  [NotificationsMutationTypes.FETCH_EMAIL_TEMPLATE](state: S, payload: EmailTemplate): void;
  [NotificationsMutationTypes.RESTORE_EMAIL_TEMPLATE_MARKUP](state: S, payload: RestoreEmailTemplate): void;
  [NotificationsMutationTypes.RESTORE_EMAIL_TEMPLATE_SUBJECT](state: S, payload: RestoreEmailTemplate): void;
  [NotificationsMutationTypes.NOTIFICATIONS_LOADING](state: S): void;
  [NotificationsMutationTypes.NOTIFICATIONS_SUCCEEDED](state: S): void;
  [NotificationsMutationTypes.NOTIFICATIONS_ERROR](state: S, payload: string): void;
  [NotificationsMutationTypes.UPDATE_EMAIL_TEMPLATE_SETTINGS](state: S, payload: EmailSettings): void;
  [NotificationsMutationTypes.GET_SMS_TEMPLATE](state: S, payload: SmsTemplate): void;
  [NotificationsMutationTypes.RESTORE_SMS_TEMPLATE](state: S, payload: { markup: string }): void;
  [NotificationsMutationTypes.CLEAR_EMAIL_TEMPLATE](state: S): void;
  [NotificationsMutationTypes.CLEAR_SMS_TEMPLATE](state: S): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [NotificationsMutationTypes.FETCH_NOTIFICATIONS](state: State, payload: ProjectNotifications) {
    state.data = payload.notifications;
  },
  [NotificationsMutationTypes.FETCH_EMAIL_TEMPLATE](state: State, payload: EmailTemplate) {
    state.emailTemplate = payload;
  },
  [NotificationsMutationTypes.RESTORE_EMAIL_TEMPLATE_MARKUP](state: State, payload: RestoreEmailTemplate) {
    if (state.emailTemplate) {
      state.emailTemplate = { ...state.emailTemplate, markup: payload.markup };
    }
  },
  [NotificationsMutationTypes.RESTORE_EMAIL_TEMPLATE_SUBJECT](state: State, payload: RestoreEmailTemplate) {
    if (state.emailTemplate) {
      state.emailTemplate = { ...state.emailTemplate, subject: payload.subject };
    }
  },

  [NotificationsMutationTypes.UPDATE_EMAIL_TEMPLATE_SETTINGS](state: State, payload: EmailSettings) {
    if (state.data) {
      state.data = { ...state.data, email: { ...state.data.email, settings: { ...payload } } };
    }
  },

  [NotificationsMutationTypes.CLEAR_EMAIL_TEMPLATE](state: State) {
    state.emailTemplate = null;
  },

  // Sms
  [NotificationsMutationTypes.GET_SMS_TEMPLATE](state: State, payload: SmsTemplate) {
    state.smsTemplate = payload;
  },

  [NotificationsMutationTypes.RESTORE_SMS_TEMPLATE](state: State, payload: { markup: string }) {
    if (state.smsTemplate) {
      state.smsTemplate = { ...state.smsTemplate, markup: payload.markup };
    }
  },

  [NotificationsMutationTypes.CLEAR_SMS_TEMPLATE](state: State) {
    state.smsTemplate = null;
  },

  // Status
  [NotificationsMutationTypes.NOTIFICATIONS_LOADING](state: State) {
    state.status = Status.LOADING;
  },
  [NotificationsMutationTypes.NOTIFICATIONS_SUCCEEDED](state: State) {
    state.status = Status.SUCCEEDED;
  },
  [NotificationsMutationTypes.NOTIFICATIONS_ERROR](state: State, payload: string) {
    state.status = Status.FAILED;
    state.error = payload;
  },
};
