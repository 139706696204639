import {
  EmailSettings,
  EmailTemplate,
  Notifications,
  SmsTemplate,
} from '@/@typespaces/interfaces/notifications.interface';
import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { State } from './state';

export type Getters = {
  getNotifications(state: State): Notifications | null;
  getEmailTemplate(state: State): EmailTemplate | null;
  getSmsTemplate(state: State): SmsTemplate | null;
  getEmailSettings(state: State): EmailSettings | undefined;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getNotifications: (state) => state.data,
  getEmailTemplate: (state) => state.emailTemplate,
  getSmsTemplate: (state) => state.smsTemplate,
  getEmailSettings: (state) => state.data?.email.settings,
};
