export enum DomainActionTypes {
  CHECK_EXPIRY_DATE_DOMAIN = 'CHECK_EXPIRY_DATE_DOMAIN',
  CHECK_EXIST_DOMAIN = 'CHECK_EXIST_DOMAIN',
  CHECK_DOMAIN = 'CHECK_DOMAIN',
  CHECK_SSL_CERT_FILE = 'CHECK_SSL_CERT_FILE',
  CONNECT_DOMAIN = 'CONNECT_DOMAIN',
  CHECK_HTTPS_ACCESS = 'CHECK_HTTPS_ACCESS',
  REMOVE_DOMAIN = 'REMOVE_DOMAIN',
  CLEAR_DOMAIN_STORE = 'CLEAR_DOMAIN_STORE',
  CHANGE_MAIN_DOMAIN = 'CHANGE_MAIN_DOMAIN',
}
