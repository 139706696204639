export async function getImageSize(file: File): Promise<{ width: number; height: number }> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        // @ts-ignore
        resolve({ width: img.width, height: img.height });
      };
      // @ts-ignore
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  });
}
