import { Identity } from '@/@typespaces/interfaces/identity.interface';
import InstanceHttpClient from './instance';

export default class IdentityClient extends InstanceHttpClient {
  constructor() {
    super('identity');
  }

  async getIdentity(): Promise<Identity> {
    try {
      return await this.apiCall({
        method: 'GET',
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
