export enum NewsAndBlogsActionTypes {
  FETCH_NEWS_AND_BLOGS_DATA = 'FETCH_NEWS_AND_BLOGS_DATA',
  FETCH_NEWS_AND_BLOGS_AUTHORS = 'FETCH_NEWS_AND_BLOGS_AUTHORS',
  REMOVE_NEWS_AND_BLOGS_AUTHOR = 'REMOVE_NEWS_AND_BLOGS_AUTHOR',
  NEWS_AND_BLOGS_EDIT_AUTHOR = 'NEWS_AND_BLOGS_EDIT_AUTHOR',
  FETCH_ONE_BLOG = 'FETCH_ONE_BLOG',
  REMOVE_ONE_BLOG = 'REMOVE_ONE_BLOG',
  SET_STATUS_BLOG = 'SET_STATUS_BLOG',
  EDIT_BLOG = 'EDIT_BLOG',
  CREATE_BLOG = 'CREATE_BLOG',
  CREATE_PUBLISH_BLOG = 'CREATE_PUBLISH_BLOG',
  COPY_POST = 'COPY_POST',
  UPDATE_POST_COVER = 'UPDATE_POST_COVER',
  CLEAR_ONE_POST = 'CLEAR_ONE_POST',
}
