export const SelectedOptions = {
  ROLES_AND_ACCESS: [
    {
      value: 'role',
      label: 'Роль',
    },
    {
      value: 'blocked',
      label: 'Статус',
    },
  ],
  ORDERS: [
    {
      value: 'created_at',
      label: 'Дата заказа',
    },
    {
      value: 'total_price',
      label: 'Сумма заказа',
    },
    {
      value: 'payment_status',
      label: 'Статус оплаты',
    },
    {
      value: 'current_status',
      label: 'Статус заказа',
    },
  ],
  CUSTOMERS: [
    {
      value: 'activityAt',
      label: 'Дата активности',
    },
  ],
  PRODUCTS: [
    {
      value: 'price',
      label: 'Стоимость',
    },
    {
      value: 'balance',
      label: 'Остаток',
    },
    {
      value: 'publish',
      label: 'Статус',
    },
  ],
  REVIEWS: [
    {
      value: 'created_at',
      label: 'Дата',
    },
    {
      value: 'published',
      label: 'Статус',
    },
    {
      value: 'author',
      label: 'Автор',
    },
  ],
  NEWS_AND_BLOG: [
    {
      value: 'published_at',
      label: 'Дата публикации',
    },
    {
      value: 'category_title',
      label: 'Рубрика',
    },
    {
      value: 'quantity_comments',
      label: 'Комментарии',
    },
    {
      value: 'title',
      label: 'Заголовок',
    },
  ],
};
