export const shortQueryOperatorSelectOptions = [
  {
    value: '_eq',
    label: 'равно',
  },
  {
    value: '_ne',
    label: 'не равно',
  },
  {
    value: '_contains',
    label: 'содержит',
  },
];

export const baseQueryOperatorSelectOptions = [
  {
    value: '_eq',
    label: 'равно',
  },
  {
    value: '_ne',
    label: 'не равно',
  },
  {
    value: '_contains',
    label: 'содержит',
  },
  {
    value: '_lt',
    label: 'меньше чем',
  },
  {
    value: '_gt',
    label: 'больше чем',
  },
];

export const dateQueryOperatorSelectOptions = [
  {
    value: '_lt',
    label: 'меньше чем',
  },
  {
    value: '_gt',
    label: 'больше чем',
  },
];

export const statusQueryOperatorSelectOptions = [
  {
    value: '_eq',
    label: 'равно',
  },
  {
    value: '_ne',
    label: 'не равно',
  },
];

export const fullOperatorSelectOptions = [
  {
    value: '_eq',
    label: 'равно',
  },
  {
    value: '_ne',
    label: 'не равно',
  },
  {
    value: '_lt',
    label: 'меньше чем',
  },
  {
    value: '_lte',
    label: 'меньше или равно',
  },
  {
    value: '_gt',
    label: 'больше чем',
  },
  {
    value: '_gte',
    label: 'больше или равно',
  },
  {
    value: '_contains',
    label: 'содержит',
  },
];
