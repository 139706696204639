export enum ReviewsActionTypes {
  GET_REVIEWS = 'GET_REVIEWS',
  PUBLISH_REVIEW = 'PUBLISH_REVIEW',
  UN_PUBLISH_REVIEW = 'UN_PUBLISH_REVIEW',
  GET_CURRENT_REVIEW = 'GET_CURRENT_REVIEW',
  REMOVE_REVIEW = 'REMOVE_REVIEW',
  CREATE_REVIEW_ANSWER = 'CREATE_REVIEW_ANSWER',
  REMOVE_REVIEW_ANSWER = 'REMOVE_REVIEW_ANSWER',
  EDIT_REVIEW_ANSWER = 'EDIT_REVIEW_ANSWER',
  EDIT_REVIEW_SETTINGS = 'EDIT_REVIEW_SETTINGS',
  GET_REVIEW_SETTINGS = 'GET_REVIEW_SETTINGS',
}
