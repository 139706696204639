import Status from '@/@typespaces/enum/status.enum';
import { Identity } from '@/@typespaces/interfaces/identity.interface';
import { IdentityMutationTypes } from '@/store/modules/identity/mutation-types';
import { MutationTree } from 'vuex';
import { State } from './state';

export type Mutations<S = State> = {
  [IdentityMutationTypes.FETCH_IDENTITY](state: S, payload: Identity): void;

  // Status
  [IdentityMutationTypes.IDENTITY_ERROR](state: S, payload: string): void;
  [IdentityMutationTypes.IDENTITY_LOADING](state: S): void;
  [IdentityMutationTypes.IDENTITY_SUCCEEDED](state: S): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [IdentityMutationTypes.FETCH_IDENTITY](state: State, data: Identity) {
    state.identity = data;
  },

  // Status
  [IdentityMutationTypes.IDENTITY_LOADING](state) {
    state.status = Status.LOADING;
  },
  [IdentityMutationTypes.IDENTITY_SUCCEEDED](state) {
    state.status = Status.SUCCEEDED;
  },
  [IdentityMutationTypes.IDENTITY_ERROR](state: State, error: string) {
    state.error = error;
    state.status = Status.IDLE;
  },
};
