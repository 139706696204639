import { ConcreteComponent } from '@vue/runtime-core';
import { h } from 'vue';

const createVNodeError = (component: ConcreteComponent | string, title: string, description: string) => ({
  content: h(component, {
    commonText: title,
    errorMessage: description,
  }),
});

export default createVNodeError;
