import { ActionContext, ActionTree } from 'vuex';
import { RootState, store } from '@/store';
import ProjectSettingsClient from '@/api/projectsSettings.api';
import { ProjectSettingsActionTypes } from '@/store/modules/projectSettings/action-types';
import { ProjectSettingsMutationTypes } from '@/store/modules/projectSettings/mutation-types';
import { IProjectSettings } from '@/@typespaces/interfaces/projectSettings.interface';
import { Mutations } from './mutations';
import { State } from './state';

const client = new ProjectSettingsClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [ProjectSettingsActionTypes.FETCH_PROJECT_SETTINGS]({ commit }: AugmentedActionContext, id: string): void;

  [ProjectSettingsActionTypes.EDIT_PROJECT_SETTINGS](
    { commit }: AugmentedActionContext,
    settings: { id: string; data: IProjectSettings }
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ProjectSettingsActionTypes.FETCH_PROJECT_SETTINGS]({ commit }, id: string) {
    const a = store.getters.getProjectId;
    console.log(a);
    try {
      const data = await client.getSettings(id);
      commit(ProjectSettingsMutationTypes.FETCH_PROJECT_SETTINGS, data);
    } catch (err) {
      throw new Error(err as string);
    }
  },
  async [ProjectSettingsActionTypes.EDIT_PROJECT_SETTINGS](
    { commit },
    settings: { id: string; data: IProjectSettings }
  ) {
    try {
      const response = await client.editSettings(settings);
      if (response.success) {
        commit(ProjectSettingsMutationTypes.EDIT_PROJECT_SETTINGS, settings.data);
      }
    } catch (err) {
      throw new Error(err as string);
    }
  },
};
