import { TemplateType } from '@/@typespaces/enum/notifications.enum';

export const notificationTemplateDictionary = (type: string) => {
  switch (type) {
    case TemplateType.APPROVE_REGISTRATION:
      return 'Подтверждение регистрации';
    case TemplateType.REGISTER_SUCCESS:
      return 'Успешная регистрация';
    case TemplateType.CREATE_ORDER:
      return 'Совершение заказа';
    case TemplateType.ORDER_STATUS:
      return 'Статус заказа';
    case TemplateType.COMPLETE_ORDER:
      return 'Завершение заказа';
    case TemplateType.CHANGE_PASSWORD:
      return 'Смена пароля';
    default:
      return '';
  }
};
