import { MainDomain } from '@/@typespaces/interfaces/domain.interface';
import { Seo } from '@/@typespaces/interfaces/template.interface';
import { BitrixIntegrationPayload } from '@/@typespaces/types/form/bitrixIntegration';
import { DocumentsDataPayload } from '@/@typespaces/types/form/documents.types';
import { CheckHttpsPayload } from '@/@typespaces/types/form/domain.types';
import { UpdateSettingsType } from '@/@typespaces/types/form/settings-form.type';
import { ThirdPartyCodePayload } from '@/@typespaces/types/form/thirdPartyCode';
import { UpdateFilePayload } from '@/@typespaces/types/updateFile.types';
import ContactsClient from '@/api/contacts.api';
import ContentSettingsApi from '@/api/contentSettings.api';
import SeoClient from '@/api/seo.api';
import ThirdPartyCodeClient from '@/api/thirdPartyCode.api';
import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store';
import { ProjectsMutationTypes } from '@/store/modules/projects/mutation-types';
import UserProjectsClient from '@/api/projects.api';
import { ICreateProject, ICredentialsData, Project } from '@/@typespaces/interfaces/projects.interface';
import { Query } from '@/@typespaces/types/query.types';
import { ContactsPayload } from '@/@typespaces/interfaces/form/contact-form-interface';
import { State } from './state';
import { Mutations } from './mutations';
import { ProjectsActionTypes } from './action-types';

const client = new UserProjectsClient();
const thirdPartyCodeClient = new ThirdPartyCodeClient();
const seoClient = new SeoClient();
const contactClient = new ContactsClient();
const contentClient = new ContentSettingsApi();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [ProjectsActionTypes.FETCH_PROJECTS]({ commit }: AugmentedActionContext, payload: Query): void;

  [ProjectsActionTypes.SET_YOOMONEY_CREDENTIALS]({ commit }: AugmentedActionContext, payload: ICredentialsData): void;

  [ProjectsActionTypes.SET_BITRIX_CREDENTIALS](
    { commit }: AugmentedActionContext,
    payload: BitrixIntegrationPayload
  ): void;

  [ProjectsActionTypes.CREATE_PROJECT]({ commit }: AugmentedActionContext, payload: ICreateProject): void;

  [ProjectsActionTypes.FETCH_ONE_PROJECT]({ commit }: AugmentedActionContext, id: string): void;

  [ProjectsActionTypes.CLEAR_ONE_PROJECT]({ commit }: AugmentedActionContext): void;

  [ProjectsActionTypes.DELETE_PROJECT]({ commit }: AugmentedActionContext, id: string): void;

  [ProjectsActionTypes.COPY_PROJECT]({ commit }: AugmentedActionContext, id: string): void;

  [ProjectsActionTypes.PUBLISH_PROJECT]({ commit }: AugmentedActionContext, id: string): void;

  [ProjectsActionTypes.UN_PUBLISH_PROJECT]({ commit }: AugmentedActionContext, id: string): void;

  [ProjectsActionTypes.EDIT_PROJECT]({ commit }: AugmentedActionContext, data: UpdateSettingsType): void;

  [ProjectsActionTypes.UPDATE_PROJECT_FILE]({ commit }: AugmentedActionContext, payload: UpdateFilePayload): void;

  [ProjectsActionTypes.EDIT_THIRD_PARTY_CODE]({ commit }: AugmentedActionContext, payload: ThirdPartyCodePayload): void;

  [ProjectsActionTypes.CONNECT_DOMAIN_IN_PROJECT]({ commit }: AugmentedActionContext, payload: MainDomain): void;

  [ProjectsActionTypes.UPDATE_CURRENT_PROJECT]({ commit }: AugmentedActionContext, payload: Project): void;

  [ProjectsActionTypes.EDIT_TEMPLATE_SEO](
    { commit }: AugmentedActionContext,
    payload: { projectId: string; data: Partial<Seo> & { templateId?: string } }
  ): void;

  [ProjectsActionTypes.GENERATE_SITEMAP](
    { commit }: AugmentedActionContext,
    payload: { projectId: string; data: { templateId: string } }
  ): void;

  [ProjectsActionTypes.EDIT_CONTACTS](
    { commit }: AugmentedActionContext,
    payload: { projectId: string; data: ContactsPayload }
  ): void;

  [ProjectsActionTypes.EDIT_BLOG_SETTINGS](
    { commit }: AugmentedActionContext,
    payload: { type: string; value: boolean }
  ): void;

  [ProjectsActionTypes.UPDATE_CONFIDENTIAL_POLICY_IN_PROJECT](
    { commit }: AugmentedActionContext,
    payload: DocumentsDataPayload
  ): void;

  [ProjectsActionTypes.UPDATE_USER_AGREEMENT_IN_PROJECT](
    { commit }: AugmentedActionContext,
    payload: DocumentsDataPayload
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ProjectsActionTypes.FETCH_PROJECTS]({ commit }, query: Query) {
    commit(ProjectsMutationTypes.PROJECTS_LOADING);
    try {
      const data = await client.getProjects(query);
      commit(ProjectsMutationTypes.FETCH_PROJECTS, data);
      commit(ProjectsMutationTypes.PROJECTS_SUCCEEDED);
    } catch (err) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, err as string);
      throw new Error(err as string);
    }
  },
  async [ProjectsActionTypes.CREATE_PROJECT]({ commit }, payload: ICreateProject) {
    try {
      const data = await client.createProjects(payload);
      commit(ProjectsMutationTypes.CREATE_PROJECT, data);
    } catch (err) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, err as string);
      throw new Error(err as string);
    }
  },
  async [ProjectsActionTypes.FETCH_ONE_PROJECT]({ commit }, id: string) {
    commit(ProjectsMutationTypes.CURRENT_PROJECT_LOADING);
    try {
      const response = await client.getOneProject(id);
      commit(ProjectsMutationTypes.FETCH_ONE_PROJECT, response);
      commit(ProjectsMutationTypes.CURRENT_PROJECT_SUCCEEDED);
      return response;
    } catch (err) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, err as string);
      throw new Error(err as string);
    }
  },

  async [ProjectsActionTypes.DELETE_PROJECT]({ commit }, id: string) {
    try {
      await client.removeProject(id);
      commit(ProjectsMutationTypes.DELETE_PROJECT, id);
    } catch (err) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, err as string);
      throw new Error(err as string);
    }
  },

  async [ProjectsActionTypes.COPY_PROJECT]({ commit }, id: string) {
    try {
      const data = await client.copyProject(id);
      commit(ProjectsMutationTypes.COPY_PROJECT, data);
    } catch (err) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, err as string);
      throw new Error(err as string);
    }
  },

  async [ProjectsActionTypes.PUBLISH_PROJECT]({ commit }, id: string) {
    try {
      const response = await client.publishProject(id);
      if (response.success) {
        commit(ProjectsMutationTypes.PUBLISH_PROJECT, id);
      }
    } catch (err) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, err as string);
      throw new Error(err as string);
    }
  },
  async [ProjectsActionTypes.UN_PUBLISH_PROJECT]({ commit }, id: string) {
    try {
      const response = await client.publishProject(id);
      if (response.success) {
        commit(ProjectsMutationTypes.UN_PUBLISH_PROJECT, id);
      }
    } catch (err) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, err as string);
      throw new Error(err as string);
    }
  },

  async [ProjectsActionTypes.EDIT_PROJECT]({ commit }, data: UpdateSettingsType) {
    try {
      const response = await client.editProjects(data);
      commit(ProjectsMutationTypes.EDIT_PROJECT, response);
    } catch (err) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, err as string);
      throw new Error(err as string);
    }
  },

  async [ProjectsActionTypes.UPDATE_PROJECT_FILE]({ commit }, payload: UpdateFilePayload) {
    try {
      await client.updateProjectFile(payload);
      commit(ProjectsMutationTypes.UPDATE_PROJECT_FILE, payload);
    } catch (err) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, err as string);
      throw new Error(err as string);
    }
  },
  async [ProjectsActionTypes.SET_YOOMONEY_CREDENTIALS]({ commit }, data: ICredentialsData) {
    try {
      await client.setYoomoneyCredentials(data);
      commit(ProjectsMutationTypes.SET_YOOMONEY_CREDENTIALS, data);
    } catch (err) {
      throw new Error(err as string);
    }
  },

  async [ProjectsActionTypes.SET_BITRIX_CREDENTIALS]({ commit }, data: BitrixIntegrationPayload) {
    try {
      await client.setBitrixCredentials(data);
      commit(ProjectsMutationTypes.SET_BITRIX_CREDENTIALS, data.data);
    } catch (err) {
      throw new Error(err as string);
    }
  },

  [ProjectsActionTypes.CLEAR_ONE_PROJECT]({ commit }) {
    commit(ProjectsMutationTypes.CLEAR_ONE_PROJECT);
  },

  async [ProjectsActionTypes.EDIT_THIRD_PARTY_CODE]({ commit }, payload: ThirdPartyCodePayload) {
    commit(ProjectsMutationTypes.PROJECTS_LOADING);
    try {
      await thirdPartyCodeClient.editCode(payload);
      commit(ProjectsMutationTypes.EDIT_THIRD_PARTY_CODE, payload.data);
      commit(ProjectsMutationTypes.PROJECTS_SUCCEEDED);
    } catch (error) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, error as string);
      throw new Error(error as string);
    }
  },
  async [ProjectsActionTypes.EDIT_TEMPLATE_SEO](
    { commit },
    payload: { projectId: string; data: Partial<Seo> & { templateId?: string } }
  ) {
    commit(ProjectsMutationTypes.PROJECTS_LOADING);
    try {
      await seoClient.editSeo(payload);
      // eslint-disable-next-line no-param-reassign
      delete payload.data.templateId;
      commit(ProjectsMutationTypes.EDIT_TEMPLATE_SEO, payload.data);
      commit(ProjectsMutationTypes.PROJECTS_SUCCEEDED);
    } catch (error) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, error as string);
      throw new Error(error as string);
    }
  },

  async [ProjectsActionTypes.GENERATE_SITEMAP](
    { commit },
    payload: { projectId: string; data: { templateId: string } }
  ) {
    commit(ProjectsMutationTypes.PROJECTS_LOADING);
    try {
      await seoClient.generateSitemap(payload);

      commit(ProjectsMutationTypes.GENERATE_SITEMAP);
      commit(ProjectsMutationTypes.PROJECTS_SUCCEEDED);
    } catch (error) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, error as string);
      throw new Error(error as string);
    }
  },

  async [ProjectsActionTypes.EDIT_CONTACTS](
    { commit },
    payload: {
      projectId: string;
      data: ContactsPayload;
    }
  ) {
    commit(ProjectsMutationTypes.PROJECTS_LOADING);
    try {
      await contactClient.editContacts(payload);
      commit(ProjectsMutationTypes.EDIT_CONTACTS, payload.data);
      commit(ProjectsMutationTypes.PROJECTS_SUCCEEDED);
    } catch (error) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, error as string);
      throw new Error(error as string);
    }
  },

  // Content
  async [ProjectsActionTypes.EDIT_BLOG_SETTINGS](
    { commit },
    payload: {
      type: string;
      value: boolean;
    }
  ) {
    commit(ProjectsMutationTypes.PROJECTS_LOADING);
    try {
      await contentClient.editSettings(payload);

      commit(ProjectsMutationTypes.EDIT_BLOG_SETTINGS, { [payload.type]: payload.value });
      commit(ProjectsMutationTypes.PROJECTS_SUCCEEDED);
    } catch (error) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, error as string);
      throw new Error(error as string);
    }
  },

  // Domain
  [ProjectsActionTypes.CONNECT_DOMAIN_IN_PROJECT]({ commit }, payload: MainDomain) {
    commit(ProjectsMutationTypes.CONNECT_DOMAIN_IN_PROJECT, payload);
  },
  [ProjectsActionTypes.REMOVE_DOMAIN_IN_PROJECT]({ commit }, payload: CheckHttpsPayload) {
    commit(ProjectsMutationTypes.REMOVE_DOMAIN_FROM_PROJECT, payload);
  },

  // Documents
  async [ProjectsActionTypes.UPDATE_CONFIDENTIAL_POLICY_IN_PROJECT]({ commit }, payload: DocumentsDataPayload) {
    const { title, body } = payload.data;
    try {
      await client.updateDocuments(payload);
      commit(ProjectsMutationTypes.UPDATE_CONFIDENTIAL_POLICY_IN_PROJECT, { title, body });
    } catch (error) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, error as string);
      throw new Error(error as string);
    }
  },
  async [ProjectsActionTypes.UPDATE_USER_AGREEMENT_IN_PROJECT]({ commit }, payload: DocumentsDataPayload) {
    const { title, body } = payload.data;
    try {
      await client.updateDocuments(payload);
      commit(ProjectsMutationTypes.UPDATE_USER_AGREEMENT_IN_PROJECT, { title, body });
    } catch (error) {
      commit(ProjectsMutationTypes.PROJECTS_ERROR, error as string);
      throw new Error(error as string);
    }
  },
  async [ProjectsActionTypes.UPDATE_CURRENT_PROJECT]({ commit }, payload: Project) {
    try {
      commit(ProjectsMutationTypes.UPDATE_CURRENT_PROJECT, payload);
    } catch (error) {
      throw new Error(error as string);
    }
  },
};
