import { Feature } from '@/@typespaces/interfaces/category.interface';
import InstanceHttpClient from '../instance';

export default class FeaturesApi extends InstanceHttpClient {
  constructor() {
    super('shop/features');
  }

  async createFeature(data: { title: string; category_id: string }): Promise<Feature> {
    try {
      return await this.apiCall({
        method: 'POST',
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async editFeature(data: { id: string; title: string }): Promise<Feature> {
    const { id, title } = data;
    try {
      return await this.apiCall({
        method: 'PUT',
        url: id,
        data: { title },
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async deleteFeature(id: string) {
    try {
      return await this.apiCall({
        method: 'DELETE',
        url: id,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
