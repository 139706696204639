import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import StatusEnum from '@/@typespaces/enum/status.enum';
import { IUser } from '@/@typespaces/interfaces/user.interface';
import { IRole } from '@/@typespaces/interfaces/rolesAndAccess.interface';
import { PaginationInfo } from '@/@typespaces/types/paginationInfo';
import { State } from './state';

export type Getters = {
  getUsersRolesAndAccess(state: State): IUser[];
  getRoles(state: State): IRole[];
  getTotalUsers(state: State): number;
  getRolesPaginationInfo(state: State): PaginationInfo;
  getUsersRolesAndAccessStatus(state: State): StatusEnum;
  getUsersRolesAndAccessById: (state: State) => (id: string) => IUser | undefined;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getUsersRolesAndAccess: (state) => state.users,
  getRoles: (state) => state.roles,
  getTotalUsers: (state) => state.total,
  getRolesPaginationInfo: (state) => ({
    total: state.total,
    page: state.page,
    pageSize: state.pageSize,
  }),
  getUsersRolesAndAccessStatus: (state) => state.status,
  getUsersRolesAndAccessById: (state) => (id: string) => state.users.find((code) => code._id === id),
};
