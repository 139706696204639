import Status from '@/@typespaces/enum/status.enum';
import { IAnswer, IComment, ICommentData } from '@/@typespaces/interfaces/comment.interface';
import { MutationTree } from 'vuex';
import { CommentMutationTypes } from './mutation-types';
import { State } from './state';

export type Mutations<S = State> = {
  [CommentMutationTypes.GET_COMMENTS](state: S, payload: ICommentData): void;
  [CommentMutationTypes.GET_ONE_COMMENT](state: S, payload: IComment): void;
  [CommentMutationTypes.CREATE_COMMENT](state: S, payload: IComment): void;
  [CommentMutationTypes.REMOVE_COMMENT](state: S, payload: string): void;
  [CommentMutationTypes.CREATE_ANSWER](state: S, payload: { id: string; answer: IAnswer }): void;
  [CommentMutationTypes.SET_COMMENT_STATUS](state: S, payload: { id: string; status: string }): void;
  // Status
  [CommentMutationTypes.COMMENTS_LOADING](state: S): void;
  [CommentMutationTypes.COMMENTS_SUCCEEDED](state: S): void;
  [CommentMutationTypes.COMMENTS_ERROR](state: S, payload: string): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [CommentMutationTypes.GET_COMMENTS](state: State, payload: ICommentData) {
    state.data = payload.data.comments;
    state.dataIds = payload.data.commentsIds;
    state.total = payload.total;
    state.page = payload.page;
    state.pageSize = payload.pageSize;
  },

  [CommentMutationTypes.GET_ONE_COMMENT](state: State, payload: IComment) {
    state.comment = payload;
  },
  [CommentMutationTypes.CREATE_COMMENT](state: State, payload: IComment) {
    state.data.push(payload);
  },

  [CommentMutationTypes.REMOVE_COMMENT](state: State, payload: string) {
    const removeIndex = state.data.map((item) => item._id).indexOf(payload);
    if (removeIndex !== -1) {
      state.data.splice(removeIndex, 1);
      state.total -= 1;
    }
  },

  [CommentMutationTypes.CREATE_ANSWER](state: State, payload: { id: string; answer: IAnswer }) {
    if (state.comment) {
      state.comment = { ...state.comment, answer: payload.answer };
    }
  },

  [CommentMutationTypes.SET_COMMENT_STATUS](state: State, payload: { id: string; status: string }) {
    state.data = state.data.map((item) => {
      if (item._id === payload.id) {
        return {
          ...item,
          status: payload.status,
        };
      }
      return item;
    });
  },

  // Status
  [CommentMutationTypes.COMMENTS_LOADING](state: State) {
    state.status = Status.LOADING;
  },
  [CommentMutationTypes.COMMENTS_SUCCEEDED](state: State) {
    state.status = Status.SUCCEEDED;
  },
  [CommentMutationTypes.COMMENTS_ERROR](state: State, payload: string) {
    state.error = payload;
    state.status = Status.IDLE;
  },
};
