import { changeCase } from '@/utils/changeCase';

export const dataMapper = <T>(obj: T, changeValueCase?: boolean) => {
  let result: T;
  Object.entries(obj).forEach(([item, value]) => {
    result = { ...result, [changeCase(item)]: value };
  });

  if (changeValueCase) {
    return Object.fromEntries(
      Object.entries(result!).map(([key, value]) => {
        if (typeof value === 'string') {
          return [key, changeCase(value)];
        }
        return [key, value];
      })
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return result!;
};
