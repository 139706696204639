import { Product, IProductData } from '@/@typespaces/interfaces/products.interface';
import { Query } from '@/@typespaces/types/query.types';
import InstanceHttpClient from '../instance';

export default class ProductsApi extends InstanceHttpClient {
  constructor() {
    super('shop/products');
  }

  async getProducts(data: { projectId: string; query: Query }): Promise<IProductData> {
    try {
      return await this.apiCall({
        method: 'GET',
        params: data.query,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async getOneProduct(id: string): Promise<Product> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: id,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async deleteProduct(id: string) {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `${id}/delete`,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async editProduct(data: Product): Promise<{ success: true }> {
    try {
      return await this.apiCall({
        method: 'PUT',
        url: data.id,
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async createProduct(data: Product): Promise<Product> {
    try {
      return await this.apiCall({
        method: 'POST',
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async deleteManyProducts(data: string[]) {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: 'delete-many',
        data: { product_ids: data },
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async hideManyProducts(data: string[]) {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: 'unpublish-many',
        data: { product_ids: data },
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async showManyProducts(data: string[]) {
    try {
      await this.apiCall({
        method: 'PATCH',
        url: 'publish-many',
        data: { product_ids: data },
      });
      return { success: true };
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async hideProduct(id: string) {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `${id}/unpublish`,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async showProduct(id: string) {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `${id}/publish`,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
