import { DeliveryData } from '@/@typespaces/interfaces/delivery.interface';
import InstanceHttpClient from './instance';

export default class DeliveryApi extends InstanceHttpClient {
  constructor() {
    super('shop/delivery');
  }

  async getDeliveryData(): Promise<DeliveryData> {
    try {
      return await this.apiCall({
        method: 'GET',
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async editDeliveryData(data: DeliveryData) {
    try {
      await this.apiCall({
        method: 'PATCH',
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
