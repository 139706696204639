import Status from '@/@typespaces/enum/status.enum';
import { IRubric } from '@/@typespaces/interfaces/rubric.interfaces';
import { PaginationInfo } from '@/@typespaces/types/paginationInfo';
import { RootState } from '@/store';
import { GetterTree } from 'vuex';
import { State } from './state';

export type Getters = {
  getRubrics(state: State): IRubric[];
  getRubricsLoading(state: State): boolean;
  getRubricsPagination(state: State): PaginationInfo;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getRubrics: (state) => state.data,
  getRubricsLoading: (state) => state.status === Status.LOADING,
  getRubricsPagination: (state) => ({
    total: state.total,
    page: state.page,
    pageSize: state.pageSize,
  }),
};
