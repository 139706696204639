import { ILocation } from '@/@typespaces/interfaces/location.interface';
import InstanceHttpClient from './instance';

export default class LocationApi extends InstanceHttpClient {
  constructor() {
    super('location');
  }

  async getLocations(): Promise<ILocation[]> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: 'regions',
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
