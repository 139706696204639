import { Category } from '@/@typespaces/interfaces/category.interface';

// @ts-ignore
// eslint-disable-next-line consistent-return
export const getPath = (items: Category[], val: string) => {
  for (let i = 0; i < items.length; i += 1) {
    const item = items[i];
    if (item.id !== val) {
      if (item.child_categories) {
        const path: string[] | undefined = getPath(item.child_categories, val);
        if (path) {
          path.unshift(item.id);
          return path;
        }
      }
    } else {
      return [item.id];
    }
  }
};
