import UsersClient from '@/api/users.api';
import { RootState } from '@/store';
import { UsersActionTypes } from '@/store/modules/users/action-types';
import { UsersMutationTypes } from '@/store/modules/users/mutation-types';
import { ActionContext, ActionTree } from 'vuex';
import { Mutations } from './mutations';
import { State } from './state';

const client = new UsersClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [UsersActionTypes.GET_PROJECT_USERS]({ commit }: AugmentedActionContext, payload: string): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [UsersActionTypes.GET_PROJECT_USERS]({ commit }, payload: string) {
    commit(UsersMutationTypes.USERS_LOADING);
    try {
      const data = await client.getUsers(payload);
      commit(UsersMutationTypes.GET_PROJECT_USERS, data);
      commit(UsersMutationTypes.USERS_SUCCEEDED);
    } catch (error) {
      commit(UsersMutationTypes.USERS_ERROR, error as string);
    }
  },
};
