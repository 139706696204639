// Core
import { removeTags } from '@/utils/removeTags';
import { RuleObject } from 'ant-design-vue/es/form';

// Constants
import { EMAIL_REGEX, PHONE_REGEX, UUID_REGEX } from '@/@constants/regExp.constants';

export const validateEmptyField = (message: string) => async (_rule: RuleObject, value: string) => {
  if (!value) {
    return Promise.reject(new Error(message));
  }
  if (value === 'Выберите') {
    return Promise.reject(new Error(message));
  }
  return Promise.resolve();
};

export const validateLimitValue = (_rule: RuleObject, value: string, message: string, max: number) => {
  if (Number(value) > max) {
    return Promise.reject(new Error(message));
  }
  if (Number(value) < 0) {
    return Promise.reject(new Error(message));
  }
  return Promise.resolve();
};

export const validateEmptyArray = (message: string) => async (_rule: RuleObject, value: string[]) => {
  if (!value.length) {
    return Promise.reject(new Error(message));
  }
  return Promise.resolve();
};

export const validateEmail = () => async (_rule: RuleObject, value: string) => {
  if (!value && _rule.required) {
    return Promise.reject(new Error('Пожалуйста, введите электронную почту'));
  }
  if (value && !value.match(EMAIL_REGEX)) {
    return Promise.reject(new Error('Пожалуйста, введите корректный адрес электронной почты'));
  }
  return Promise.resolve();
};

export const validatePassword = () => async (_rule: RuleObject, value: string) => {
  if (value === '') {
    return Promise.reject(new Error('Пожалуйста, введите пароль'));
  }
  if (value.trim().length < 6) {
    return Promise.reject(new Error('Длина пароля должна быть больше 6 символов'));
  }
  return Promise.resolve();
};

export const validateConfirmPass = (confirm_value: string) => async (_rule: RuleObject, value: string) => {
  if (!value) {
    return Promise.reject(new Error('Пожалуйста, повторите пароль'));
  }
  if (value !== confirm_value) {
    return Promise.reject(new Error('Пароли не совпадают'));
  }
  return Promise.resolve();
};

export const validatePhone = () => async (_rule: RuleObject, value: string) => {
  if (value && !value.match(PHONE_REGEX)) {
    return Promise.reject(new Error('Пожалуйста, введите корректный телефон'));
  }
  return false;
};

export const validateBitrixId = () => async (_rule: RuleObject, value: string) => {
  if (value && value.length < 18) {
    return Promise.reject(new Error('Пожалуйста, введите корректный ID'));
  }
  return false;
};

export const isUUID = (uuid: string) => !!uuid.match(UUID_REGEX);
