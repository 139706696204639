import { MutationTree } from 'vuex';
import Status from '@/@typespaces/enum/status.enum';
import { Statistic } from '@/@typespaces/interfaces/statistics.interface';
import { StatisticsMutationTypes } from './mutation-types';
import { State } from './state';

export type Mutations<S = State> = {
  [StatisticsMutationTypes.GET_STATISTICS_VISITORS](state: S, payload: Statistic[]): void;
  [StatisticsMutationTypes.GET_STATISTICS_ORDERS](state: S, payload: Statistic[]): void;
  [StatisticsMutationTypes.GET_STATISTICS_CUSTOMERS](state: S, payload: Statistic[]): void;

  // Status
  [StatisticsMutationTypes.STATISTICS_LOADING](state: S): void;
  [StatisticsMutationTypes.STATISTICS_SUCCEEDED](state: S): void;
  [StatisticsMutationTypes.STATISTICS_ERROR](state: S): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [StatisticsMutationTypes.GET_STATISTICS_VISITORS](state: State, payload: Statistic[]) {
    state.visitors = payload;
  },
  [StatisticsMutationTypes.GET_STATISTICS_ORDERS](state: State, payload: Statistic[]) {
    state.orders = payload;
  },
  [StatisticsMutationTypes.GET_STATISTICS_CUSTOMERS](state: State, payload: Statistic[]) {
    state.customers = payload;
  },

  // Status
  [StatisticsMutationTypes.STATISTICS_LOADING](state: State) {
    state.status = Status.LOADING;
  },
  [StatisticsMutationTypes.STATISTICS_SUCCEEDED](state: State) {
    state.status = Status.SUCCEEDED;
  },
  [StatisticsMutationTypes.STATISTICS_ERROR](state: State) {
    state.status = Status.FAILED;
  },
};
