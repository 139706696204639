import { IFile } from '@/@typespaces/interfaces/file.interface';
import { MediaData } from '@/@typespaces/interfaces/media.interface';
import { Query } from '@/@typespaces/types/query.types';
import InstanceHttpClient from './instance';

export default class MediaClient extends InstanceHttpClient {
  constructor() {
    super('files');
  }

  async getFiles(data: { projectId: string; query: Query }): Promise<MediaData> {
    try {
      return await this.apiCall({
        method: 'GET',
        params: data.query,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async getOneFile(id: string): Promise<IFile> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: `${id}`,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async addOneFile(data: IFile): Promise<IFile> {
    try {
      return await this.apiCall({
        method: 'POST',
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async addManyFiles(data: any): Promise<IFile[]> {
    try {
      return await this.apiCall({
        method: 'POST',
        url: '/multi',
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async editFile(data: IFile): Promise<IFile> {
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `${data._id}`,
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async removeFile(id: string): Promise<IFile> {
    try {
      return await this.apiCall({
        method: 'DELETE',
        url: `${id}`,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
