export enum ReviewsMutationTypes {
  GET_REVIEWS = 'GET_REVIEWS',
  GET_CURRENT_REVIEW = 'GET_CURRENT_REVIEW',
  REMOVE_REVIEW = 'REMOVE_REVIEW',
  PUBLISH_REVIEW = 'PUBLISH_REVIEW',
  UN_PUBLISH_REVIEW = 'UN_PUBLISH_REVIEW',
  CREATE_REVIEW_ANSWER = 'CREATE_REVIEW_ANSWER',
  REMOVE_REVIEW_ANSWER = 'REMOVE_REVIEW_ANSWER',
  EDIT_REVIEW_ANSWER = 'EDIT_REVIEW_ANSWER',
  EDIT_REVIEW_SETTINGS = 'EDIT_REVIEW_SETTINGS',
  GET_REVIEW_SETTINGS = 'GET_REVIEW_SETTINGS',

  // Status
  REVIEWS_LOADING = 'REVIEWS_LOADING',
  REVIEWS_SUCCEEDED = 'REVIEWS_SUCCEEDED',
  REVIEWS_ERROR = 'REVIEWS_ERROR',
}
