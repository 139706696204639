import Status from '@/@typespaces/enum/status.enum';
import { ShortTemplate, TemplateCategories, TemplateStep } from '@/@typespaces/interfaces/template.interface';
import { PaginationInfo } from '@/@typespaces/types/paginationInfo';
import { RootState } from '@/store';
import { GetterTree } from 'vuex';
import { State } from './state';

export type Getters = {
  getTemplates(state: State): ShortTemplate[];
  getTemplateCategories(state: State): TemplateCategories[];
  getTemplateStep(state: State): TemplateStep | null;
  getTemplatesLoading(state: State): boolean;
  getTemplatesPagination(state: State): PaginationInfo;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getTemplates: (state) => state.data,
  getTemplateCategories: (state) => state.categories,
  getTemplateStep: (state) => state.templateStep,
  getTemplatesPagination: (state) => ({
    total: state.total,
    page: state.page,
    pageSize: state.pageSize,
  }),
  getTemplatesLoading: (state) => state.status === Status.LOADING,
};
