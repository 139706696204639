import { Category, CategoryFull, ICreateCategory } from '@/@typespaces/interfaces/category.interface';
import InstanceHttpClient from '../instance';

export default class CategoriesApi extends InstanceHttpClient {
  constructor() {
    super('shop/categories');
  }

  async getCategories(): Promise<Category[]> {
    try {
      return await this.apiCall({
        method: 'GET',
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async getCategoriesByAlias(alias: string): Promise<{
    categories: Category[];
    expanded_keys: string[];
  }> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: `alias/${alias}`,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async getOneCategory(id: string): Promise<CategoryFull> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: id,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async getCategoryPath(id: string): Promise<string[]> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: `get-category-path/${id}`,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async createCategory(data: ICreateCategory): Promise<Category> {
    try {
      return await this.apiCall({
        method: 'POST',
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async editPriorityCategory(data: ICreateCategory) {
    try {
      await this.apiCall({
        method: 'PATCH',
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async editCategory(payload: { id: string; data: CategoryFull }): Promise<CategoryFull> {
    const { data, id } = payload;
    try {
      return await this.apiCall({
        method: 'PUT',
        url: id,
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async deleteCategory(id: string): Promise<Category> {
    try {
      return await this.apiCall({
        method: 'DELETE',
        url: id,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async renameCategory(data: ICreateCategory): Promise<Category> {
    const { id, parent_category_id, title } = data;
    try {
      return await this.apiCall({
        method: 'PUT',
        url: id,
        data: { parent_category_id, title },
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
