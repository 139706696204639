import Status from '@/@typespaces/enum/status.enum';
import { IComment } from '@/@typespaces/interfaces/comment.interface';
import { PaginationInfo } from '@/@typespaces/types/paginationInfo';
import { RootState } from '@/store';
import { GetterTree } from 'vuex';
import { State } from './state';

export type Getters = {
  getAllComments(state: State): IComment[];
  getCommentById: (state: State) => (id: string) => IComment | undefined;
  getOneComment(state: State): IComment | null;
  getCommentsPagination(state: State): PaginationInfo;
  getCommentsLoading(state: State): boolean;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getAllComments: (state) => state.data,
  getCommentById: (state) => (id: string) => state.data.find((comment) => comment._id === id),
  getOneComment: (state) => state.comment,
  getCommentsPagination: (state) => ({
    total: state.total,
    page: state.page,
    pageSize: state.pageSize,
  }),
  getCommentsLoading: (state) => state.status === Status.LOADING,
};
