// Core
import { leavePage } from '@/helpers/confirmationsModalsFunctions';
import { Ref, ref } from 'vue';
import { onBeforeRouteLeave } from 'vue-router';

const useSubmit = (meta: Ref) => {
  const submitBtn = ref<Ref | null>(null);
  const isSubmitting = ref<boolean>(false);

  const submitHandler = () => {
    submitBtn.value.click();
    isSubmitting.value = true;
  };

  onBeforeRouteLeave((to, from, next) => {
    if (meta.value.dirty && !isSubmitting.value) {
      leavePage(next);
    } else {
      next();
    }
  });

  return {
    submitBtn,
    isSubmitting,
    submitHandler,
  };
};

export default useSubmit;
