export enum MediaMutationTypes {
  FETCH_FILES = 'FETCH_FILES',
  FETCH_FILE = 'FETCH_FILE',
  ADD_FILE = 'ADD_FILE',
  ADD_MANY_FILES = 'ADD_MANY_FILES',
  EDIT_FILE = 'EDIT_FILE',
  REMOVE_FILE = 'REMOVE_FILE',

  // Status
  MEDIA_LOADING = 'MEDIA_LOADING',
  MEDIA_SUCCEEDED = 'MEDIA_SUCCEEDED',
  MEDIA_ERROR = 'MEDIA_ERROR',
}
