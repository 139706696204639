// Core
import { computed, reactive, ref, Ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

// Types
import { Query, QueryShopType } from '@/@typespaces/types/query.types';
import { TableProps } from 'ant-design-vue';
import { Key } from 'ant-design-vue/es/_util/type';
import { PaginationInfo } from '@/@typespaces/types/paginationInfo';

const useTable = <T>(paginationData: Ref<PaginationInfo>, array: Ref, actionType: string, type?: 'shop') => {
  const router = useRouter();
  const route = useRoute();
  const store = useStore();
  const pagination = computed(() => ({
    current: paginationData?.value.page,
    pageSize: paginationData?.value.pageSize,
    showSizeChanger: true,
    size: 'large',
    pageSizeOptions: ['10', '20', '50', '100'],
    position: ['bottomCenter'],
    total: paginationData?.value.total,
  }));
  const selectedState = reactive<{ selectedRowKeys: Key[] | string[]; selectedElements: T[] }>({
    selectedRowKeys: [],
    selectedElements: [],
  });

  const createRequest = async (query: Query) => {
    if (route.params.projectId) {
      await store.dispatch(actionType, {
        projectId: route.params.projectId,
        query,
      });
    } else {
      await store.dispatch(actionType, query);
    }
  };

  const getRouterName = () => {
    if (route.name) {
      return route.name;
    }
    return undefined;
  };

  const rowSelection = ref({
    onChange: (selectedRowKeys: Key[] | string[], selectedRows: any[]) => {
      selectedState.selectedRowKeys = selectedRowKeys;
      selectedState.selectedElements = selectedRows;
    },
    onSelectAll: () => {
      selectedState.selectedRowKeys = array?.value.map((e: T, i: number) => i.toString());
    },
  });

  const onChange: TableProps<T>['onChange'] = async (pagination: any, filters: any, sorter: any) => {
    selectedState.selectedRowKeys = [];
    let order;
    switch (sorter.order) {
      case 'descend':
        order = 'DESC';
        break;
      case 'ascend':
        order = 'ASC';
        break;
      default:
        order = '';
        break;
    }
    const query: Query | QueryShopType = {
      ...router.currentRoute.value.query,
      page: pagination.current,
    };
    if (type === 'shop') {
      Object.assign(query, {
        limit: pagination.pageSize,
      });
    } else {
      Object.assign(query, {
        pageSize: pagination.pageSize,
      });
    }
    if (sorter.order) {
      query._sort = `${sorter.field}:${order}`;
    }
    await router.replace({
      name: getRouterName(),
      query,
    });
    await createRequest(query);
  };

  const handleClickRow = (cd: () => void) => ({
    onClick: (event: PointerEvent) => {
      const element = event.target as HTMLDivElement;
      if (element.closest('.actions')) {
        return;
      }
      cd();
    },
  });

  return {
    onChange,
    rowSelection,
    selectedState,
    pagination,
    handleClickRow,
  };
};

export default useTable;
