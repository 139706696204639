import Status from '@/@typespaces/enum/status.enum';
import { ILocation } from '@/@typespaces/interfaces/location.interface';

export interface State {
  data: ILocation[];
  status: Status;
  error: string | null;
}

export const state: State = {
  data: [],
  status: Status.IDLE,
  error: null,
};
