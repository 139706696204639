import Status from '@/@typespaces/enum/status.enum';
import { IWarehouse } from '@/@typespaces/interfaces/warehouses.interface';

export interface State {
  data: IWarehouse[];
  warehouseIds: string[];
  oneWarehouse: IWarehouse | null;
  status: Status;
  page: number;
  pageSize: number;
  total: number;
  error: string | null;
}

export const state: State = {
  data: [],
  warehouseIds: [],
  oneWarehouse: null,
  page: 0,
  pageSize: 0,
  total: 0,
  status: Status.IDLE,
  error: null,
};
