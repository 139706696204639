import { IFile } from '@/@typespaces/interfaces/file.interface';
import { Query } from '@/@typespaces/types/query.types';
import MediaClient from '@/api/media.api';
import { RootState } from '@/store';
import { ActionContext, ActionTree } from 'vuex';
import { MediaMutationTypes } from './mutation-types';
import { MediaActionTypes } from './action-types';
import { Mutations } from './mutations';
import { State } from './state';

const client = new MediaClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [MediaActionTypes.FETCH_FILES](
    { commit }: AugmentedActionContext,
    payload: { projectId: string; query: Query }
  ): void;
  [MediaActionTypes.FETCH_FILE]({ commit }: AugmentedActionContext, payload: string): void;
  [MediaActionTypes.ADD_FILE]({ commit }: AugmentedActionContext, payload: IFile): void;
  [MediaActionTypes.ADD_MANY_FILES]({ commit }: AugmentedActionContext, payload: IFile[]): void;
  [MediaActionTypes.EDIT_FILE]({ commit }: AugmentedActionContext, payload: IFile): void;
  [MediaActionTypes.REMOVE_FILE]({ commit }: AugmentedActionContext, payload: string): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [MediaActionTypes.FETCH_FILES]({ commit }, payload: { projectId: string; query: Query }) {
    commit(MediaMutationTypes.MEDIA_LOADING);
    try {
      const response = await client.getFiles(payload);
      commit(MediaMutationTypes.FETCH_FILES, response);
      commit(MediaMutationTypes.MEDIA_SUCCEEDED);
    } catch (err) {
      commit(MediaMutationTypes.MEDIA_ERROR, err as string);
      throw new Error(err as string);
    }
  },

  async [MediaActionTypes.FETCH_FILE]({ commit }, payload: string) {
    commit(MediaMutationTypes.MEDIA_LOADING);
    try {
      const response = await client.getOneFile(payload);
      commit(MediaMutationTypes.FETCH_FILE, response);
      commit(MediaMutationTypes.MEDIA_SUCCEEDED);
    } catch (err) {
      commit(MediaMutationTypes.MEDIA_ERROR, err as string);
      throw new Error(err as string);
    }
  },

  async [MediaActionTypes.ADD_FILE]({ commit }, payload: IFile) {
    commit(MediaMutationTypes.MEDIA_LOADING);
    try {
      const response = await client.addOneFile(payload);
      commit(MediaMutationTypes.ADD_FILE, response);
      commit(MediaMutationTypes.MEDIA_SUCCEEDED);
      return response;
    } catch (err) {
      commit(MediaMutationTypes.MEDIA_ERROR, err as string);
      throw new Error(err as string);
    }
  },

  async [MediaActionTypes.ADD_MANY_FILES]({ commit }, payload: IFile[]) {
    commit(MediaMutationTypes.MEDIA_LOADING);
    try {
      const response = await client.addManyFiles(payload);
      commit(MediaMutationTypes.ADD_MANY_FILES, response);
      commit(MediaMutationTypes.MEDIA_SUCCEEDED);
    } catch (err) {
      commit(MediaMutationTypes.MEDIA_ERROR, err as string);
      throw new Error(err as string);
    }
  },

  async [MediaActionTypes.EDIT_FILE]({ commit }, payload: IFile) {
    commit(MediaMutationTypes.MEDIA_LOADING);
    try {
      const response = await client.editFile(payload);
      commit(MediaMutationTypes.EDIT_FILE, response);
      commit(MediaMutationTypes.MEDIA_SUCCEEDED);
    } catch (err) {
      commit(MediaMutationTypes.MEDIA_ERROR, err as string);
      throw new Error(err as string);
    }
  },

  async [MediaActionTypes.REMOVE_FILE]({ commit }, payload: string) {
    commit(MediaMutationTypes.MEDIA_LOADING);
    try {
      await client.removeFile(payload);
      commit(MediaMutationTypes.REMOVE_FILE, payload);
      commit(MediaMutationTypes.MEDIA_SUCCEEDED);
    } catch (err) {
      commit(MediaMutationTypes.MEDIA_ERROR, err as string);
      throw new Error(err as string);
    }
  },
};
