import { IOrder, IOrderData, IOrderStatuses, IUpdateOrderStatuses } from '@/@typespaces/interfaces/orders.interface';
import { Query } from '@/@typespaces/types/query.types';
import InstanceHttpClient from '../instance';

export default class OrdersApi extends InstanceHttpClient {
  constructor() {
    super('shop/orders');
  }

  async getOrders(data: { projectId: string; query: Query }): Promise<IOrderData> {
    try {
      return await this.apiCall({
        method: 'GET',
        params: data.query,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async getOneOrder(data: { id: string; query: Query }): Promise<IOrder> {
    const { id, query } = data;
    try {
      return await this.apiCall({
        method: 'GET',
        url: id,
        params: query,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async deleteOneOrder(id: string): Promise<{ success: true }> {
    try {
      return await this.apiCall({
        method: 'POST',
        url: 'delete-many',
        data: { orders: [id] },
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async deleteManyOrders(data: { orders: string[] }): Promise<{ success: true }> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: 'delete-many',
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async updatePaymentStatuses(data: IUpdateOrderStatuses): Promise<{ success: true }> {
    try {
      return await this.apiCall({
        method: 'PUT',
        url: 'update-payment-statuses',
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async updateOrderStatuses(data: IUpdateOrderStatuses): Promise<IOrderStatuses> {
    try {
      return await this.apiCall({
        method: 'PUT',
        url: 'set-statuses',
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async editOrder(data: IOrder): Promise<{ success: true }> {
    try {
      return await this.apiCall({
        method: 'PUT',
        url: data.id,
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async deleteProductInOrder(data: { productId: string; orderId: string }): Promise<{ success: true }> {
    try {
      await this.apiCall({
        method: 'GET',
        url: 'https://jsonplaceholder.typicode.com/posts/1/comments', // временно
      });
      return { success: true };
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async deleteManyProductsInOrder(data: { productIds: string[]; orderId: string }): Promise<{ success: true }> {
    try {
      await this.apiCall({
        method: 'GET',
        url: 'https://jsonplaceholder.typicode.com/posts/1/comments', // временно
      });
      return { success: true };
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
