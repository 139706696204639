import Status from '@/@typespaces/enum/status.enum';
import { IFile } from '@/@typespaces/interfaces/file.interface';
import { PaginationInfo } from '@/@typespaces/types/paginationInfo';
import { RootState } from '@/store';
import { GetterTree } from 'vuex';
import { State } from './state';

export type Getters = {
  getMedia(state: State): IFile[];
  getMediaPaginationInfo(state: State): PaginationInfo;
  getMediaLoading(state: State): boolean;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getMedia: (state) => state.data,
  getMediaLoading: (state) => state.status === Status.LOADING,
  getMediaPaginationInfo: (state) => ({
    page: state.page,
    pageSize: state.pageSize,
    total: state.total,
  }),
};
