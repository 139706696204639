import { CheckHttpsPayload, DomainPayload } from '@/@typespaces/types/form/domain.types';
import DomainClient from '@/api/domain';
import { RootState } from '@/store';
import { ActionContext, ActionTree } from 'vuex';
import { DomainMutationTypes } from './mutation-types';
import { DomainActionTypes } from './action-types';
import { Mutations } from './mutations';
import { State } from './state';

const client = new DomainClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [DomainActionTypes.CONNECT_DOMAIN]({ commit }: AugmentedActionContext, payload: DomainPayload): void;

  [DomainActionTypes.CHECK_HTTPS_ACCESS]({ commit }: AugmentedActionContext, payload: CheckHttpsPayload): void;

  [DomainActionTypes.REMOVE_DOMAIN]({ commit }: AugmentedActionContext, payload: CheckHttpsPayload): void;

  [DomainActionTypes.CLEAR_DOMAIN_STORE]({ commit }: AugmentedActionContext): void;
  [DomainActionTypes.CHECK_EXPIRY_DATE_DOMAIN](
    { commit }: AugmentedActionContext,
    payload: {
      projectId: string;
      domain: string;
    }
  ): Promise<any>;
  [DomainActionTypes.CHECK_DOMAIN](
    { commit }: AugmentedActionContext,
    payload: {
      projectId: string;
      domain: string;
    }
  ): Promise<{
    cname: boolean;
    https: boolean;
  }>;
  [DomainActionTypes.CHECK_EXIST_DOMAIN](
    { commit }: AugmentedActionContext,
    payload: {
      projectId: string;
      domain: string;
    }
  ): Promise<boolean>;
  [DomainActionTypes.CHECK_SSL_CERT_FILE](
    { commit }: AugmentedActionContext,
    payload: {
      projectId: string;
      body: FormData;
    }
  ): void;
  [DomainActionTypes.CHANGE_MAIN_DOMAIN](
    { commit }: AugmentedActionContext,
    payload: {
      projectId: string;
      domain: string;
    }
  ): Promise<any>;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [DomainActionTypes.CONNECT_DOMAIN]({ commit }, payload: DomainPayload) {
    commit(DomainMutationTypes.DOMAIN_LOADING);
    try {
      const response = await client.connectDomain(payload);
      commit(DomainMutationTypes.CONNECT_DOMAIN, response);
      commit(DomainMutationTypes.DOMAIN_SUCCEEDED);
    } catch (err) {
      commit(DomainMutationTypes.DOMAIN_ERROR, err as string);
      throw new Error(err as string);
    }
  },

  async [DomainActionTypes.CHECK_HTTPS_ACCESS]({ commit }, payload: CheckHttpsPayload) {
    commit(DomainMutationTypes.DOMAIN_LOADING);
    try {
      const response = await client.checkHttpsAccess(payload);
      commit(DomainMutationTypes.CHECK_HTTPS_ACCESS, response);
      commit(DomainMutationTypes.DOMAIN_SUCCEEDED);
    } catch (err) {
      commit(DomainMutationTypes.DOMAIN_ERROR, err as string);
      throw new Error(err as string);
    }
  },
  async [DomainActionTypes.REMOVE_DOMAIN]({ commit }, payload: CheckHttpsPayload) {
    commit(DomainMutationTypes.DOMAIN_LOADING);
    try {
      const result = await client.removeDomain(payload);
      commit(DomainMutationTypes.REMOVE_DOMAIN);
      commit(DomainMutationTypes.DOMAIN_SUCCEEDED);
      return result;
    } catch (err) {
      commit(DomainMutationTypes.DOMAIN_ERROR, err as string);
      throw new Error(err as string);
    }
  },
  [DomainActionTypes.CLEAR_DOMAIN_STORE]({ commit }) {
    commit(DomainMutationTypes.CLEAR_DOMAIN_STORE);
  },
  async [DomainActionTypes.CHECK_EXPIRY_DATE_DOMAIN]({ commit }, payload) {
    try {
      return client.checkExpiryDate(payload);
    } catch (error: any) {
      throw new Error(error);
    }
  },
  async [DomainActionTypes.CHECK_EXIST_DOMAIN]({ commit }, payload) {
    try {
      return client.checkExistDomain(payload);
    } catch (error: any) {
      throw new Error(error);
    }
  },
  async [DomainActionTypes.CHECK_DOMAIN]({ commit }, payload) {
    try {
      return client.checkDomain(payload);
    } catch (error: any) {
      throw new Error(error);
    }
  },
  async [DomainActionTypes.CHECK_SSL_CERT_FILE]({ commit }, payload) {
    try {
      return client.checkSSLCert(payload);
    } catch (error: any) {
      throw new Error(error);
    }
  },
  async [DomainActionTypes.CHANGE_MAIN_DOMAIN]({ commit }, payload) {
    try {
      return client.changeMainDomain(payload);
    } catch (error: any) {
      throw new Error(error);
    }
  },
};
