import { User } from '@/@typespaces/interfaces/users.interface';
import InstanceHttpClient from './instance';

export default class UsersClient extends InstanceHttpClient {
  constructor() {
    super('users');
  }

  async getUsers(projectId: string): Promise<User[]> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: projectId,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
