import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store';
import { IOrder, IUpdateOrderStatuses } from '@/@typespaces/interfaces/orders.interface';

import OrdersApi from '@/api/shop/orders.api';
import { OrdersActionTypes } from '@/store/modules/orders/action-types';
import { OrdersMutationTypes } from '@/store/modules/orders/mutation-types';
import { Query } from '@/@typespaces/types/query.types';
import { Mutations } from './mutations';
import { State } from './state';

const api = new OrdersApi();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [OrdersActionTypes.GET_ORDERS](
    { commit }: AugmentedActionContext,
    payload: { projectId: string; query: Query }
  ): void;

  [OrdersActionTypes.GET_ONE_ORDER]({ commit }: AugmentedActionContext, payload: { id: string; query: Query }): void;

  [OrdersActionTypes.EDIT_ORDER]({ commit }: AugmentedActionContext, payload: IOrder): void;

  [OrdersActionTypes.DELETE_ONE_ORDER]({ commit }: AugmentedActionContext, id: string): void;

  [OrdersActionTypes.DELETE_MANY_ORDERS]({ commit }: AugmentedActionContext, data: { orders: string[] }): void;

  [OrdersActionTypes.UPDATE_ORDER_STATUS]({ commit }: AugmentedActionContext, data: IUpdateOrderStatuses): void;

  [OrdersActionTypes.UPDATE_PAYMENT_STATUS]({ commit }: AugmentedActionContext, data: IUpdateOrderStatuses): void;

  [OrdersActionTypes.DELETE_PRODUCT_IN_ORDER](
    { commit }: AugmentedActionContext,
    data: { productId: string; orderId: string }
  ): void;

  [OrdersActionTypes.DELETE_MANY_PRODUCTS_IN_ORDER](
    { commit }: AugmentedActionContext,
    data: { productIds: string[]; orderId: string }
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [OrdersActionTypes.GET_ORDERS]({ commit }, payload: { projectId: string; query: Query }) {
    commit(OrdersMutationTypes.ORDER_LOADING);
    try {
      const orders = await api.getOrders(payload);
      commit(OrdersMutationTypes.GET_ORDERS, orders);
      commit(OrdersMutationTypes.ORDER_SUCCEEDED);
    } catch (e) {
      commit(OrdersMutationTypes.ORDER_ERROR, e as string);
      throw new Error(e as string);
    }
  },

  async [OrdersActionTypes.GET_ONE_ORDER]({ commit }, payload: { id: string; query: Query }) {
    commit(OrdersMutationTypes.ORDER_LOADING);
    try {
      const order = await api.getOneOrder(payload);
      commit(OrdersMutationTypes.GET_ONE_ORDER, order);
      commit(OrdersMutationTypes.ORDER_SUCCEEDED);
    } catch (e) {
      commit(OrdersMutationTypes.ORDER_ERROR, e as string);
      throw new Error(e as string);
    }
  },
  async [OrdersActionTypes.DELETE_ONE_ORDER]({ commit }, id: string) {
    try {
      const response = await api.deleteOneOrder(id);
      if (response.success) {
        commit(OrdersMutationTypes.DELETE_ONE_ORDER, id);
      }
    } catch (e) {
      commit(OrdersMutationTypes.ORDER_ERROR, e as string);
      throw new Error(e as string);
    }
  },
  async [OrdersActionTypes.DELETE_MANY_ORDERS]({ commit }, data: { orders: string[] }) {
    try {
      const response = await api.deleteManyOrders(data);
      // if (response.success) {
      commit(OrdersMutationTypes.DELETE_MANY_ORDERS, data.orders);
      // }
    } catch (e) {
      commit(OrdersMutationTypes.ORDER_ERROR, e as string);
      throw new Error(e as string);
    }
  },

  async [OrdersActionTypes.UPDATE_PAYMENT_STATUS]({ commit }, data: IUpdateOrderStatuses) {
    commit(OrdersMutationTypes.ORDER_LOADING);
    try {
      const response = await api.updatePaymentStatuses(data);
      if (response.success) {
        commit(OrdersMutationTypes.ORDER_SUCCEEDED);
        commit(OrdersMutationTypes.UPDATE_PAYMENT_STATUS, data);
      }
    } catch (e) {
      commit(OrdersMutationTypes.ORDER_ERROR, e as string);
      throw new Error(e as string);
    }
  },
  async [OrdersActionTypes.UPDATE_ORDER_STATUS]({ commit }, data: IUpdateOrderStatuses) {
    commit(OrdersMutationTypes.ORDER_LOADING);
    try {
      const response = await api.updateOrderStatuses(data);
      commit(OrdersMutationTypes.UPDATE_ORDER_STATUS, { data, status: response });
      commit(OrdersMutationTypes.ORDER_SUCCEEDED);
    } catch (e) {
      commit(OrdersMutationTypes.ORDER_ERROR, e as string);
      throw new Error(e as string);
    }
  },

  async [OrdersActionTypes.EDIT_ORDER]({ commit }, data: IOrder) {
    try {
      const response = await api.editOrder(data);
      if (response.success) {
        commit(OrdersMutationTypes.EDIT_ORDER, data);
      }
    } catch (e) {
      commit(OrdersMutationTypes.ORDER_ERROR, e as string);
      throw new Error(e as string);
    }
  },

  async [OrdersActionTypes.DELETE_PRODUCT_IN_ORDER]({ commit }, data: { productId: string; orderId: string }) {
    try {
      const response = await api.deleteProductInOrder(data);
      if (response.success) {
        commit(OrdersMutationTypes.DELETE_PRODUCT_IN_ORDER, data);
      }
    } catch (e) {
      commit(OrdersMutationTypes.ORDER_ERROR, e as string);
      throw new Error(e as string);
    }
  },

  async [OrdersActionTypes.DELETE_MANY_PRODUCTS_IN_ORDER]({ commit }, data: { productIds: string[]; orderId: string }) {
    try {
      const response = await api.deleteManyProductsInOrder(data);
      if (response.success) {
        commit(OrdersMutationTypes.DELETE_MANY_PRODUCTS_IN_ORDER, data);
      }
    } catch (e) {
      commit(OrdersMutationTypes.ORDER_ERROR, e as string);
      throw new Error(e as string);
    }
  },
};
