import { MutationTree } from 'vuex';
import { CustomerData } from '@/@typespaces/interfaces/customer.interface';
import Status from '@/@typespaces/enum/status.enum';
import { State } from './state';
import { CustomersMutationTypes } from './mutation-types';

export type Mutations<S = State> = {
  [CustomersMutationTypes.FETCH_CUSTOMERS](state: S, payload: CustomerData): void;
  [CustomersMutationTypes.EDIT_CUSTOMER](state: S, payload: { userId: string; location: string }): void;

  // Status
  [CustomersMutationTypes.CUSTOMER_ERROR](state: S, payload: string): void;
  [CustomersMutationTypes.CUSTOMERS_LOADING](state: S): void;
  [CustomersMutationTypes.CUSTOMERS_SUCCEEDED](state: S): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [CustomersMutationTypes.FETCH_CUSTOMERS](state: State, data: CustomerData) {
    state.total = data.total;
    state.data = data.data.customers;
    state.ids = data.data.customersIds;
    state.pageSize = data.pageSize;
    state.page = data.page;
  },

  [CustomersMutationTypes.EDIT_CUSTOMER](state: State, payload: { userId: string; location: string }) {
    state.data = state.data.map((user) => {
      if (user.id === payload.userId) {
        return { ...user, address: { ...user.address, location: payload.location } };
      }
      return user;
    });
  },

  // Status
  [CustomersMutationTypes.CUSTOMERS_LOADING](state: State) {
    state.status = Status.LOADING;
  },
  [CustomersMutationTypes.CUSTOMERS_SUCCEEDED](state: State) {
    state.status = Status.SUCCEEDED;
  },
  [CustomersMutationTypes.CUSTOMER_ERROR](state: State, error) {
    state.error = error;
  },
};
