import Status from '@/@typespaces/enum/status.enum';
import { MainDomain } from '@/@typespaces/interfaces/domain.interface';

export interface State {
  error: string | null;
  status: Status;
  domain: MainDomain | null;
}

export const state: State = {
  domain: null,
  error: null,
  status: Status.IDLE,
};
