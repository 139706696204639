import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store';
import ReviewsClient from '@/api/shop/reviews.api';
import { ReviewSettings } from '@/@typespaces/interfaces/review';
import { ReviewsAnswer } from '@/@typespaces/types/form/reviews.types';
import { Query } from '@/@typespaces/types/query.types';
import { State } from './state';
import { Mutations } from './mutations';
import { ReviewsMutationTypes } from './mutation-types';
import { ReviewsActionTypes } from './action-types';

const client = new ReviewsClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [ReviewsActionTypes.GET_REVIEWS](
    { commit }: AugmentedActionContext,
    payload?: { projectId: string; query: Query }
  ): void;

  [ReviewsActionTypes.REMOVE_REVIEW]({ commit }: AugmentedActionContext, id: string): void;

  [ReviewsActionTypes.GET_CURRENT_REVIEW]({ commit }: AugmentedActionContext, id: string): void;

  [ReviewsActionTypes.PUBLISH_REVIEW]({ commit }: AugmentedActionContext, id: string): void;

  [ReviewsActionTypes.UN_PUBLISH_REVIEW]({ commit }: AugmentedActionContext, id: string): void;

  [ReviewsActionTypes.CREATE_REVIEW_ANSWER]({ commit }: AugmentedActionContext, payload: ReviewsAnswer): void;

  [ReviewsActionTypes.EDIT_REVIEW_ANSWER](
    { commit }: AugmentedActionContext,
    payload: { id: string; data: ReviewsAnswer }
  ): void;

  [ReviewsActionTypes.REMOVE_REVIEW_ANSWER]({ commit }: AugmentedActionContext, payload: string): void;

  [ReviewsActionTypes.GET_REVIEW_SETTINGS]({ commit }: AugmentedActionContext): void;

  [ReviewsActionTypes.EDIT_REVIEW_SETTINGS](
    { commit }: AugmentedActionContext,
    payload: { id: string; data: ReviewSettings }
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ReviewsActionTypes.GET_REVIEWS](
    { commit }: AugmentedActionContext,
    payload?: { projectId: string; query: Query }
  ) {
    commit(ReviewsMutationTypes.REVIEWS_LOADING);
    try {
      const data = await client.getReviews(payload);
      commit(ReviewsMutationTypes.GET_REVIEWS, data);
      commit(ReviewsMutationTypes.REVIEWS_SUCCEEDED);
    } catch (error) {
      commit(ReviewsMutationTypes.REVIEWS_ERROR);
      throw new Error(error as string);
    }
  },

  async [ReviewsActionTypes.GET_CURRENT_REVIEW]({ commit }: AugmentedActionContext, id: string) {
    commit(ReviewsMutationTypes.REVIEWS_LOADING);
    try {
      const data = await client.getOneReview(id);
      commit(ReviewsMutationTypes.GET_CURRENT_REVIEW, data);
      commit(ReviewsMutationTypes.REVIEWS_SUCCEEDED);
    } catch (error) {
      commit(ReviewsMutationTypes.REVIEWS_ERROR);
      throw new Error(error as string);
    }
  },

  async [ReviewsActionTypes.REMOVE_REVIEW]({ commit }: AugmentedActionContext, id: string) {
    try {
      await client.removeReview(id);
      commit(ReviewsMutationTypes.REMOVE_REVIEW, id);
    } catch (error) {
      commit(ReviewsMutationTypes.REVIEWS_ERROR);
      throw new Error(error as string);
    }
  },

  async [ReviewsActionTypes.PUBLISH_REVIEW]({ commit }: AugmentedActionContext, id: string) {
    try {
      await client.publishReview(id);
      commit(ReviewsMutationTypes.PUBLISH_REVIEW, id);
    } catch (error) {
      commit(ReviewsMutationTypes.REVIEWS_ERROR);
      throw new Error(error as string);
    }
  },

  async [ReviewsActionTypes.UN_PUBLISH_REVIEW]({ commit }: AugmentedActionContext, id: string) {
    try {
      await client.unPublishReview(id);
      commit(ReviewsMutationTypes.UN_PUBLISH_REVIEW, id);
    } catch (error) {
      commit(ReviewsMutationTypes.REVIEWS_ERROR);
      throw new Error(error as string);
    }
  },

  async [ReviewsActionTypes.CREATE_REVIEW_ANSWER]({ commit }: AugmentedActionContext, payload: ReviewsAnswer) {
    try {
      const response = await client.createReviewAnswer(payload);
      commit(ReviewsMutationTypes.CREATE_REVIEW_ANSWER, response);
    } catch (error) {
      commit(ReviewsMutationTypes.REVIEWS_ERROR);
      throw new Error(error as string);
    }
  },

  async [ReviewsActionTypes.EDIT_REVIEW_ANSWER](
    { commit }: AugmentedActionContext,
    payload: { id: string; data: ReviewsAnswer }
  ) {
    try {
      await client.editReviewAnswer(payload);
      commit(ReviewsMutationTypes.EDIT_REVIEW_ANSWER, payload.data.text);
    } catch (error) {
      commit(ReviewsMutationTypes.REVIEWS_ERROR);
      throw new Error(error as string);
    }
  },

  async [ReviewsActionTypes.REMOVE_REVIEW_ANSWER]({ commit }: AugmentedActionContext, payload: string) {
    try {
      await client.removeReviewAnswer(payload);
      commit(ReviewsMutationTypes.REMOVE_REVIEW_ANSWER);
    } catch (error) {
      commit(ReviewsMutationTypes.REVIEWS_ERROR);
      throw new Error(error as string);
    }
  },

  async [ReviewsActionTypes.GET_REVIEW_SETTINGS]({ commit }: AugmentedActionContext) {
    commit(ReviewsMutationTypes.REVIEWS_LOADING);
    try {
      const response = await client.getReviewsSettings();
      commit(ReviewsMutationTypes.GET_REVIEW_SETTINGS, response);
      commit(ReviewsMutationTypes.REVIEWS_SUCCEEDED);
    } catch (error) {
      commit(ReviewsMutationTypes.REVIEWS_ERROR);
      throw new Error(error as string);
    }
  },

  async [ReviewsActionTypes.EDIT_REVIEW_SETTINGS](
    { commit }: AugmentedActionContext,
    payload: { id: string; data: ReviewSettings }
  ) {
    try {
      const response = await client.editReviewsSettings(payload);
      commit(ReviewsMutationTypes.EDIT_REVIEW_SETTINGS, response);
    } catch (error) {
      commit(ReviewsMutationTypes.REVIEWS_ERROR);
      throw new Error(error as string);
    }
  },
};
