import BaseError from '@/components/BaseError.vue';
import createVNodeError from '@/helpers/createVNodeError';
import { store } from '@/store';
import { DomainActionTypes } from '@/store/modules/domain/action-types';
import { message } from 'ant-design-vue';
import { toASCII } from 'punycode';

// eslint-disable-next-line consistent-return
export const checkDomain = async (
  projectId: string,
  domain: string
): Promise<{ cname: boolean; https: boolean; isExist: boolean } | undefined> => {
  try {
    const convertDomain = toASCII(domain);
    const cnameCheckResult = (await store.dispatch(DomainActionTypes.CHECK_DOMAIN, {
      projectId,
      domain: convertDomain,
    })) as {
      cname: boolean;
      https: boolean;
    };
    const checkExistDomainResult = await store.dispatch(DomainActionTypes.CHECK_EXIST_DOMAIN, {
      projectId,
      domain: convertDomain,
    });
    return { cname: cnameCheckResult.cname, https: cnameCheckResult.https, isExist: checkExistDomainResult };
  } catch (error) {
    if (error instanceof Error) {
      const errorMessage = error.message;
      message.error(createVNodeError(BaseError, 'Не удалось проверить домен', errorMessage));
    }
  }
  return undefined;
};
