import { MutationTree } from 'vuex';
import Status from '@/@typespaces/enum/status.enum';
import { Product, IProductData } from '@/@typespaces/interfaces/products.interface';
import { State } from './state';
import { ProductsMutationTypes } from './mutation-types';

export type Mutations<S = State> = {
  [ProductsMutationTypes.GET_PRODUCTS](state: S, payload: IProductData): void;
  [ProductsMutationTypes.FILTER_PRODUCT_BY_CATEGORY](state: S, payload: string): void;
  [ProductsMutationTypes.GET_ONE_PRODUCT](state: S, data: Product): void;
  [ProductsMutationTypes.CREATE_PRODUCT](state: S, data: Product): void;
  [ProductsMutationTypes.EDIT_PRODUCT](state: S, payload: Product): void;
  [ProductsMutationTypes.DELETE_PRODUCT](state: S, id: string): void;
  [ProductsMutationTypes.DELETE_MANY_PRODUCTS](state: S, data: string[]): void;
  [ProductsMutationTypes.SHOW_MANY_PRODUCTS](state: S, data: string[]): void;
  [ProductsMutationTypes.HIDE_MANY_PRODUCTS](state: S, data: string[]): void;
  [ProductsMutationTypes.HIDE_PRODUCT](state: S, data: string): void;
  [ProductsMutationTypes.SHOW_PRODUCT](state: S, data: string): void;
  [ProductsMutationTypes.CLEAR_CURRENT_PRODUCT](state: S): void;

  [ProductsMutationTypes.PRODUCTS_ERROR](state: S, payload: string): void;
  [ProductsMutationTypes.PRODUCTS_LOADING](state: S): void;
  [ProductsMutationTypes.PRODUCTS_SUCCEEDED](state: S): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [ProductsMutationTypes.GET_PRODUCTS](state: State, data: IProductData) {
    state.page = data.page;
    state.count = data.count;
    state.limit = data.limit;
    state.products = data.products;
    state.allProducts = data.products;
  },
  [ProductsMutationTypes.GET_ONE_PRODUCT](state: State, data: Product) {
    state.oneProduct = data;
  },
  [ProductsMutationTypes.FILTER_PRODUCT_BY_CATEGORY](state: State, id: string) {
    const filteredProduct = state.products.filter((product) => product.category_id === id);
    if (filteredProduct.length) {
      state.products = filteredProduct;
    } else {
      state.products = state.allProducts;
    }
  },

  [ProductsMutationTypes.CREATE_PRODUCT](state: State, data: Product) {
    state.products.push(data);
  },
  [ProductsMutationTypes.DELETE_PRODUCT](state: State, id: string) {
    const removeIndex = state.products.map((item) => item.id).indexOf(id);
    state.products.splice(removeIndex, 1);
  },
  [ProductsMutationTypes.EDIT_PRODUCT](state: State, data) {
    state.products = state.products.map((item) => {
      if (item.id === data.id) {
        return data;
      }
      return item;
    });
  },
  [ProductsMutationTypes.DELETE_MANY_PRODUCTS](state: State, data) {
    state.products = state.products.filter((product) => !data.includes(product.id));
  },
  [ProductsMutationTypes.SHOW_MANY_PRODUCTS](state: State, data) {
    state.products = state.products.map((item) => {
      if (data.includes(item.id)) {
        return {
          ...item,
          publish: true,
        };
      }
      return item;
    });
  },
  [ProductsMutationTypes.HIDE_MANY_PRODUCTS](state: State, data) {
    state.products = state.products.map((item) => {
      if (data.includes(item.id)) {
        return {
          ...item,
          publish: false,
        };
      }
      return item;
    });
  },

  [ProductsMutationTypes.SHOW_PRODUCT](state: State, data) {
    state.products = state.products.map((item) => {
      if (item.id === data) {
        return {
          ...item,
          publish: true,
        };
      }
      return item;
    });
  },

  [ProductsMutationTypes.HIDE_PRODUCT](state: State, data) {
    state.products = state.products.map((item) => {
      if (item.id === data) {
        return {
          ...item,
          publish: false,
        };
      }
      return item;
    });
  },

  [ProductsMutationTypes.CLEAR_CURRENT_PRODUCT](state: State) {
    state.oneProduct = null;
  },

  // Status
  [ProductsMutationTypes.PRODUCTS_LOADING](state: State) {
    state.status = Status.LOADING;
  },
  [ProductsMutationTypes.PRODUCTS_SUCCEEDED](state: State) {
    state.status = Status.SUCCEEDED;
  },
  [ProductsMutationTypes.PRODUCTS_ERROR](state: State, data: string) {
    state.error = data;
    state.status = Status.IDLE;
  },
};
