export enum NewsAndBlogMutationTypes {
  FETCH_NEWS_AND_BLOGS_DATA = 'FETCH_NEWS_AND_BLOGS_DATA',
  FETCH_NEWS_AND_BLOGS_SETTINGS = 'FETCH_NEWS_AND_BLOGS_SETTINGS',
  FETCH_NEWS_AND_BLOGS_AUTHORS = 'FETCH_NEWS_AND_BLOGS_AUTHORS',
  REMOVE_NEWS_AND_BLOGS_AUTHOR = 'REMOVE_NEWS_AND_BLOGS_AUTHOR',
  NEWS_AND_BLOGS_EDIT_AUTHOR = 'NEWS_AND_BLOGS_EDIT_AUTHOR',
  EDIT_AND_BLOGS_SETTINGS = 'EDIT_AND_BLOGS_SETTINGS',
  FETCH_ONE_BLOG = 'FETCH_ONE_BLOG',
  REMOVE_ONE_BLOG = 'REMOVE_ONE_BLOG',
  EDIT_BLOG = 'EDIT_BLOG',
  CREATE_BLOG = 'CREATE_BLOG',
  SET_STATUS_BLOG = 'SET_STATUS_BLOG',
  CLEAR_ONE_POST = 'CLEAR_ONE_POST',
  // Status
  NEWS_AND_BLOGS_LOADING = 'NEWS_AND_BLOGS_LOADING',
  NEWS_AND_BLOGS_SUCCEEDED = 'NEWS_AND_BLOGS_SUCCEEDED',
  NEWS_AND_BLOGS_ERROR = 'NEWS_AND_BLOGS_ERROR',
  COPY_POST = 'COPY_POST',
}
