import { Seo } from '@/@typespaces/interfaces/template.interface';
import { dataMapper } from '@/utils/dataMapper';
import InstanceHttpClient from './instance';

export default class SeoClient extends InstanceHttpClient {
  constructor() {
    super('seo');
  }

  async editSeo(body: { projectId: string; data: Partial<Seo> }) {
    const { projectId, data } = body;
    const remapData = dataMapper(data);
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `/${projectId}`,
        data: remapData,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async generateSitemap(body: { projectId: string; data: { templateId: string } }) {
    const { projectId, data } = body;

    try {
      await this.apiCall({
        method: 'POST',
        url: `/${projectId}/generate-sitemap`,
        data: { templateId: data.templateId },
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
