// Core
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { store } from '@/store';

// Layouts
import Default from '@/layouts/DefaultLayout.vue';
import LayoutWithSidebar from '@/layouts/LayoutWithSidebar.vue';
import EditorLayout from '@/layouts/EditorLayout.vue';
import BlankLayout from '@/layouts/BlankLayout.vue';

// Auth
import Auth from '@/layouts/AuthLayout.vue';
import Login from '@/views/Authorization/Login.vue';
import PasswordRecovery from '@/views/Authorization/PasswordRecovery.vue';
import PasswordCreate from '@/views/Authorization/PasswordCreate.vue';
import Registration from '@/views/Authorization/Registration.vue';
import RestorePassword from '@/views/Authorization/RestorePassword.vue';
import FastRegister from '@/views/Authorization/FastRegister.vue';
import ConfirmAccount from '@/views/Authorization/ConfirmAccount.vue';
import About from '@/views/AboutView.vue';

// GeneralSettings
import GeneralSettings from '@/views/Settings/GeneralSettings.vue';

// Notification
import Notification from '@/views/Notification/NotificationsMenu.vue';
import NotificationEditEmailTemplate from '@/views/Notification/NotificationEditEmailTemplate.vue';

// Projects
import ProjectsPage from '@/views/Projects/ProjectsPage.vue';
import EditProject from '@/views/Projects/EditProject.vue';
import CreateProject from '@/views/Projects/CreatePoject/CreateProject.vue';

// UserProfile
import UserProfile from '@/views/Profile/UserProfile.vue';

// Comment
import Comment from '@/views/Comments/CommentEdit.vue';
import ProjectComment from '@/views/Comments/CommentsPage.vue';
import SettingsComment from '@/views/Comments/SettingsComment.vue';

// Reviews
import ProjectReviews from '@/views/Reviews/ProjectReviews.vue';
import ProjectReview from '@/views/Reviews/ProjectReview.vue';
import SettingReview from '@/views/Reviews/SettingsReviews.vue';

// Customers
import CustomersPage from '@/views/Customers/CustomersPage.vue';

// Orders
import OrdersTable from '@/views/Orders/OrdersTable.vue';
import OrderProductTable from '@/views/Orders/OrderProductsTable.vue';
import OrderInfo from '@/views/Orders/OrderInfo.vue';

// Seo
import SeoSection from '@/views/Seo/SeoView.vue';
import SeoRobots from '@/views/Seo/views/Robots.vue';
import SeoSiteMap from '@/views/Seo/views/SiteMap.vue';
import SeoMetricsAndAnalytics from '@/views/Seo/views/MetricsAndAnalytics.vue';
import SeoWebMaster from '@/views/Seo/views/WebMaster.vue';

// ThirdPartyCode
import ThirdPartyCode from '@/views/ThirdPartyCode/ThirdPartyCode.vue';

// RolesAndAccess
import RolesAndAccess from '@/views/RolesAndAccess/RolesAndAccess.vue';

// Products
import ProductsPage from '@/views/Products/ProductsPage.vue';
import ProductEditPage from '@/views/Products/ProductEditPage.vue';
import ProductCreate from '@/views/Products/ProductCreate.vue';
import ProductSettingsCategory from '@/views/Products/Categories/ProductSettingsCategory.vue';

// Contacts
import Contacts from '@/views/Contacts/Contacts.vue';

// Warehouses
import WarehousesPage from '@/views/warehouses/WarehousesPage.vue';
import WarehouseEdit from '@/views/warehouses/WarehouseEdit.vue';
import WarehouseCreate from '@/views/warehouses/WarehouseCreate.vue';

// Editor
import Editor from '@/views/Editor/Editor.vue';

// Integration
import PaymentIntegration from '@/views/Integration/PaymentIntegration.vue';
import YoomoneyIntegration from '@/views/Integration/YoomoneyIntegration.vue';
import BitrixIntegration from '@/views/Integration/BitrixIntegration.vue';

// News and Blog
import NewsAndBlogPage from '@/views/NewsAndBlog/NewsAndBlogPage.vue';
import NewsAndBlogCreate from '@/views/NewsAndBlog/NewsAndBlogCreate.vue';
import NewsAndBlogEdit from '@/views/NewsAndBlog/NewsAndBlogEdit.vue';
import NewsAndBlogSettings from '@/views/NewsAndBlog/NewsAndBlogSettings.vue';

// Rubrics
import RubricsPage from '@/views/Rubrics/RubricsPage.vue';

// Files
import FilesAndMedia from '@/views/FilesAndMedia/FilesAndMedia.vue';

// Documents
import ConfidentialPolicy from '@/views/ConfidentialPolicy.vue';
import UserAgreement from '@/views/UserAgreement.vue';

// Delivery
import DeliveryPage from '@/views/Delivery/DeliveryPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/sign-in',
    name: 'SignIn',
    component: Login,
    meta: {
      layout: Auth,
    },
  },
  {
    path: '/password-recovery',
    name: 'PasswordRecovery',
    component: PasswordRecovery,
    meta: {
      layout: Auth,
    },
  },
  {
    path: '/recovery-password',
    name: 'RestorePassword',
    component: RestorePassword,
    meta: {
      layout: Auth,
    },
  },
  {
    path: '/set-password',
    name: 'PasswordCreate',
    component: PasswordCreate,
    meta: {
      layout: Auth,
    },
  },
  {
    path: '/fast-register',
    name: 'FastRegister',
    component: FastRegister,
    meta: {
      layout: BlankLayout,
    },
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: Registration,
    meta: {
      layout: Auth,
    },
  },
  {
    path: '/confirm-account',
    name: 'ConfirmAccount',
    component: ConfirmAccount,
    meta: {
      layout: Auth,
    },
  },
  {
    path: '/',
    redirect: '/projects?page=1&pageSize=8&_sort=createdAt:ASC',
    name: 'Home',
    component: About,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/projects',
    name: 'ProjectsPage',
    component: ProjectsPage,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/project-create',
    name: 'CreateProject',
    component: CreateProject,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/:projectId',
    name: 'ProjectEdit',
    component: EditProject,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/editor',
    name: 'Editor',
    component: () => import('@/views/Editor/Editor.vue'),
    meta: {
      layout: EditorLayout,
    },
  },
  {
    path: '/service',
    name: 'Service',
    component: About,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/knowledge-base',
    name: 'KnowledgeBase',
    component: About,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/:projectId/general-settings',
    name: 'GeneralSettings',
    component: GeneralSettings,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/confidential',
    name: 'ConfidentialPolicy',
    component: ConfidentialPolicy,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/user-agreement',
    name: 'UserAgreement',
    component: UserAgreement,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/comment',
    name: 'ProjectComment',
    component: ProjectComment,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/comment/:id',
    name: 'Comment',
    component: Comment,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/settings-comment',
    name: 'SettingReview',
    component: SettingReview,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/reviews',
    name: 'ProjectReviews',
    component: ProjectReviews,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/reviews/:id',
    name: 'ProjectReview',
    component: ProjectReview,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/settings-review',
    name: 'SettingsComment',
    component: SettingsComment,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/customers',
    name: 'CustomersPage',
    component: CustomersPage,
    meta: {
      layout: LayoutWithSidebar,
    },
  },

  {
    path: '/:projectId/orders',
    name: 'Orders',
    component: OrdersTable,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/order/:id',
    name: 'Order',
    component: OrderProductTable,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/order-edit/:id',
    name: 'OrderInfo',
    component: OrderInfo,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/notification',
    name: 'Notification',
    component: Notification,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/notification/edit-template/:type',
    name: 'EditEmailTemplate',
    component: NotificationEditEmailTemplate,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/support',
    name: 'Support',
    component: About,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/:projectId/seo',
    name: 'Seo',
    component: SeoSection,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/seo/robots',
    name: 'SeoRobots',
    component: SeoRobots,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/seo/site-map',
    name: 'SeoSiteMap',
    component: SeoSiteMap,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/seo/web-master',
    name: 'SeoWebMaster',
    component: SeoWebMaster,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/seo/metrics-analytics',
    name: 'SeoMetricsAndAnalytics',
    component: SeoMetricsAndAnalytics,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/third-party-code',
    name: 'ThirdPartyCode',
    component: ThirdPartyCode,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/roles-and-access',
    name: 'RolesAndAccess',
    component: RolesAndAccess,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/profile',
    name: 'UserProfile',
    component: UserProfile,
    meta: {
      layout: Default,
    },
  },
  {
    path: '/:projectId/products',
    name: 'Products',
    component: ProductsPage,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/product-create',
    name: 'ProductCreate',
    component: ProductCreate,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/product-dublicate/:id',
    name: 'ProductDublicate',
    component: ProductEditPage,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/product-edit/:id',
    name: 'ProductsEdit',
    component: ProductEditPage,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/product-show/:id',
    name: 'ProductsShow',
    component: ProductEditPage,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/category-edit/:id',
    name: 'ProductsCategoryEdit',
    component: ProductSettingsCategory,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/contacts',
    name: 'Contacts',
    component: Contacts,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/warehouses',
    name: 'Warehouses',
    component: WarehousesPage,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/warehouse-create',
    name: 'WarehouseCreate',
    component: WarehouseCreate,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/warehouse-edit/:id',
    name: 'WarehouseEdit',
    component: WarehouseEdit,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/delivery',
    name: 'Delivery',
    component: DeliveryPage,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/news',
    name: 'News',
    component: About,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/rubric',
    name: 'Rubric',
    component: About,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/media',
    name: 'Media',
    component: FilesAndMedia,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/integration',
    name: 'Integration',
    component: PaymentIntegration,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/integration/yoomoney',
    name: 'Yoomoney',
    component: YoomoneyIntegration,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/integration/bitrix',
    name: 'Bitrix',
    component: BitrixIntegration,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/news-and-blog',
    name: 'NewsAndBlog',
    component: NewsAndBlogPage,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/news-and-blog',
    name: 'NewsAndBlog',
    component: NewsAndBlogPage,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/news-and-blog/create',
    name: 'NewsAndBlogCreate',
    component: NewsAndBlogCreate,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/news-and-blog/edit/:id',
    name: 'NewsAndBlogEdit',
    component: NewsAndBlogEdit,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/news-and-blog/settings',
    name: 'NewsAndBlogSettings',
    component: NewsAndBlogSettings,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
  {
    path: '/:projectId/rubrics',
    name: 'Rubrics',
    component: RubricsPage,
    meta: {
      layout: LayoutWithSidebar,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.name !== 'Products') {
      return { top: 0 };
    }
    return {};
  },
});
router.beforeEach((to, from, next) => {
  const IS_LOGGED_IN = store.getters.isLoggedIn;
  if (to.name === 'SignUp') {
    next();
  }
  if (to.name === 'PasswordRecovery') {
    next();
  }
  if (to.name === 'ConfirmAccount') {
    next();
  }
  if (to.name === 'FastRegister') {
    next();
  }
  if (to.name === 'ChangePassword') {
    next();
  }
  if (to.name === 'PasswordCreate') {
    next();
  }
  if (to.name === 'RestorePassword') {
    next();
  }
  if (to.name !== 'SignIn' && !IS_LOGGED_IN) {
    next({ name: 'SignIn' });
  } else {
    next();
  }
  if (to.name === 'SignIn' && IS_LOGGED_IN) {
    next({ name: 'Home' });
  }
  next();
});

export default router;
