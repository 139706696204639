export enum RubricsMutationTypes {
  FETCH_RUBRICS = 'FETCH_RUBRICS',
  EDIT_RUBRIC = 'EDIT_RUBRIC',
  CREATE_RUBRIC = 'CREATE_RUBRIC',
  REMOVE_RUBRIC = 'REMOVE_RUBRIC',
  REMOVE_MANY_RUBRICS = 'REMOVE_MANY_RUBRICS',
  // Status
  RUBRICS_LOADING = 'RUBRICS_LOADING',
  RUBRICS_SUCCEEDED = 'RUBRICS_SUCCEEDED',
  RUBRICS_ERROR = 'RUBRICS_ERROR',
}
