import { StatisticsQuery } from '@/@typespaces/types/statisticsQuery';
import { Statistic } from '@/@typespaces/interfaces/statistics.interface';
import InstanceHttpClient from './instance';

export default class StatisticsApi extends InstanceHttpClient {
  constructor() {
    super('statistics');
  }

  async getVisitors(params: StatisticsQuery): Promise<Statistic[]> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: '/visitors',
        params,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async getCustomers(params: StatisticsQuery): Promise<Statistic[]> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: '/customers',
        params,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async getOrders(params: StatisticsQuery): Promise<Statistic[]> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: '/orders',
        params,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
