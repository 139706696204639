import { Profile } from '@/@typespaces/interfaces/profile.interface';
import { IRole } from '@/@typespaces/interfaces/rolesAndAccess.interface';
import InstanceHttpClient from './instance';

export default class ProfileClient extends InstanceHttpClient {
  constructor() {
    super('profile');
  }

  async getProfile(email: string): Promise<Profile> {
    try {
      return await this.apiCall({
        method: 'GET',
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async editProfile(body: Profile): Promise<{ success: true }> {
    try {
      return await this.apiCall({
        method: 'PATCH',
        data: body,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  // Тип IRole временно any пока не починят роли
  async getUserRoleForProject(projectId: string): Promise<IRole> {
    try {
      return await this.apiCall({
        method: 'POST',
        url: `get-role-for-project`,
        data: {
          project_id: projectId,
        },
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async getUserRoleForDashboard(): Promise<IRole> {
    try {
      return await this.apiCall({
        method: 'POST',
        url: `get-role-for-dashboard`,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
