import { User } from '@/@typespaces/interfaces/users.interface';
import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { State } from './state';

export type Getters = {
  getUsers(state: State): User[];
};

export const getters: GetterTree<State, RootState> & Getters = {
  getUsers: (state) => state.users,
};
