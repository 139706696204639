import BaseError from '@/components/BaseError.vue';
import createVNodeError from '@/helpers/createVNodeError';
import { message } from 'ant-design-vue';

export const copyToClipboardHandler = (value: string) => {
  try {
    const textarea = document.createElement('textarea');
    textarea.value = value;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    message.success('Текст скопирован в буфер обмена');
  } catch (error) {
    if (error instanceof Error) {
      const errorMessage = error.message;
      message.error(createVNodeError(BaseError, 'Не удалось скопировать текст', errorMessage));
    }
  }
};
