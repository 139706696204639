import Status from '@/@typespaces/enum/status.enum';
import { Statistic } from '@/@typespaces/interfaces/statistics.interface';

export interface State {
  visitors: Statistic[];
  customers: Statistic[];
  orders: Statistic[];
  status: Status;
}

export const state: State = {
  visitors: [],
  customers: [],
  orders: [],
  status: Status.IDLE,
};
