import Status from '@/@typespaces/enum/status.enum';
import { RootState } from '@/store';
import { GetterTree } from 'vuex';
import { Statistic } from '@/@typespaces/interfaces/statistics.interface';
import { State } from './state';

export type Getters = {
  getStatisticsVisitorsData(state: State): Statistic[];
  getStatisticsCustomersData(state: State): Statistic[];
  getStatisticsOrdersData(state: State): Statistic[];
  getStatisticsLoading(state: State): boolean;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getStatisticsVisitorsData: (state) => state.visitors,
  getStatisticsCustomersData: (state) => state.customers,
  getStatisticsOrdersData: (state) => state.orders,
  getStatisticsLoading: (state) => state.status === Status.LOADING,
};
