import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { IOrder } from '@/@typespaces/interfaces/orders.interface';
import Status from '@/@typespaces/enum/status.enum';
import { PaginationInfo } from '@/@typespaces/types/paginationInfo';
import { State } from './state';

export type Getters = {
  getOrders(state: State): IOrder[];
  getOneOrder(state: State): IOrder | null;
  getOrdersById: (state: State) => (id: string) => IOrder | undefined;
  getOrdersStatus(state: State): boolean;
  getTotalOrders(state: State): number;
  getOrdersPageSize(state: State): number;
  getOrdersCurrentPage(state: State): number;
  getOrdersPaginationInfo(state: State): PaginationInfo;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getOrders: (state) => state.data,
  getOneOrder: (state) => state.oneOrder,
  getOrdersById: (state) => (id: string) => state.data.find((order) => order.id === id),
  getOrdersStatus: (state) => {
    const loading = state.orderStatus;
    return loading === Status.LOADING;
  },
  getTotalOrders: (state) => state.total,
  getOrdersPageSize: (state) => state.pageSize,
  getOrdersCurrentPage: (state) => state.page,
  getOrdersPaginationInfo: (state) => ({
    page: state.page,
    pageSize: state.pageSize,
    total: state.total,
  }),
};
