export const queryParamsInterpreter = (value: string) => {
  if (value) {
    switch (value) {
      case 'status':
        return 'status';
      case 'blocked':
        return 'blocked';
      case 'published':
      case 'publish':
        return 'published';
      case 'activityAt':
      case 'updatedAt':
      case 'createdAt':
      case 'published_at':
      case 'publishedAt':
      case 'created_at':
      case 'eDate':
        return 'date';
      case 'payment_status':
        return 'payment';
      case 'current_status':
        return 'order_status';
      case 'processed':
        return 'processed';
      default:
        return 'default';
    }
  }
  return '';
};
