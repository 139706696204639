import { ConfirmAccount, RestorePassword } from '@/@typespaces/types/form/auth.types';
import { RootState, store } from '@/store';
import { ProfileActionTypes } from '@/store/modules/profile/action-types';
import { ActionContext, ActionTree } from 'vuex';
import AuthClient from '../../../api/auth.api';
import { AuthActionTypes } from './action-types';
import { AuthMutationTypes } from './mutation-types';
import { Mutations } from './mutations';
import { State } from './state';

const client = new AuthClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [AuthActionTypes.SIGN_IN]({ commit }: AugmentedActionContext, payload: { email: string; password: string }): void;

  [AuthActionTypes.SIGN_UP]({ commit }: AugmentedActionContext, payload: { email: string; password: string }): void;

  [AuthActionTypes.INVITATION](
    { commit }: AugmentedActionContext,
    data: { invitationId: string; password: string }
  ): void;

  [AuthActionTypes.REGISTRATION]({ commit }: AugmentedActionContext, payload: { email: string }): void;

  [AuthActionTypes.LOG_OUT]({ commit }: AugmentedActionContext): void;

  [AuthActionTypes.REFRESH_TOKEN]({ commit }: AugmentedActionContext, token: string): void;

  [AuthActionTypes.TAKE_TOKEN_FOR_DASHBOARD]({ commit }: AugmentedActionContext, token: string): void;

  [AuthActionTypes.TAKE_TOKEN_FOR_PROJECT]({ commit }: AugmentedActionContext, payload: string): void;

  [AuthActionTypes.RECOVERY_PASSWORD]({ commit }: AugmentedActionContext, payload: string): void;

  [AuthActionTypes.RESTORE_PASSWORD]({ commit }: AugmentedActionContext, payload: RestorePassword): void;

  [AuthActionTypes.RE_CONFIRMATION_REGISTER]({ commit }: AugmentedActionContext, payload: string): void;

  [AuthActionTypes.CONFIRM_ACCOUNT]({ commit }: AugmentedActionContext, payload: ConfirmAccount): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [AuthActionTypes.SIGN_IN]({ commit }, payload: { email: string; password: string }) {
    commit(AuthMutationTypes.AUTH_LOADING);
    try {
      const response = await client.signIn(payload);
      commit(AuthMutationTypes.SIGN_IN, { ...response, email: payload.email });
      commit(AuthMutationTypes.AUTH_SUCCEEDED);
    } catch (err) {
      commit(AuthMutationTypes.AUTH_ERROR);
      throw new Error(err as string);
    }
  },

  async [AuthActionTypes.SIGN_UP]({ commit }, payload: { email: string; password: string }) {
    commit(AuthMutationTypes.AUTH_LOADING);
    try {
      const response = await client.signUp(payload);
      commit(AuthMutationTypes.SIGN_UP, { ...response, email: payload.email });
      commit(AuthMutationTypes.AUTH_SUCCEEDED);
    } catch (err) {
      commit(AuthMutationTypes.AUTH_ERROR);
      throw new Error(err as string);
    }
  },
  async [AuthActionTypes.LOG_OUT]({ commit }) {
    await store.dispatch(ProfileActionTypes.CLEAR_PROFILE_DATA);
    commit(AuthMutationTypes.LOG_OUT);
  },

  async [AuthActionTypes.INVITATION]({ commit }, data) {
    commit(AuthMutationTypes.AUTH_LOADING);
    try {
      const token = await client.invitation(data.invitationId, data.password);
      commit(AuthMutationTypes.INVITATION, token);
      commit(AuthMutationTypes.AUTH_SUCCEEDED);
    } catch (err) {
      commit(AuthMutationTypes.AUTH_ERROR);
      throw new Error(err as string);
    }
  },

  async [AuthActionTypes.REGISTRATION]({ commit }, payload: { email: string }) {
    commit(AuthMutationTypes.AUTH_LOADING);
    try {
      const response = await client.register(payload);
      commit(AuthMutationTypes.REGISTRATION, { ...response, email: payload.email });
      commit(AuthMutationTypes.AUTH_SUCCEEDED);
    } catch (err) {
      commit(AuthMutationTypes.AUTH_ERROR);
      throw new Error(err as string);
    }
  },
  [AuthActionTypes.REFRESH_TOKEN]({ commit }, token) {
    commit(AuthMutationTypes.REFRESH_TOKEN, token);
  },

  async [AuthActionTypes.TAKE_TOKEN_FOR_DASHBOARD]({ commit }) {
    try {
      const data = await client.takeTokenForDashboard();
      commit(AuthMutationTypes.TAKE_TOKEN_FOR_DASHBOARD, data.access_token);
    } catch (error) {
      throw new Error(error as string);
    }
  },

  async [AuthActionTypes.TAKE_TOKEN_FOR_PROJECT]({ commit }, payload) {
    try {
      const data = await client.takeTokenForProject(payload);
      commit(AuthMutationTypes.TAKE_TOKEN_FOR_PROJECT, data.access_token);
    } catch (error) {
      throw new Error(error as string);
    }
  },

  async [AuthActionTypes.RECOVERY_PASSWORD]({ commit }, payload) {
    commit(AuthMutationTypes.AUTH_LOADING);
    try {
      await client.recoveryPassword(payload);
      commit(AuthMutationTypes.AUTH_SUCCEEDED);
    } catch (error) {
      commit(AuthMutationTypes.AUTH_ERROR);
      throw new Error(error as string);
    }
  },
  async [AuthActionTypes.RESTORE_PASSWORD]({ commit }, payload: RestorePassword) {
    commit(AuthMutationTypes.AUTH_LOADING);
    try {
      await client.restorePassword(payload);
      commit(AuthMutationTypes.AUTH_SUCCEEDED);
    } catch (error) {
      commit(AuthMutationTypes.AUTH_ERROR);
      throw new Error(error as string);
    }
  },

  async [AuthActionTypes.RE_CONFIRMATION_REGISTER]({ commit }, payload: string) {
    commit(AuthMutationTypes.AUTH_LOADING);
    try {
      await client.reconfirmation(payload);
      commit(AuthMutationTypes.AUTH_SUCCEEDED);
    } catch (error) {
      commit(AuthMutationTypes.AUTH_ERROR);
      throw new Error(error as string);
    }
  },

  async [AuthActionTypes.CONFIRM_ACCOUNT]({ commit }, payload: ConfirmAccount) {
    commit(AuthMutationTypes.AUTH_LOADING);
    try {
      await client.confirmAccount(payload);
      commit(AuthMutationTypes.AUTH_SUCCEEDED);
    } catch (error) {
      commit(AuthMutationTypes.AUTH_ERROR);
      throw new Error(error as string);
    }
  },
};
