import { UpdateVariantData } from '@/@typespaces/types/form/variants.types';
import FeaturesApi from '@/api/shop/features.api';
import VariantsApi from '@/api/shop/variants.api';
import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store';
import { CategoriesMutationTypes } from '@/store/modules/categories/mutation-types';
import { CategoriesActionTypes } from '@/store/modules/categories/action-types';
import CategoriesApi from '@/api/shop/categories.api';
import { CategoryFull, ICreateCategory, Variant } from '@/@typespaces/interfaces/category.interface';
import { Mutations } from './mutations';
import { State } from './state';

const api = new CategoriesApi();
const variantApi = new VariantsApi();
const featuresApi = new FeaturesApi();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [CategoriesActionTypes.GET_CATEGORIES]({ commit }: AugmentedActionContext): void;

  [CategoriesActionTypes.GET_CATEGORIES_BY_ALIAS]({ commit }: AugmentedActionContext, payload: string): void;

  [CategoriesActionTypes.GET_ONE_CATEGORY]({ commit }: AugmentedActionContext, payload: string): void;

  [CategoriesActionTypes.REMOVE_CATEGORY]({ commit }: AugmentedActionContext, payload: string): void;

  [CategoriesActionTypes.CREATE_CATEGORY]({ commit }: AugmentedActionContext, payload: ICreateCategory): void;

  [CategoriesActionTypes.EDIT_PRIORITY_CATEGORY]({ commit }: AugmentedActionContext, payload: ICreateCategory): void;

  [CategoriesActionTypes.ADD_VARIANT_IN_CATEGORY]({ commit }: AugmentedActionContext, payload: Variant): void;

  [CategoriesActionTypes.EDIT_VARIANT_IN_CATEGORY](
    { commit }: AugmentedActionContext,
    payload: { id: string; data: UpdateVariantData }
  ): void;

  [CategoriesActionTypes.REMOVE_VARIANT_IN_CATEGORY](
    { commit }: AugmentedActionContext,
    payload: { id: string; category_id: string }
  ): void;

  [CategoriesActionTypes.CREATE_FEATURE](
    { commit }: AugmentedActionContext,
    payload: { title: string; category_id: string }
  ): void;

  [CategoriesActionTypes.EDIT_FEATURE](
    { commit }: AugmentedActionContext,
    payload: { id: string; title: string }
  ): void;

  [CategoriesActionTypes.REMOVE_FEATURE]({ commit }: AugmentedActionContext, payload: string): void;

  [CategoriesActionTypes.EDIT_CATEGORY](
    { commit }: AugmentedActionContext,
    payload: { id: string; data: CategoryFull }
  ): void;

  [CategoriesActionTypes.GET_CATEGORY_PATH]({ commit }: AugmentedActionContext, payload: string): void;

  [CategoriesActionTypes.SET_CURRENT_CATEGORY]({ commit }: AugmentedActionContext, payload: string): void;

  [CategoriesActionTypes.CLEAR_CURRENT_CATEGORY]({ commit }: AugmentedActionContext): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [CategoriesActionTypes.GET_CATEGORIES]({ commit }) {
    commit(CategoriesMutationTypes.CATEGORY_LOADING);
    try {
      const data = await api.getCategories();
      commit(CategoriesMutationTypes.GET_CATEGORIES, data);
      commit(CategoriesMutationTypes.SET_SEARCH_CATEGORIES_FOR_EXPAND, null);
      commit(CategoriesMutationTypes.CATEGORY_SUCCEEDED);
    } catch (err) {
      commit(CategoriesMutationTypes.CATEGORY_ERROR);
      throw new Error(err as string);
    }
  },
  async [CategoriesActionTypes.GET_CATEGORIES_BY_ALIAS]({ commit }, payload: string) {
    commit(CategoriesMutationTypes.CATEGORY_LOADING);
    try {
      const data = await api.getCategoriesByAlias(payload);
      commit(CategoriesMutationTypes.GET_CATEGORIES, data.categories);
      commit(CategoriesMutationTypes.SET_SEARCH_CATEGORIES_FOR_EXPAND, data.expanded_keys);
      commit(CategoriesMutationTypes.CATEGORY_SUCCEEDED);
    } catch (err) {
      commit(CategoriesMutationTypes.CATEGORY_ERROR);
      throw new Error(err as string);
    }
  },

  async [CategoriesActionTypes.GET_ONE_CATEGORY]({ commit }, id: string) {
    commit(CategoriesMutationTypes.CATEGORY_LOADING);
    try {
      const data = await api.getOneCategory(id);
      commit(CategoriesMutationTypes.GET_ONE_CATEGORY, data);
      commit(CategoriesMutationTypes.CATEGORY_SUCCEEDED);
    } catch (err) {
      commit(CategoriesMutationTypes.CATEGORY_ERROR);
      throw new Error(err as string);
    }
  },

  async [CategoriesActionTypes.REMOVE_CATEGORY]({ commit }, id: string) {
    commit(CategoriesMutationTypes.CATEGORY_LOADING);
    try {
      await api.deleteCategory(id);
      commit(CategoriesMutationTypes.CATEGORY_SUCCEEDED);
    } catch (err) {
      commit(CategoriesMutationTypes.CATEGORY_ERROR);
      throw new Error(err as string);
    }
  },

  async [CategoriesActionTypes.CREATE_CATEGORY]({ commit }, data: ICreateCategory) {
    commit(CategoriesMutationTypes.CATEGORY_LOADING);
    try {
      const response = await api.createCategory(data);
      commit(CategoriesMutationTypes.CREATE_CATEGORY, response);
      commit(CategoriesMutationTypes.SET_CATEGORY_FOR_EXPAND, response.id);
      commit(CategoriesMutationTypes.CATEGORY_SUCCEEDED);
    } catch (err) {
      commit(CategoriesMutationTypes.CATEGORY_ERROR);
      throw new Error(err as string);
    }
  },

  async [CategoriesActionTypes.EDIT_PRIORITY_CATEGORY]({ commit }, data: ICreateCategory) {
    commit(CategoriesMutationTypes.CATEGORY_LOADING);
    try {
      await api.editPriorityCategory(data);
      commit(CategoriesMutationTypes.CATEGORY_SUCCEEDED);
      commit(CategoriesMutationTypes.CLEAR_CATEGORY_FOR_EXPAND);
    } catch (err) {
      commit(CategoriesMutationTypes.CATEGORY_ERROR);
      throw new Error(err as string);
    }
  },

  async [CategoriesActionTypes.EDIT_CATEGORY](
    { commit },
    payload: {
      id: string;
      data: CategoryFull;
    }
  ) {
    commit(CategoriesMutationTypes.CATEGORY_LOADING);
    try {
      const response = await api.editCategory(payload);
      commit(CategoriesMutationTypes.EDIT_CATEGORY, response);
      commit(CategoriesMutationTypes.CATEGORY_SUCCEEDED);
    } catch (err) {
      commit(CategoriesMutationTypes.CATEGORY_ERROR);
      throw new Error(err as string);
    }
  },

  async [CategoriesActionTypes.RENAME_CATEGORY]({ commit }, data: ICreateCategory) {
    commit(CategoriesMutationTypes.CATEGORY_LOADING);
    try {
      await api.renameCategory(data);
      commit(CategoriesMutationTypes.CATEGORY_SUCCEEDED);
    } catch (err) {
      commit(CategoriesMutationTypes.CATEGORY_ERROR);
      throw new Error(err as string);
    }
  },

  async [CategoriesActionTypes.ADD_VARIANT_IN_CATEGORY]({ commit }, payload: Variant) {
    try {
      const response = await variantApi.createVariant(payload);
      commit(CategoriesMutationTypes.ADD_VARIANT_IN_CATEGORY, response);
    } catch (err) {
      throw new Error(err as string);
    }
  },

  async [CategoriesActionTypes.EDIT_VARIANT_IN_CATEGORY](
    { commit },
    payload: {
      id: string;
      data: UpdateVariantData;
    }
  ) {
    try {
      const response = await variantApi.editVariant(payload);
      commit(CategoriesMutationTypes.EDIT_VARIANT_IN_CATEGORY, response);
    } catch (err) {
      throw new Error(err as string);
    }
  },

  async [CategoriesActionTypes.REMOVE_VARIANT_IN_CATEGORY](
    { commit },
    payload: {
      id: string;
      category_id: string;
    }
  ) {
    try {
      await variantApi.removeVariant(payload);
      commit(CategoriesMutationTypes.REMOVE_VARIANT_IN_CATEGORY, payload.id);
    } catch (err) {
      throw new Error(err as string);
    }
  },

  async [CategoriesActionTypes.CREATE_FEATURE](
    { commit },
    payload: {
      title: string;
      category_id: string;
    }
  ) {
    try {
      const response = await featuresApi.createFeature(payload);
      commit(CategoriesMutationTypes.CREATE_FEATURE, response);
      return response;
    } catch (err) {
      throw new Error(err as string);
    }
  },

  async [CategoriesActionTypes.EDIT_FEATURE]({ commit }, payload: { id: string; title: string }) {
    try {
      const response = await featuresApi.editFeature(payload);
      commit(CategoriesMutationTypes.EDIT_FEATURE, response);
    } catch (err) {
      throw new Error(err as string);
    }
  },

  async [CategoriesActionTypes.REMOVE_FEATURE]({ commit }, payload: string) {
    try {
      await featuresApi.deleteFeature(payload);
      commit(CategoriesMutationTypes.REMOVE_FEATURE, payload);
    } catch (err) {
      throw new Error(err as string);
    }
  },

  async [CategoriesActionTypes.GET_CATEGORY_PATH]({ commit }, payload: string) {
    try {
      return api.getCategoryPath(payload);
    } catch (err) {
      throw new Error(err as string);
    }
  },

  [CategoriesActionTypes.SET_CURRENT_CATEGORY]({ commit }, payload: string) {
    try {
      commit(CategoriesMutationTypes.SET_CURRENT_CATEGORY, payload);
      commit(CategoriesMutationTypes.SET_CATEGORY_FOR_EXPAND, payload);
    } catch (err) {
      throw new Error(err as string);
    }
  },

  [CategoriesActionTypes.CLEAR_CURRENT_CATEGORY]({ commit }) {
    try {
      commit(CategoriesMutationTypes.CLEAR_CURRENT_CATEGORY);
    } catch (err) {
      throw new Error(err as string);
    }
  },
};
