import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import Status from '@/@typespaces/enum/status.enum';
import { ILocation } from '@/@typespaces/interfaces/location.interface';
import { State } from './state';

export type Getters = {
  getLocations(state: State): ILocation[];
  getLocationStatus(state: State): Status;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getLocations: (state) => state.data,
  getLocationStatus: (state) => state.status,
  getLocationTree: (state) =>
    state.data
      .map((item: ILocation) => ({
        ...item,
        cities: item.cities.sort((a, b) => (a.title > b.title ? 1 : -1)),
      }))
      .sort((a, b) => (a.title > b.title ? 1 : -1)),
};
