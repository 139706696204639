export enum TemplateType {
  APPROVE_REGISTRATION = 'approve_registration',
  REGISTER_SUCCESS = 'register_success',
  CREATE_ORDER = 'create_order',
  ORDER_STATUS = 'order_status',
  COMPLETE_ORDER = 'complete_order',
  CHANGE_PASSWORD = 'change_password',
  SUBMITTING_FORMS = 'submitting_forms',
}

export enum RecipientTemplateType {
  REGISTER_SUCCESS = 'registerSuccess',
  CREATE_ORDER = 'createOrder',
  ORDER_STATUS = 'orderStatus',
  COMPLETE_ORDER = 'completeOrder',
  SUBMITTING_FORMS = 'submittingForms',
}

export enum NotificationTemplateType {
  SMS = 'sms',
  EMAIL = 'email',
}

export enum NotificationModal {
  EMAIL_SETTINGS = 'emailSettings',
  TEST_EMAIL = 'testEmail',
  TEST_SMS = 'testSms',
  SMS_SETTINGS = 'smsSettings',
}
