import Status from '@/@typespaces/enum/status.enum';
import { MainDomain } from '@/@typespaces/interfaces/domain.interface';
import { RootState } from '@/store';
import { GetterTree } from 'vuex';
import { State } from './state';

export type Getters = {
  getMainDomain(state: State): MainDomain | null;
  getConnectDomainStatus(state: State): boolean;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getMainDomain: (state) => state.domain,
  getConnectDomainStatus: (state) => state.status === Status.LOADING,
};
