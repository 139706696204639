import Status from '@/@typespaces/enum/status.enum';
import { Category, CategoryFull, Feature, Variant } from '@/@typespaces/interfaces/category.interface';
import { MutationTree } from 'vuex';
import { CategoriesMutationTypes } from './mutation-types';
import { State } from './state';

export type Mutations<S = State> = {
  [CategoriesMutationTypes.GET_CATEGORIES](state: S, payload: Category[]): void;
  [CategoriesMutationTypes.GET_ONE_CATEGORY](state: S, payload: CategoryFull): void;
  [CategoriesMutationTypes.CREATE_CATEGORY](state: S, payload: Category): void;
  [CategoriesMutationTypes.EDIT_CATEGORY](state: S, payload: CategoryFull): void;
  [CategoriesMutationTypes.ADD_VARIANT_IN_CATEGORY](state: S, payload: Variant): void;
  [CategoriesMutationTypes.EDIT_VARIANT_IN_CATEGORY](state: S, payload: Variant): void;
  [CategoriesMutationTypes.REMOVE_VARIANT_IN_CATEGORY](state: S, payload: string): void;
  [CategoriesMutationTypes.SET_CURRENT_CATEGORY](state: S, payload: string): void;
  [CategoriesMutationTypes.CLEAR_CURRENT_CATEGORY](state: S): void;
  [CategoriesMutationTypes.SET_CATEGORY_FOR_EXPAND](state: S, payload: string): void;
  [CategoriesMutationTypes.SET_SEARCH_CATEGORIES_FOR_EXPAND](state: S, payload: string[] | null): void;
  [CategoriesMutationTypes.CLEAR_CATEGORY_FOR_EXPAND](state: S): void;

  [CategoriesMutationTypes.CREATE_FEATURE](state: S, payload: Feature): void;
  [CategoriesMutationTypes.EDIT_FEATURE](state: S, payload: Feature): void;
  [CategoriesMutationTypes.REMOVE_FEATURE](state: S, payload: string): void;

  // Status
  [CategoriesMutationTypes.CATEGORY_LOADING](state: S): void;
  [CategoriesMutationTypes.CATEGORY_SUCCEEDED](state: S): void;
  [CategoriesMutationTypes.CATEGORY_ERROR](state: S): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [CategoriesMutationTypes.GET_CATEGORIES](state: State, data: Category[]) {
    state.data = data;
  },

  [CategoriesMutationTypes.GET_ONE_CATEGORY](state: State, data: CategoryFull) {
    state.category = data;
  },

  [CategoriesMutationTypes.CREATE_CATEGORY](state: State, data: Category) {
    state.data.push(data);
  },
  [CategoriesMutationTypes.EDIT_CATEGORY](state: State, data: CategoryFull) {
    state.category = data;
  },
  [CategoriesMutationTypes.SET_CURRENT_CATEGORY](state: State, payload: string) {
    state.selectedCategory = payload;
  },
  [CategoriesMutationTypes.CLEAR_CURRENT_CATEGORY](state: State) {
    state.selectedCategory = null;
  },
  [CategoriesMutationTypes.SET_SEARCH_CATEGORIES_FOR_EXPAND](state: State, payload: string[] | null) {
    state.searchCategoriesExpand = payload;
  },
  [CategoriesMutationTypes.SET_CATEGORY_FOR_EXPAND](state: State, payload: string) {
    state.categoryForExpand = payload;
  },
  [CategoriesMutationTypes.CLEAR_CATEGORY_FOR_EXPAND](state: State) {
    state.categoryForExpand = null;
  },

  [CategoriesMutationTypes.ADD_VARIANT_IN_CATEGORY](state: State, payload: Variant) {
    state.category?.variants.push(payload);
  },
  [CategoriesMutationTypes.REMOVE_VARIANT_IN_CATEGORY](state: State, payload: string) {
    if (state.category) {
      state.category = {
        ...state.category,
        variants: state.category?.variants.filter((item) => item.id !== payload),
      };
    }
  },
  [CategoriesMutationTypes.EDIT_VARIANT_IN_CATEGORY](state: State, payload: Variant) {
    if (state.category) {
      state.category = {
        ...state.category,
        variants: state.category.variants.map((item) => {
          if (item.id === payload.id) {
            return payload;
          }
          return item;
        }),
      };
    }
  },

  [CategoriesMutationTypes.CREATE_FEATURE](state: State, payload: Feature) {
    state.category?.features.push(payload);
  },
  [CategoriesMutationTypes.EDIT_FEATURE](state: State, payload: Feature) {
    if (state.category) {
      state.category = {
        ...state.category,
        features: state.category.features.map((item) => {
          if (item.id === payload.id) {
            return payload;
          }
          return item;
        }),
      };
    }
  },
  [CategoriesMutationTypes.REMOVE_FEATURE](state: State, payload: string) {
    console.log(payload);
    if (state.category) {
      state.category = {
        ...state.category,
        features: state.category.features.filter((item) => item.id !== payload),
      };
    }
  },

  // Status
  [CategoriesMutationTypes.CATEGORY_LOADING](state: State) {
    state.status = Status.LOADING;
  },

  [CategoriesMutationTypes.CATEGORY_SUCCEEDED](state: State) {
    state.status = Status.SUCCEEDED;
  },

  [CategoriesMutationTypes.CATEGORY_ERROR](state: State) {
    state.status = Status.FAILED;
  },
};
