import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { Customer } from '@/@typespaces/interfaces/customer.interface';
import Status from '@/@typespaces/enum/status.enum';
import { PaginationInfo } from '@/@typespaces/types/paginationInfo';
import { State } from './state';

export type Getters = {
  getCustomers(state: State): Customer[];
  getCustomersTotal(state: State): number;
  getCustomersPage(state: State): number;
  getCustomersPageSize(state: State): number;
  getCustomersPaginationInfo(state: State): PaginationInfo;
  getCustomersStatus(state: State): Status;
  getCustomerById: (state: State) => (id: string) => Customer | undefined;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getCustomers: (state) => state.data,
  getCustomersTotal: (state) => state.total,
  getCustomersPage: (state) => state.page,
  getCustomersPageSize: (state) => state.pageSize,
  getCustomersPaginationInfo: (state) => ({
    total: state.total,
    page: state.page,
    pageSize: state.pageSize,
  }),
  getCustomersStatus: (state) => state.status,
  getCustomerById: (state) => (id: string) => state.data.find((user) => user.id === id),
};
