export const queryValueInterpreter = (query: string | Date, params: string) => {
  const checkedParams = () => {
    if (params === 'processed' && query === 'true') {
      return 'processed';
    }
    if (params === 'processed' && query === 'false') {
      return 'unprocessed';
    }
    return query;
  };
  const isString = typeof query === 'string';
  if (isString) {
    switch (checkedParams()) {
      case 'active':
      case 'false':
        return 'Активен';
      case 'blocked':
      case 'true':
        return 'Заблокирован';
      case 'block-in-project':
        return 'Блок в проекте';
      case 'expecting_payment':
        return 'Ожидает оплаты';
      case 'paid':
        return 'Оплачен';
      case 'when_receiving':
        return 'При получении';
      case 'new':
        return 'Новый';
      case 'shipped':
        return 'Отправлен';
      case 'completed':
        return 'Завершен';
      case 'assembled':
        return 'Собран';
      case 'processed':
        return 'Обработан';
      case 'unprocessed':
        return 'На модерации';
      default:
        return query;
    }
  } else {
    return new Date(query).toLocaleDateString();
  }
};
