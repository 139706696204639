export enum CommentMutationTypes {
  CREATE_COMMENT = 'CREATE_COMMENT',
  GET_COMMENTS = 'GET_COMMENTS',
  GET_ONE_COMMENT = 'GET_ONE_COMMENT',
  REMOVE_COMMENT = 'REMOVE_COMMENT',
  SET_COMMENT_STATUS = 'SET_COMMENT_STATUS',
  CREATE_ANSWER = 'CREATE_ANSWER',
  // Status
  COMMENTS_LOADING = 'COMMENTS_LOADING',
  COMMENTS_SUCCEEDED = 'COMMENTS_SUCCEEDED',
  COMMENTS_ERROR = 'COMMENTS_ERROR',
}
