import Status from '@/@typespaces/enum/status.enum';
import { Product } from '@/@typespaces/interfaces/products.interface';

export interface State {
  count: number;
  limit: number;
  page: number;
  products: Product[];
  allProducts: Product[];
  oneProduct: Product | null;
  status: Status;
  error: string | null;
}

export const state: State = {
  count: 0,
  limit: 0,
  page: 0,
  products: [],
  allProducts: [],
  status: Status.IDLE,
  error: null,
  oneProduct: null,
};
