export enum TemplatesMutationTypes {
  FETCH_TEMPLATES = 'FETCH_TEMPLATES',
  FETCH_TEMPLATE_CATEGORIES = 'FETCH_TEMPLATE_CATEGORIES',
  SAVE_TEMPLATE_STEP = 'SAVE_TEMPLATE_STEP',
  CLEAR_TEMPLATE_STEP = 'CLEAR_TEMPLATE_STEP',
  // Status
  TEMPLATES_LOADING = 'TEMPLATES_LOADING',
  TEMPLATES_SUCCEEDED = 'TEMPLATES_SUCCEEDED',
  TEMPLATES_ERROR = 'TEMPLATES_ERROR',
}
