import { Ref } from 'vue';
import { UploadProps } from 'ant-design-vue';

// eslint-disable-next-line import/prefer-default-export
export const getBlobHelper = (
  domainInput: Ref<string>,
  fileListCert: Ref<UploadProps['fileList']>,
  fileListKey: Ref<UploadProps['fileList']>,
  certRadioBtnValue: Ref<'file' | 'text'>,
  privateKeyRadioBtnValue: Ref<'file' | 'text'>,
  certText: Ref<string | null>,
  keyText: Ref<string | null>
): [Blob, Blob] => {
  let certBlob: Blob = new Blob();
  let keyBlob: Blob = new Blob();
  if (
    domainInput.value &&
    fileListCert.value &&
    fileListCert.value.length &&
    fileListKey.value &&
    fileListKey.value.length &&
    certRadioBtnValue.value === 'file' &&
    privateKeyRadioBtnValue.value === 'file'
  ) {
    certBlob = fileListCert.value[0] as unknown as Blob;
    keyBlob = fileListKey.value[0] as unknown as Blob;
  }
  if (
    domainInput.value &&
    certText.value &&
    certText.value.length &&
    keyText.value &&
    keyText.value.length &&
    certRadioBtnValue.value === 'text' &&
    privateKeyRadioBtnValue.value === 'text'
  ) {
    certBlob = new Blob([certText.value], { type: 'application/x-x509-ca-cert' });
    keyBlob = new Blob([keyText.value], { type: 'application/x-x509-ca-cert' });
  }
  return [certBlob, keyBlob];
};
