export enum AuthMutationTypes {
  SIGN_IN = 'SIGN_IN',
  SIGN_UP = 'SIGN_UP',
  LOG_OUT = 'LOG_OUT',
  REGISTRATION = 'REGISTRATION',
  REFRESH_TOKEN = 'REFRESH_TOKEN',
  TAKE_TOKEN_FOR_DASHBOARD = 'TAKE_TOKEN_FOR_DASHBOARD',
  TAKE_TOKEN_FOR_PROJECT = 'TAKE_TOKEN_FOR_PROJECT',
  INVITATION = 'INVITATION',

  // Status
  AUTH_LOADING = 'AUTH_LOADING',
  AUTH_SUCCEEDED = 'AUTH_SUCCEEDED',
  AUTH_ERROR = 'AUTH_ERROR',
}
