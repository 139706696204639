import Status from '@/@typespaces/enum/status.enum';
import { IFile } from '@/@typespaces/interfaces/file.interface';

export interface State {
  error: string | null;
  status: Status;
  data: IFile[];

  currentFile: IFile | null;
  page: number;
  total: number;
  pageSize: number;
}

export const state: State = {
  data: [],
  currentFile: null,
  error: null,
  status: Status.IDLE,
  page: 0,
  total: 0,
  pageSize: 0,
};
