import { Query } from '@/@typespaces/types/query.types';
import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store';
import WarehousesClient from '@/api/shop/warehouses.api';
import { WarehousesActionTypes } from '@/store/modules/warehouses/action-types';
import { WarehousesMutationTypes } from '@/store/modules/warehouses/mutation-types';
import { IWarehouse } from '@/@typespaces/interfaces/warehouses.interface';
import { State } from './state';
import { Mutations } from './mutations';

const api = new WarehousesClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [WarehousesActionTypes.FETCH_WAREHOUSES](
    { commit }: AugmentedActionContext,
    payload?: { projectId: string; query: Query }
  ): void;

  [WarehousesActionTypes.EDIT_WAREHOUSE]({ commit }: AugmentedActionContext, payload: IWarehouse): void;

  [WarehousesActionTypes.CREATE_WAREHOUSE]({ commit }: AugmentedActionContext, payload: IWarehouse): void;

  [WarehousesActionTypes.DELETE_WAREHOUSE]({ commit }: AugmentedActionContext, id: string): void;

  [WarehousesActionTypes.FETCH_ONE_WAREHOUSE]({ commit }: AugmentedActionContext, id: string): void;

  [WarehousesActionTypes.BLOCK_WAREHOUSE]({ commit }: AugmentedActionContext, payload: IWarehouse): void;

  [WarehousesActionTypes.UN_BLOCK_WAREHOUSE]({ commit }: AugmentedActionContext, payload: IWarehouse): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [WarehousesActionTypes.FETCH_WAREHOUSES]({ commit }, payload?: { projectId: string; query: Query }) {
    commit(WarehousesMutationTypes.WAREHOUSES_LOADING);
    try {
      const data = await api.getWarehouses(payload);
      commit(WarehousesMutationTypes.FETCH_WAREHOUSES, data);
      commit(WarehousesMutationTypes.WAREHOUSES_SUCCEEDED);
    } catch (e) {
      commit(WarehousesMutationTypes.WAREHOUSES_ERROR, e as string);
      throw new Error(e as string);
    }
  },

  async [WarehousesActionTypes.FETCH_ONE_WAREHOUSE]({ commit }, id: string) {
    commit(WarehousesMutationTypes.WAREHOUSES_LOADING);
    try {
      const data = await api.getOneWarehouse(id);
      commit(WarehousesMutationTypes.FETCH_ONE_WAREHOUSE, data);
      commit(WarehousesMutationTypes.WAREHOUSES_SUCCEEDED);
    } catch (e) {
      commit(WarehousesMutationTypes.WAREHOUSES_ERROR, e as string);
      throw new Error(e as string);
    }
  },
  async [WarehousesActionTypes.EDIT_WAREHOUSE]({ commit }, data: IWarehouse) {
    try {
      const response = await api.editWarehouse(data);
      if (response.success) {
        commit(WarehousesMutationTypes.EDIT_WAREHOUSE, data);
      }
    } catch (e) {
      commit(WarehousesMutationTypes.WAREHOUSES_ERROR, e as string);
      throw new Error(e as string);
    }
  },

  async [WarehousesActionTypes.CREATE_WAREHOUSE]({ commit }, data: IWarehouse) {
    try {
      const response = await api.createWarehouse(data);
      commit(WarehousesMutationTypes.CREATE_WAREHOUSE, response);
    } catch (e) {
      commit(WarehousesMutationTypes.WAREHOUSES_ERROR, e as string);
      throw new Error(e as string);
    }
  },
  async [WarehousesActionTypes.DELETE_WAREHOUSE]({ commit }, id: string) {
    commit(WarehousesMutationTypes.WAREHOUSES_LOADING);
    try {
      await api.removeWarehouse(id);
      commit(WarehousesMutationTypes.DELETE_WAREHOUSE, id);
      commit(WarehousesMutationTypes.WAREHOUSES_SUCCEEDED);
    } catch (e) {
      commit(WarehousesMutationTypes.WAREHOUSES_ERROR, e as string);
      throw new Error(e as string);
    }
  },

  async [WarehousesActionTypes.BLOCK_WAREHOUSE]({ commit }, payload: IWarehouse) {
    commit(WarehousesMutationTypes.WAREHOUSES_LOADING);
    try {
      await api.editWarehouse(payload);
      commit(WarehousesMutationTypes.BLOCK_WAREHOUSE, payload.id);
      commit(WarehousesMutationTypes.WAREHOUSES_SUCCEEDED);
    } catch (e) {
      commit(WarehousesMutationTypes.WAREHOUSES_ERROR, e as string);
      throw new Error(e as string);
    }
  },

  async [WarehousesActionTypes.UN_BLOCK_WAREHOUSE]({ commit }, payload: IWarehouse) {
    commit(WarehousesMutationTypes.WAREHOUSES_LOADING);
    try {
      await api.editWarehouse(payload);
      commit(WarehousesMutationTypes.UN_BLOCK_WAREHOUSE, payload.id);
      commit(WarehousesMutationTypes.WAREHOUSES_SUCCEEDED);
    } catch (e) {
      commit(WarehousesMutationTypes.WAREHOUSES_ERROR, e as string);
      throw new Error(e as string);
    }
  },
};
