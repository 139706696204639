import { MutationTree } from 'vuex';
import { NewsAndBlogMutationTypes } from '@/store/modules/newsAndBlog/mutation-types';

import Status from '@/@typespaces/enum/status.enum';
import {
  IAuthor,
  INewsAndBlog,
  INewsAndBlogData,
  INewsAndBlogSettings,
} from '@/@typespaces/interfaces/newsAndBlog.interfaces';
import { State } from './state';

export type Mutations<S = State> = {
  // Settings
  [NewsAndBlogMutationTypes.FETCH_NEWS_AND_BLOGS_SETTINGS](state: S, payload: INewsAndBlogSettings): void;
  [NewsAndBlogMutationTypes.EDIT_AND_BLOGS_SETTINGS](state: S, payload: INewsAndBlogSettings): void;
  // Authors
  [NewsAndBlogMutationTypes.FETCH_NEWS_AND_BLOGS_AUTHORS](state: S, payload: IAuthor[]): void;
  [NewsAndBlogMutationTypes.REMOVE_NEWS_AND_BLOGS_AUTHOR](state: S, payload: string): void;
  [NewsAndBlogMutationTypes.NEWS_AND_BLOGS_EDIT_AUTHOR](state: S, payload: Partial<IAuthor>): void;
  // Blog
  [NewsAndBlogMutationTypes.FETCH_NEWS_AND_BLOGS_DATA](state: S, payload: INewsAndBlogData): void;
  [NewsAndBlogMutationTypes.FETCH_ONE_BLOG](state: S, payload: INewsAndBlog): void;
  [NewsAndBlogMutationTypes.CREATE_BLOG](state: S, payload: INewsAndBlog): void;
  [NewsAndBlogMutationTypes.EDIT_BLOG](state: S, payload: INewsAndBlog): void;
  [NewsAndBlogMutationTypes.REMOVE_ONE_BLOG](state: S, payload: string): void;
  [NewsAndBlogMutationTypes.COPY_POST](state: S, payload: INewsAndBlog): void;
  [NewsAndBlogMutationTypes.SET_STATUS_BLOG](state: S, payload: { blogId: string; status: string }): void;
  [NewsAndBlogMutationTypes.CLEAR_ONE_POST](state: S): void;
  // Status
  [NewsAndBlogMutationTypes.NEWS_AND_BLOGS_LOADING](state: S): void;
  [NewsAndBlogMutationTypes.NEWS_AND_BLOGS_SUCCEEDED](state: S): void;
  [NewsAndBlogMutationTypes.NEWS_AND_BLOGS_ERROR](state: S, payload: string): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [NewsAndBlogMutationTypes.FETCH_NEWS_AND_BLOGS_DATA](state: State, payload: INewsAndBlogData) {
    state.data = payload.data.posts;
    state.dataIds = payload.data.postsIds;
    state.page = payload.page;
    state.pageSize = payload.pageSize;
    state.total = payload.total;
  },
  [NewsAndBlogMutationTypes.FETCH_ONE_BLOG](state: State, payload: INewsAndBlog) {
    state.currentBlog = payload;
  },

  [NewsAndBlogMutationTypes.CREATE_BLOG](state: State, payload: INewsAndBlog) {
    state.data.push(payload);
    state.total += 1;
  },
  [NewsAndBlogMutationTypes.EDIT_BLOG](state: State, payload: INewsAndBlog) {
    state.data = state.data.map((item) => {
      if (item._id === payload._id) {
        return payload;
      }
      return item;
    });
  },

  [NewsAndBlogMutationTypes.REMOVE_ONE_BLOG](state: State, payload: string) {
    const removeIndex = state.data.findIndex((item) => item._id === payload);
    if (removeIndex !== -1) {
      state.data.splice(removeIndex, 1);
      state.total -= 1;
    }
  },

  [NewsAndBlogMutationTypes.COPY_POST](state: State, payload: INewsAndBlog) {
    state.data.push(payload);
    state.total += 1;
  },

  [NewsAndBlogMutationTypes.CLEAR_ONE_POST](state: State) {
    state.currentBlog = null;
  },

  [NewsAndBlogMutationTypes.SET_STATUS_BLOG](state: State, payload: { blogId: string; status: string }) {
    state.data = state.data.map((item) => {
      if (item._id === payload.blogId) {
        return {
          ...item,
          status: payload.status,
        };
      }
      return item;
    });
  },
  // Authors
  [NewsAndBlogMutationTypes.FETCH_NEWS_AND_BLOGS_AUTHORS](state: State, payload: IAuthor[]) {
    state.authors = payload;
  },
  [NewsAndBlogMutationTypes.NEWS_AND_BLOGS_EDIT_AUTHOR](state: State, payload: IAuthor) {
    state.authors = state.authors.map((item) => {
      if (item._id === payload._id) {
        return { ...item, ...payload };
      }
      return item;
    });
  },
  [NewsAndBlogMutationTypes.REMOVE_NEWS_AND_BLOGS_AUTHOR](state: State, payload: string) {
    const removeIndex = state.authors.findIndex((item) => item._id === payload);
    if (removeIndex !== -1) {
      state.authors.splice(removeIndex, 1);
    }
  },
  // Settings
  [NewsAndBlogMutationTypes.FETCH_NEWS_AND_BLOGS_SETTINGS](state: State, payload: INewsAndBlogSettings) {
    state.settings = payload;
  },
  [NewsAndBlogMutationTypes.EDIT_AND_BLOGS_SETTINGS](state: State, payload: INewsAndBlogSettings) {
    state.settings = payload;
  },

  // Status
  [NewsAndBlogMutationTypes.NEWS_AND_BLOGS_LOADING](state: State) {
    state.status = Status.LOADING;
  },
  [NewsAndBlogMutationTypes.NEWS_AND_BLOGS_SUCCEEDED](state: State) {
    state.status = Status.SUCCEEDED;
  },
  [NewsAndBlogMutationTypes.NEWS_AND_BLOGS_ERROR](state: State, data: string) {
    state.error = data;
    state.status = Status.IDLE;
  },
};
