import { MutationTree } from 'vuex';
import { ProjectSettingsMutationTypes } from '@/store/modules/projectSettings/mutation-types';
import { IProjectSettings } from '@/@typespaces/interfaces/projectSettings.interface';
import { State } from './state';

export type Mutations<S = State> = {
  [ProjectSettingsMutationTypes.FETCH_PROJECT_SETTINGS](state: S, payload: IProjectSettings): void;
  [ProjectSettingsMutationTypes.EDIT_PROJECT_SETTINGS](state: S, payload: IProjectSettings): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [ProjectSettingsMutationTypes.FETCH_PROJECT_SETTINGS](state: State, data: IProjectSettings) {
    state.data = data;
  },
  [ProjectSettingsMutationTypes.EDIT_PROJECT_SETTINGS](state: State, data: IProjectSettings) {
    state.data = data;
  },
};
