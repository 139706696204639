import Status from '@/@typespaces/enum/status.enum';
import { IFile } from '@/@typespaces/interfaces/file.interface';
import { MediaData } from '@/@typespaces/interfaces/media.interface';
import { MutationTree } from 'vuex';
import { MediaMutationTypes } from './mutation-types';
import { State } from './state';

export type Mutations<S = State> = {
  [MediaMutationTypes.FETCH_FILES](state: S, payload: MediaData): void;
  [MediaMutationTypes.FETCH_FILE](state: S, payload: IFile): void;
  [MediaMutationTypes.EDIT_FILE](state: S, payload: IFile): void;
  [MediaMutationTypes.ADD_FILE](state: S, payload: IFile): void;
  [MediaMutationTypes.REMOVE_FILE](state: S, payload: string): void;
  [MediaMutationTypes.ADD_MANY_FILES](state: S, payload: IFile[]): void;

  // Status
  [MediaMutationTypes.MEDIA_ERROR](state: S, payload: string): void;
  [MediaMutationTypes.MEDIA_LOADING](state: S): void;
  [MediaMutationTypes.MEDIA_SUCCEEDED](state: S): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MediaMutationTypes.FETCH_FILES](state: State, payload: MediaData) {
    state.data = payload.data.files;
    state.page = payload.page;
    state.pageSize = payload.pageSize;
    state.total = payload.total;
  },

  [MediaMutationTypes.FETCH_FILE](state: State, payload: IFile) {
    state.currentFile = payload;
  },

  [MediaMutationTypes.EDIT_FILE](state: State, payload: IFile) {
    state.currentFile = payload;
  },

  [MediaMutationTypes.ADD_FILE](state: State, payload: IFile) {
    state.total += 1;
  },

  [MediaMutationTypes.ADD_MANY_FILES](state: State, payload: IFile[]) {
    state.data.push(...payload);
  },

  [MediaMutationTypes.REMOVE_FILE](state: State, payload: string) {
    state.data = state.data.filter((item) => item._id !== payload);
    state.total -= 1;
  },

  // Status
  [MediaMutationTypes.MEDIA_LOADING](state) {
    state.status = Status.LOADING;
  },
  [MediaMutationTypes.MEDIA_SUCCEEDED](state) {
    state.status = Status.SUCCEEDED;
  },
  [MediaMutationTypes.MEDIA_ERROR](state: State, error: string) {
    state.error = error;
    state.status = Status.FAILED;
  },
};
