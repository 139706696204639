import { PaginationInfo } from '@/@typespaces/types/paginationInfo';
import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import StatusEnum from '@/@typespaces/enum/status.enum';
import { IWarehouse } from '@/@typespaces/interfaces/warehouses.interface';
import { State } from './state';

export type Getters = {
  getWarehouses(state: State): IWarehouse[];
  getOneWarehouse(state: State): IWarehouse | null;
  getWarehouseById: (state: State) => (id: string) => IWarehouse | undefined;
  getWarehousesStatus(state: State): StatusEnum;
  getWarehousesPagination(state: State): PaginationInfo;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getWarehouses: (state) => state.data,
  getWarehouseById: (state) => (id: string) => state.data.find((order) => order.id === id),
  getWarehousesStatus: (state) => state.status,
  getOneWarehouse: (state) => state.oneWarehouse,
  getWarehousesPagination: (state) => ({
    page: state.page,
    pageSize: state.pageSize,
    total: state.total,
  }),
};
