import { MutationTree } from 'vuex';
import Status from '@/@typespaces/enum/status.enum';
import { IRole, IRolesAndAccessData } from '@/@typespaces/interfaces/rolesAndAccess.interface';
import { State } from './state';
import { RolesAndAccessMutationTypes } from './mutation-types';

export type Mutations<S = State> = {
  [RolesAndAccessMutationTypes.FETCH_USERS](state: S, payload: IRolesAndAccessData): void;
  [RolesAndAccessMutationTypes.INVITE_USER](state: S): void;
  [RolesAndAccessMutationTypes.DELETE_ONE_USER](state: S, payload: string): void;
  [RolesAndAccessMutationTypes.BLOCK_USER](state: S, payload: string): void;
  [RolesAndAccessMutationTypes.UNBLOCK_USER](state: S, payload: string): void;
  [RolesAndAccessMutationTypes.EDIT_USER_ROLE](state: S, payload: { userId: string; roleId: string }): void;
  [RolesAndAccessMutationTypes.CLEAR_USER_ROLE](state: S): void;
  [RolesAndAccessMutationTypes.GET_ROLES_PROJECT_GROUP](state: S, payload: IRole[]): void;

  // Status
  [RolesAndAccessMutationTypes.USERS_ERROR](state: S, payload: string): void;
  [RolesAndAccessMutationTypes.USERS_SUCCEEDED](state: S): void;
  [RolesAndAccessMutationTypes.USERS_LOADING](state: S): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [RolesAndAccessMutationTypes.FETCH_USERS](state: State, data: IRolesAndAccessData) {
    state.users = data.data.users;
    state.usersIds = data.data.usersIds;
    state.total = data.total;
    state.page = data.page;
    state.pageSize = data.pageSize;
  },
  [RolesAndAccessMutationTypes.CLEAR_USER_ROLE](state: State) {
    state.role = null;
  },

  [RolesAndAccessMutationTypes.GET_ROLES_PROJECT_GROUP](state: State, payload: IRole[]) {
    state.roles = payload;
  },
  [RolesAndAccessMutationTypes.INVITE_USER]() {
    return true;
  },
  [RolesAndAccessMutationTypes.DELETE_ONE_USER](state: State, id: string) {
    const removeIndex = state.users.findIndex((item) => item._id === id);
    state.users.splice(removeIndex, 1);
    state.total -= 1;
  },

  [RolesAndAccessMutationTypes.BLOCK_USER](state: State, id: string) {
    state.users = state.users.map((item) => {
      if (item._id === id) {
        return {
          ...item,
          blocked: true,
        };
      }
      return item;
    });
  },

  [RolesAndAccessMutationTypes.UNBLOCK_USER](state: State, id: string) {
    state.users = state.users.map((item) => {
      if (item._id === id) {
        return {
          ...item,
          blocked: false,
        };
      }
      return item;
    });
  },

  [RolesAndAccessMutationTypes.EDIT_USER_ROLE](state: State, data: { userId: string; roleId: string }) {
    const { userId, roleId } = data;
    const findRole = state.roles.find((role) => role._id === roleId) as IRole;
    state.users = state.users.map((user) => {
      if (user._id === userId) {
        return {
          ...user,
          role: findRole.visibleName,
        };
      }
      return user;
    });
  },

  // Status
  [RolesAndAccessMutationTypes.USERS_LOADING](state) {
    state.status = Status.LOADING;
  },
  [RolesAndAccessMutationTypes.USERS_SUCCEEDED](state) {
    state.status = Status.SUCCEEDED;
  },
  [RolesAndAccessMutationTypes.USERS_ERROR](state: State, error: string) {
    state.error = error;
    state.status = Status.IDLE;
  },
};
