import { MutationTree } from 'vuex';
import { FiltersQuery } from '@/@typespaces/types/query.types';
import { State } from './state';
import { ServiceMutationTypes } from './mutation-types';

export type Mutations<S = State> = {
  [ServiceMutationTypes.SET_SEARCH_STRING](state: S, payload: string): void;
  [ServiceMutationTypes.CLEAR_SEARCH_STRING](state: S): void;
  [ServiceMutationTypes.SET_FILTER_VALUE](state: S, payload: FiltersQuery): void;
  [ServiceMutationTypes.REMOVE_FILTER_VALUE](state: S, payload: string): void;
  [ServiceMutationTypes.CLEAR_FILTER_VALUE](state: S): void;
  [ServiceMutationTypes.COLLAPSE_MENU](state: S, payload: boolean): void;
  [ServiceMutationTypes.SHOW_CONFIRM_MODAL](state: S, payload: boolean): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [ServiceMutationTypes.SET_SEARCH_STRING](state: State, payload: string) {
    state.searchString = payload;
  },
  [ServiceMutationTypes.CLEAR_SEARCH_STRING](state: State) {
    state.searchString = '';
  },
  [ServiceMutationTypes.SET_FILTER_VALUE](state: State, payload: FiltersQuery) {
    state.filter.push(payload);
  },
  [ServiceMutationTypes.REMOVE_FILTER_VALUE](state: State, payload: string) {
    const removeIndex = state.filter.map((item) => item.id).indexOf(payload);
    state.filter.splice(removeIndex, 1);
  },
  [ServiceMutationTypes.CLEAR_FILTER_VALUE](state: State) {
    state.filter = [];
  },
  [ServiceMutationTypes.COLLAPSE_MENU](state: State, payload: boolean) {
    state.collapsed = payload;
  },
  [ServiceMutationTypes.SHOW_CONFIRM_MODAL](state: State, payload: boolean) {
    state.confirmModal = payload;
  },
};
