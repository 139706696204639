export enum CategoriesMutationTypes {
  GET_CATEGORIES = 'GET_CATEGORIES',
  GET_ONE_CATEGORY = 'GET_ONE_CATEGORY',
  CREATE_CATEGORY = 'CREATE_CATEGORY',
  EDIT_CATEGORY = 'EDIT_CATEGORY',
  SET_CURRENT_CATEGORY = 'SET_CURRENT_CATEGORY',
  CLEAR_CURRENT_CATEGORY = 'CLEAR_CURRENT_CATEGORY',
  SET_SEARCH_CATEGORIES_FOR_EXPAND = 'SET_SEARCH_CATEGORIES_FOR_EXPAND',
  SET_CATEGORY_FOR_EXPAND = 'SET_CATEGORY_FOR_EXPAND',
  CLEAR_CATEGORY_FOR_EXPAND = 'CLEAR_CATEGORY_FOR_EXPAND',

  ADD_VARIANT_IN_CATEGORY = 'ADD_VARIANT_IN_CATEGORY',
  EDIT_VARIANT_IN_CATEGORY = 'EDIT_VARIANT_IN_CATEGORY',
  REMOVE_VARIANT_IN_CATEGORY = 'REMOVE_VARIANT_IN_CATEGORY',

  CREATE_FEATURE = 'CREATE_FEATURE',
  EDIT_FEATURE = 'EDIT_FEATURE',
  REMOVE_FEATURE = 'REMOVE_FEATURE',

  // Status
  CATEGORY_LOADING = 'CATEGORY_LOADING',
  CATEGORY_SUCCEEDED = 'CATEGORY_SUCCEEDED',
  CATEGORY_ERROR = 'CATEGORY_ERROR',
}
