import { ActionTree, ActionContext } from 'vuex';
import { RootState } from '@/store';
import CustomersClient from '@/api/shop/customers.api';
import { Query } from '@/@typespaces/types/query.types';
import { CustomersActionTypes } from '@/store/modules/customers/action-types';
import { CustomersMutationTypes } from '@/store/modules/customers/mutation-types';
import { State } from './state';
import { Mutations } from './mutations';

const client = new CustomersClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [CustomersActionTypes.FETCH_CUSTOMERS](
    { commit }: AugmentedActionContext,
    data: { projectId: string; query: Query }
  ): void;

  [CustomersActionTypes.EDIT_CUSTOMER](
    { commit }: AugmentedActionContext,
    payload: { userId: string; location: string }
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [CustomersActionTypes.FETCH_CUSTOMERS]({ commit }, data: { projectId: string; query: Query }) {
    commit(CustomersMutationTypes.CUSTOMERS_LOADING);
    try {
      const users = await client.getCustomers(data);
      commit(CustomersMutationTypes.FETCH_CUSTOMERS, users);
      commit(CustomersMutationTypes.CUSTOMERS_SUCCEEDED);
    } catch (e: any) {
      commit(CustomersMutationTypes.CUSTOMER_ERROR, e);
    }
  },

  async [CustomersActionTypes.EDIT_CUSTOMER]({ commit }, payload: { userId: string; location: string }) {
    commit(CustomersMutationTypes.CUSTOMERS_LOADING);
    try {
      await client.editCustomer(payload);

      commit(CustomersMutationTypes.EDIT_CUSTOMER, payload);
      commit(CustomersMutationTypes.CUSTOMERS_SUCCEEDED);
    } catch (e: any) {
      commit(CustomersMutationTypes.CUSTOMER_ERROR, e);
      throw new Error(e);
    }
  },
};
