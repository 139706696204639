import { IRubric } from '@/@typespaces/interfaces/rubric.interfaces';
import { Query } from '@/@typespaces/types/query.types';
import RubricsApi from '@/api/rubrics.api';
import { RubricsActionTypes } from '@/store/modules/rubrics/action-types';
import { RubricsMutationTypes } from '@/store/modules/rubrics/mutation-types';
import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store';
import { State } from './state';
import { Mutations } from './mutations';

const api = new RubricsApi();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [RubricsActionTypes.FETCH_RUBRICS](
    { commit }: AugmentedActionContext,
    payload: { projectId: string; query: Query }
  ): void;
  [RubricsActionTypes.EDIT_RUBRIC]({ commit }: AugmentedActionContext, payload: IRubric): void;
  [RubricsActionTypes.CREATE_RUBRIC]({ commit }: AugmentedActionContext, payload: Partial<IRubric>): void;
  [RubricsActionTypes.REMOVE_RUBRIC]({ commit }: AugmentedActionContext, payload: string): void;
  [RubricsActionTypes.REMOVE_MANY_RUBRICS]({ commit }: AugmentedActionContext, payload: string[]): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [RubricsActionTypes.FETCH_RUBRICS]({ commit }, payload: { projectId: string; query: Query }) {
    commit(RubricsMutationTypes.RUBRICS_LOADING);
    try {
      const data = await api.fetchRubrics(payload);
      commit(RubricsMutationTypes.FETCH_RUBRICS, data);
      commit(RubricsMutationTypes.RUBRICS_SUCCEEDED);
    } catch (err) {
      commit(RubricsMutationTypes.RUBRICS_ERROR, String(err));
      throw new Error(err as string);
    }
  },

  async [RubricsActionTypes.EDIT_RUBRIC]({ commit }, payload: IRubric) {
    commit(RubricsMutationTypes.RUBRICS_LOADING);
    try {
      await api.editRubric(payload);
      commit(RubricsMutationTypes.EDIT_RUBRIC, payload);
      commit(RubricsMutationTypes.RUBRICS_SUCCEEDED);
    } catch (err) {
      commit(RubricsMutationTypes.RUBRICS_ERROR, String(err));
      throw new Error(err as string);
    }
  },

  async [RubricsActionTypes.CREATE_RUBRIC]({ commit }, payload: Partial<IRubric>) {
    commit(RubricsMutationTypes.RUBRICS_LOADING);
    try {
      const data = await api.createRubric(payload);
      commit(RubricsMutationTypes.CREATE_RUBRIC, data);
      commit(RubricsMutationTypes.RUBRICS_SUCCEEDED);
    } catch (err) {
      commit(RubricsMutationTypes.RUBRICS_ERROR, String(err));
      throw new Error(err as string);
    }
  },

  async [RubricsActionTypes.REMOVE_RUBRIC]({ commit }, payload: string) {
    commit(RubricsMutationTypes.RUBRICS_LOADING);
    try {
      await api.removeRubric(payload);
      commit(RubricsMutationTypes.REMOVE_RUBRIC, payload);
      commit(RubricsMutationTypes.RUBRICS_SUCCEEDED);
    } catch (err) {
      commit(RubricsMutationTypes.RUBRICS_ERROR, String(err));
      throw new Error(err as string);
    }
  },

  async [RubricsActionTypes.REMOVE_MANY_RUBRICS]({ commit }, payload: string[]) {
    commit(RubricsMutationTypes.RUBRICS_LOADING);
    try {
      await api.removeManyRubrics(payload);
      commit(RubricsMutationTypes.REMOVE_MANY_RUBRICS, payload);
      commit(RubricsMutationTypes.RUBRICS_SUCCEEDED);
    } catch (err) {
      commit(RubricsMutationTypes.RUBRICS_ERROR, String(err));
      throw new Error(err as string);
    }
  },
};
