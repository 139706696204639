import { ConfirmAccount, RestorePassword } from '@/@typespaces/types/form/auth.types';
import InstanceHttpClient from './instance';

export default class AuthClient extends InstanceHttpClient {
  constructor() {
    super('auth/scs');
  }

  async signIn(data: { email: string; password: string }): Promise<{ access_token: string }> {
    try {
      return await this.apiCall({
        method: 'POST',
        url: 'login',
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async invitation(invitationId: string, password: string): Promise<{ access_token: string }> {
    try {
      return await this.apiCall({
        method: 'POST',
        url: 'invitation',
        data: {
          invitationId,
          password,
        },
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async register(data: { email: string }): Promise<{ access_token: string }> {
    try {
      return await this.apiCall({
        method: 'POST',
        url: 'register',
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async signUp(data: { email: string; password: string }): Promise<{ access_token: string }> {
    try {
      return await this.apiCall({
        method: 'POST',
        url: 'signup',
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async takeTokenForProject(projectId: string): Promise<{ access_token: string }> {
    try {
      const request: any = await this.apiCall({
        method: 'POST',
        url: 'take-tokens-for-project',
        data: {
          project_id: projectId,
        },
      });
      return {
        access_token: request.access_token,
      };
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async takeTokenForDashboard(): Promise<{ access_token: string }> {
    try {
      const request: any = await this.apiCall({
        method: 'POST',
        url: 'take-tokens-for-dashboard',
      });
      return {
        access_token: request.access_token,
      };
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async recoveryPassword(email: string) {
    try {
      await this.apiCall({
        method: 'POST',
        url: 'recovery-password',
        data: { email },
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async restorePassword(data: RestorePassword) {
    try {
      await this.apiCall({
        method: 'POST',
        url: 'restore-password',
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async reconfirmation(email: string) {
    try {
      await this.apiCall({
        method: 'POST',
        url: 're-confirmation-request',
        data: email,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async confirmAccount(data: ConfirmAccount) {
    try {
      await this.apiCall({
        method: 'PATCH',
        url: 'confirm-account',
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
