import StatusEnum from '@/@typespaces/enum/status.enum';
import { IRole } from '@/@typespaces/interfaces/rolesAndAccess.interface';
import { IUser } from '@/@typespaces/interfaces/user.interface';

export interface State {
  users: IUser[];
  usersIds: string[];
  roles: IRole[];
  page: number;
  total: number;
  pageSize: number;
  error: string | null;
  status: StatusEnum;
  role: IRole | null;
}

export const state: State = {
  users: [],
  usersIds: [],
  roles: [],
  page: 0,
  total: 0,
  pageSize: 0,
  error: null,
  status: StatusEnum.IDLE,
  role: null,
};
