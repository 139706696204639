import { IAnswer, IComment, ICommentData } from '@/@typespaces/interfaces/comment.interface';
import { Query } from '@/@typespaces/types/query.types';
import InstanceHttpClient from './instance';

export default class CommentsClient extends InstanceHttpClient {
  constructor() {
    super('content/comments/');
  }

  async getComments(data: { query: Query }): Promise<ICommentData> {
    try {
      return await this.apiCall({
        method: 'GET',
        params: data.query,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async getOneComment(id: string): Promise<IComment> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: id,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async createComment(comment: Partial<IComment>): Promise<IComment> {
    try {
      return await this.apiCall({
        method: 'POST',
        data: comment,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async removeComment(id: string) {
    try {
      await this.apiCall({
        method: 'DELETE',
        headers: {
          comment_id: id,
        },
        url: id,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async updateStatusComment(data: { id: string; status: string }): Promise<IComment> {
    try {
      return await this.apiCall({
        method: 'PATCH',
        headers: {
          comment_id: data.id,
        },
        url: `${data.id}/update-status`,
        data: { status: data.status },
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async addAnswer(data: { id: string; answer: IAnswer }) {
    try {
      await this.apiCall({
        method: 'PATCH',
        headers: {
          comment_id: data.id,
        },
        url: `${data.id}/add-answer`,
        data: data.answer,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
