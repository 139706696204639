export const EDITOR_OPTIONS = {
  modules: {
    toolbar: [
      ['bold'],
      [{ color: [] }],
      [{ align: '' }],
      [{ align: 'center' }],
      [{ align: 'right' }],
      ['link', 'image', 'video'],
      ['clean'],
    ],
  },
};
