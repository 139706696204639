import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability';
import { IRole } from '@/@typespaces/interfaces/rolesAndAccess.interface';

export type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete' | string;
export type Subjects =
  | 'Admins'
  | 'Franchise'
  | 'Roles'
  | 'Users'
  | 'Projects'
  | 'Site_Settings'
  | 'Media'
  | 'Knowledge_Base'
  | 'Notifications'
  | 'Integrations'
  | 'Contacts'
  | 'Seo'
  | 'Third_Party'
  | 'Templates'
  | 'Constructor'
  | 'Orders'
  | 'Products'
  | 'Stores'
  | 'Blogs'
  | 'Topics'
  | 'Comments'
  | 'Reviews'
  | 'Services'
  | 'Faq'
  | 'Clients'
  | 'all'
  | string;

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

export default function defineRulesFor(profile: IRole) {
  const { can, rules } = new AbilityBuilder(AppAbility);
  if (profile?.permissions) {
    profile.permissions.map((item: any) => can(item.action, item.subject));
  }
  return rules;
}

export function buildAbilityFor(role: IRole): AppAbility {
  return new AppAbility(defineRulesFor(role), {
    // @ts-ignore
    detectSubjectType: (object) => object!.type,
  });
}
