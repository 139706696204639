import Status from '@/@typespaces/enum/status.enum';
import { Category, CategoryFull } from '@/@typespaces/interfaces/category.interface';
import { RootState } from '@/store';
import { recursiveMap } from '@/utils/recursiveMap';
import { GetterTree } from 'vuex';
import { State } from './state';

export type Getters = {
  getCategories(state: State): Category[];
  getCategoryById: (state: State) => (id: string) => Category | undefined;
  getCategoryLoading(state: State): boolean;
  getCurrentCategory(state: State): CategoryFull | null;
  getSelectedCategory(state: State): string | null;
  getCategoryForExpand(state: State): string | null;
  getSearchCategoriesForExpand(state: State): string[] | null;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getCategories: (state) => recursiveMap(state.data),
  getCategoryLoading: (state) => state.status === Status.LOADING,
  getCategoryById: (state) => (id: string) => state.data.find((category) => category.id === id),
  getCurrentCategory: (state) => state.category,
  getSelectedCategory: (state) => state.selectedCategory,
  getCategoryForExpand: (state) => state.categoryForExpand,
  getSearchCategoriesForExpand: (state) => state.searchCategoriesExpand,
};
