import { MainDomain } from '@/@typespaces/interfaces/domain.interface';
import { CheckHttpsPayload, DomainPayload } from '@/@typespaces/types/form/domain.types';
import InstanceHttpClient from './instance';

export default class DomainClient extends InstanceHttpClient {
  constructor() {
    super('domain');
  }

  async connectDomain(body: DomainPayload): Promise<MainDomain> {
    const { projectId, data } = body;
    try {
      return await this.apiCall({
        method: 'POST',
        url: `/${projectId}/connect-domain`,
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async checkHttpsAccess(body: CheckHttpsPayload): Promise<MainDomain> {
    const { projectId, data } = body;
    try {
      return await this.apiCall({
        method: 'POST',
        url: `/${projectId}/check-https-access`,
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async checkExpiryDate({ projectId, domain }: { projectId: string; domain: string }): Promise<any> {
    try {
      return await this.apiCall({
        method: 'POST',
        url: `${projectId}/check-expiry-date`,
        data: { domain },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async checkDomain({ projectId, domain }: { projectId: string; domain: string }): Promise<{
    cname: boolean;
    https: boolean;
  }> {
    try {
      return await this.apiCall({
        method: 'POST',
        url: `${projectId}/check-domain`,
        data: { domain },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async checkExistDomain({ projectId, domain }: { projectId: string; domain: string }): Promise<boolean> {
    try {
      return await this.apiCall({
        method: 'POST',
        url: `${projectId}/check-exist-domain`,
        data: { domain },
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async checkSSLCert({
    projectId,
    body,
  }: {
    projectId: string;
    body: FormData;
  }): Promise<{ success: boolean; message?: string; domain?: string; expiryDate?: number }> {
    try {
      return await this.apiCall({
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        method: 'POST',
        url: `${projectId}/check-cert-by-files`,
        data: body,
      });
    } catch (err: any) {
      throw new Error(err);
    }
  }

  async removeDomain(body: CheckHttpsPayload) {
    const { projectId, data } = body;
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `/${projectId}/remove-domain`,
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async changeMainDomain({ projectId, domain }: { projectId: string; domain: string }) {
    try {
      return this.apiCall({
        method: 'PATCH',
        url: `/${projectId}/change-main-domain`,
        data: { domain },
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
