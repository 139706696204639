import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store';
import StatisticsApi from '@/api/statistics.api';
import { StatisticsQuery } from '@/@typespaces/types/statisticsQuery';
import { StatisticsActionTypes } from './action-types';
import { StatisticsMutationTypes } from './mutation-types';
import { State } from './state';
import { Mutations } from './mutations';

const api = new StatisticsApi();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [StatisticsActionTypes.GET_STATISTICS_VISITORS]({ commit }: AugmentedActionContext, payload: StatisticsQuery): void;
  [StatisticsActionTypes.GET_STATISTICS_CUSTOMERS]({ commit }: AugmentedActionContext, payload: StatisticsQuery): void;
  [StatisticsActionTypes.GET_STATISTICS_ORDERS]({ commit }: AugmentedActionContext, payload: StatisticsQuery): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [StatisticsActionTypes.GET_STATISTICS_VISITORS]({ commit }, payload: StatisticsQuery) {
    commit(StatisticsMutationTypes.STATISTICS_LOADING);
    try {
      const response = await api.getVisitors(payload);
      commit(StatisticsMutationTypes.GET_STATISTICS_VISITORS, response);
      commit(StatisticsMutationTypes.STATISTICS_SUCCEEDED);
    } catch (error) {
      commit(StatisticsMutationTypes.STATISTICS_ERROR);
      throw new Error(error as string);
    }
  },

  async [StatisticsActionTypes.GET_STATISTICS_CUSTOMERS]({ commit }, payload: StatisticsQuery) {
    commit(StatisticsMutationTypes.STATISTICS_LOADING);
    try {
      const response = await api.getCustomers(payload);
      commit(StatisticsMutationTypes.GET_STATISTICS_CUSTOMERS, response);
      commit(StatisticsMutationTypes.STATISTICS_SUCCEEDED);
    } catch (error) {
      commit(StatisticsMutationTypes.STATISTICS_ERROR);
      throw new Error(error as string);
    }
  },

  async [StatisticsActionTypes.GET_STATISTICS_ORDERS]({ commit }, payload: StatisticsQuery) {
    commit(StatisticsMutationTypes.STATISTICS_LOADING);
    try {
      const response = await api.getOrders(payload);
      commit(StatisticsMutationTypes.GET_STATISTICS_ORDERS, response);
      commit(StatisticsMutationTypes.STATISTICS_SUCCEEDED);
    } catch (error) {
      commit(StatisticsMutationTypes.STATISTICS_ERROR);
      throw new Error(error as string);
    }
  },
};
