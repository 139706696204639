export const findDeepObject = <T, R>(array: Array<T>, key: string, value: string): R | undefined => {
  let o;
  array.some(function iter(a: { [key: string]: any }) {
    if (a[key] === value) {
      o = a;
      return true;
    }
    return Array.isArray(a.child_categories) && a.child_categories.some(iter);
  });
  return o;
};
