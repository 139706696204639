export enum CategoriesActionTypes {
  GET_CATEGORIES = 'GET_CATEGORIES',
  GET_CATEGORIES_BY_ALIAS = 'GET_CATEGORIES_BY_ALIAS',
  GET_ONE_CATEGORY = 'GET_ONE_CATEGORY',
  CREATE_CATEGORY = 'CREATE_CATEGORY',
  EDIT_CATEGORY = 'EDIT_CATEGORY',
  EDIT_PRIORITY_CATEGORY = 'EDIT_PRIORITY_CATEGORY',
  REMOVE_CATEGORY = 'REMOVE_CATEGORY',
  RENAME_CATEGORY = 'RENAME_CATEGORY',
  ADD_VARIANT_IN_CATEGORY = 'ADD_VARIANT_IN_CATEGORY',
  EDIT_VARIANT_IN_CATEGORY = 'EDIT_VARIANT_IN_CATEGORY',
  REMOVE_VARIANT_IN_CATEGORY = 'REMOVE_VARIANT_IN_CATEGORY',
  CREATE_FEATURE = 'CREATE_FEATURE',
  EDIT_FEATURE = 'EDIT_FEATURE',
  REMOVE_FEATURE = 'REMOVE_FEATURE',
  GET_CATEGORY_PATH = 'GET_CATEGORY_PATH',
  SET_CURRENT_CATEGORY = 'SET_CURRENT_CATEGORY',
  CLEAR_CURRENT_CATEGORY = 'CLEAR_CURRENT_CATEGORY',
}
