import Status from '@/@typespaces/enum/status.enum';
import { IAuthor, INewsAndBlog, INewsAndBlogSettings } from '@/@typespaces/interfaces/newsAndBlog.interfaces';

export interface State {
  data: INewsAndBlog[];
  dataIds: string[];
  settings: INewsAndBlogSettings | null;
  currentBlog: INewsAndBlog | null;
  page: number;
  pageSize: number;
  total: number;
  error: string | null;
  status: Status;
  authors: IAuthor[];
}

export const state: State = {
  data: [],
  dataIds: [],
  currentBlog: null,
  page: 0,
  pageSize: 0,
  total: 0,
  error: null,
  status: Status.IDLE,
  settings: null,
  authors: [],
};
