import Status from '@/@typespaces/enum/status.enum';
import { User } from '@/@typespaces/interfaces/users.interface';
import { MutationTree } from 'vuex';
import { UsersMutationTypes } from './mutation-types';
import { State } from './state';

export type Mutations<S = State> = {
  [UsersMutationTypes.GET_PROJECT_USERS](state: S, payload: User[]): void;
  [UsersMutationTypes.USERS_LOADING](state: S): void;
  [UsersMutationTypes.USERS_SUCCEEDED](state: S): void;
  [UsersMutationTypes.USERS_ERROR](state: S, payload: string): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [UsersMutationTypes.GET_PROJECT_USERS](state: State, data: User[]) {
    state.users = data;
  },
  [UsersMutationTypes.USERS_LOADING](state: State) {
    state.status = Status.LOADING;
  },
  [UsersMutationTypes.USERS_SUCCEEDED](state: State) {
    state.status = Status.SUCCEEDED;
  },
  [UsersMutationTypes.USERS_ERROR](state: State, payload: string) {
    state.error = payload;
    state.status = Status.IDLE;
  },
};
