import { FiltersQuery } from '@/@typespaces/types/query.types';

export interface State {
  searchString: string;
  filter: FiltersQuery[];
  collapsed: boolean;
  confirmModal: boolean;
}

export const state: State = {
  searchString: '',
  filter: [],
  collapsed: false,
  confirmModal: false,
};
