import { Category } from '@/@typespaces/interfaces/category.interface';

export const recursiveMap = (category: Category[]): Category[] =>
  category.map((item) => {
    if (item.child_categories) {
      return {
        ...item,
        key: item.id,
        child_categories: recursiveMap(item.child_categories),
      };
    }
    return { ...item, key: item.id };
  });
