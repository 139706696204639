import { Review, ReviewSettings } from '@/@typespaces/interfaces/review';
import Status from '@/@typespaces/enum/status.enum';

export interface State {
  data: Review[];
  currentReview: Review | null;
  status: Status;
  error: string | null;
  pagination: {
    page: number;
    pageSize: number;
    total: number;
  };

  settings: ReviewSettings | null;
}

export const state: State = {
  data: [],
  currentReview: null,
  status: Status.IDLE,
  error: null,
  pagination: {
    page: 0,
    pageSize: 0,
    total: 0,
  },
  settings: null,
};
