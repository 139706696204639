import { TemplateData } from '@/@typespaces/interfaces/template.interface';
import { Query } from '@/@typespaces/types/query.types';
import InstanceHttpClient from './instance';

export default class TemplatesClient extends InstanceHttpClient {
  constructor() {
    super('templates');
  }

  async fetchTemplates(query?: Query): Promise<TemplateData> {
    try {
      return await this.apiCall({
        method: 'GET',
        params: query,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
