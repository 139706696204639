import { MainDomain } from '@/@typespaces/interfaces/domain.interface';
import { BlogSettings } from '@/@typespaces/interfaces/newsAndBlog.interfaces';
import { Seo } from '@/@typespaces/interfaces/template.interface';
import { BitrixIntegration } from '@/@typespaces/types/form/bitrixIntegration';
import { CheckHttpsPayload } from '@/@typespaces/types/form/domain.types';
import { ThirdPartyCodeData } from '@/@typespaces/types/form/thirdPartyCode';
import { MutationTree } from 'vuex';
import { ProjectsMutationTypes } from '@/store/modules/projects/mutation-types';
import {
  ICredentialsData,
  Project,
  ShortProject,
  ProjectData,
  DocumentsData,
} from '@/@typespaces/interfaces/projects.interface';
import Status from '@/@typespaces/enum/status.enum';
import { ContactsPayload } from '@/@typespaces/interfaces/form/contact-form-interface';
import { UpdateFilePayload } from '@/@typespaces/types/updateFile.types';
import { State } from './state';

export type Mutations<S = State> = {
  [ProjectsMutationTypes.FETCH_PROJECTS](state: S, payload: ProjectData): void;
  [ProjectsMutationTypes.SET_YOOMONEY_CREDENTIALS](state: S, payload: ICredentialsData): void;
  [ProjectsMutationTypes.SET_BITRIX_CREDENTIALS](state: S, payload: BitrixIntegration): void;
  [ProjectsMutationTypes.CREATE_PROJECT](state: S, payload: Project): void;
  [ProjectsMutationTypes.FETCH_ONE_PROJECT](state: S, payload: Project): void;
  [ProjectsMutationTypes.UPDATE_CURRENT_PROJECT](state: S, payload: Project): void;
  [ProjectsMutationTypes.CLEAR_ONE_PROJECT](state: S): void;
  [ProjectsMutationTypes.COPY_PROJECT](state: S, payload: Project): void;
  [ProjectsMutationTypes.DELETE_PROJECT](state: S, payload: string): void;
  [ProjectsMutationTypes.PUBLISH_PROJECT](state: S, payload: string): void;
  [ProjectsMutationTypes.UN_PUBLISH_PROJECT](state: S, payload: string): void;
  [ProjectsMutationTypes.EDIT_PROJECT](state: S, payload: ShortProject): void;
  [ProjectsMutationTypes.EDIT_THIRD_PARTY_CODE](state: S, payload: ThirdPartyCodeData): void;
  [ProjectsMutationTypes.EDIT_TEMPLATE_SEO](state: S, payload: Partial<Seo>): void;
  [ProjectsMutationTypes.GENERATE_SITEMAP](state: S): void;
  [ProjectsMutationTypes.EDIT_CONTACTS](state: S, payload: ContactsPayload): void;
  [ProjectsMutationTypes.EDIT_BLOG_SETTINGS](state: S, payload: Partial<BlogSettings>): void;
  [ProjectsMutationTypes.CONNECT_DOMAIN_IN_PROJECT](state: S, payload: MainDomain): void;
  [ProjectsMutationTypes.REMOVE_DOMAIN_FROM_PROJECT](state: S, payload: CheckHttpsPayload): void;
  [ProjectsMutationTypes.UPDATE_CONFIDENTIAL_POLICY_IN_PROJECT](state: S, payload: DocumentsData): void;
  [ProjectsMutationTypes.UPDATE_USER_AGREEMENT_IN_PROJECT](state: S, payload: DocumentsData): void;
  [ProjectsMutationTypes.UPDATE_PROJECT_FILE](state: S, payload: UpdateFilePayload): void;

  // Status
  [ProjectsMutationTypes.PROJECTS_ERROR](state: S, payload: string): void;
  [ProjectsMutationTypes.PROJECTS_LOADING](state: S): void;
  [ProjectsMutationTypes.PROJECTS_SUCCEEDED](state: S): void;

  [ProjectsMutationTypes.CURRENT_PROJECT_LOADING](state: S): void;
  [ProjectsMutationTypes.CURRENT_PROJECT_SUCCEEDED](state: S): void;
  [ProjectsMutationTypes.CURRENT_PROJECT_ERROR](state: S): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [ProjectsMutationTypes.FETCH_PROJECTS](state: State, data: ProjectData) {
    state.data = data.data.projects;
    state.projectsIds = data.data.projectsIds;
    state.total = data.total;
    state.page = data.page;
    state.pageSize = data.pageSize;
  },
  [ProjectsMutationTypes.FETCH_ONE_PROJECT](state: State, project: Project) {
    state.currentProject = project;
  },
  [ProjectsMutationTypes.UPDATE_CURRENT_PROJECT](state: State, project: Project) {
    state.currentProject = null;
    state.currentProject = project;
  },
  [ProjectsMutationTypes.CLEAR_ONE_PROJECT](state: State) {
    state.currentProject = null;
  },
  [ProjectsMutationTypes.CREATE_PROJECT](state: State, project: Project) {
    state.currentProject = project;
  },

  [ProjectsMutationTypes.CONNECT_DOMAIN_IN_PROJECT](state: State, payload: MainDomain) {
    if (state.currentProject) {
      state.currentProject = {
        ...state.currentProject,
        domain_settings: { ...state.currentProject.domain_settings, main_domain: payload },
      };
    }
  },

  [ProjectsMutationTypes.REMOVE_DOMAIN_FROM_PROJECT](state: State, payload: CheckHttpsPayload) {
    if (state.currentProject) {
      if (payload.data.type === 'main_domain') {
        state.currentProject = {
          ...state.currentProject,
          domain_settings: {
            ...state.currentProject.domain_settings,
            main_domain: {
              approve_domain: false,
              approve_https_access: false,
              domain_delegation_date: null,
              ssl: false,
              url: null,
              cert: null,
              key: null,
            },
          },
        };
      }
    }
  },
  [ProjectsMutationTypes.COPY_PROJECT](state: State, project: Project) {
    const shortProject: ShortProject = {
      _id: project._id,
      description: project.description,
      preview: project.template?.preview.template?.url || null,
      published: project.published,
      title: project.title,
    };

    if (state.data.length < state.pageSize) {
      state.data.push(shortProject);
    }
    state.total += 1;
  },
  [ProjectsMutationTypes.DELETE_PROJECT](state: State, id: string) {
    const removeIndex = state.data.map((item) => item._id).indexOf(id);
    state.data.splice(removeIndex, 1);
  },

  [ProjectsMutationTypes.PUBLISH_PROJECT](state: State, id: string) {
    state.data = state.data.map((item) => {
      if (item._id === id) {
        return {
          ...item,
          blocked: false,
        };
      }
      return item;
    });
  },

  [ProjectsMutationTypes.SET_YOOMONEY_CREDENTIALS](state: State, data: ICredentialsData) {
    if (state.currentProject) {
      state.currentProject = {
        ...state.currentProject,
        integrations: {
          ...state.currentProject.integrations,
          yoomoney: data,
        },
      };
    }
  },

  [ProjectsMutationTypes.SET_BITRIX_CREDENTIALS](state: State, data: BitrixIntegration) {
    if (state.currentProject) {
      state.currentProject = {
        ...state.currentProject,
        integrations: {
          ...state.currentProject.integrations,
          bitrix: data,
        },
      };
    }
  },

  [ProjectsMutationTypes.UN_PUBLISH_PROJECT](state: State, id: string) {
    state.data = state.data.map((item) => {
      if (item._id === id) {
        return {
          ...item,
          blocked: true,
        };
      }
      return item;
    });
  },

  [ProjectsMutationTypes.EDIT_PROJECT](state: State, data: ShortProject) {
    state.data = state.data.map((item) => {
      if (item._id === data._id) {
        return data;
      }
      return item;
    });
  },

  [ProjectsMutationTypes.EDIT_THIRD_PARTY_CODE](state: State, data: ThirdPartyCodeData) {
    const { body, head } = data;
    if (state.currentProject) {
      state.currentProject = {
        ...state.currentProject,
        template: { ...state.currentProject?.template, third_party_code: { body, head } },
      };
    }
  },
  [ProjectsMutationTypes.EDIT_TEMPLATE_SEO](state: State, data: Partial<Seo>) {
    if (state.currentProject) {
      state.currentProject = {
        ...state.currentProject,
        template: {
          ...state.currentProject?.template,
          seo: { ...state.currentProject.template.seo, ...data },
        },
      };
    }
  },

  [ProjectsMutationTypes.GENERATE_SITEMAP](state: State) {
    console.log(state.currentProject?.template.seo.sitemap);
  },

  [ProjectsMutationTypes.EDIT_CONTACTS](state: State, payload: ContactsPayload) {
    if (state.currentProject) {
      const contacts = { ...payload, feedback_email: payload.feedbackEmail || '' };
      delete contacts.feedbackEmail;

      state.currentProject = {
        ...state.currentProject,
        contacts,
      };
    }
  },

  [ProjectsMutationTypes.EDIT_BLOG_SETTINGS](state: State, payload: Partial<BlogSettings>) {
    if (state.currentProject) {
      state.currentProject = {
        ...state.currentProject,
        blog_settings: { ...state.currentProject.blog_settings, ...payload },
      };
    }
  },

  // Documents

  [ProjectsMutationTypes.UPDATE_CONFIDENTIAL_POLICY_IN_PROJECT](state: State, payload: DocumentsData) {
    if (state.currentProject) {
      state.currentProject = {
        ...state.currentProject,
        documents: { ...state.currentProject.documents, privacy_policy: payload },
      };
    }
  },

  [ProjectsMutationTypes.UPDATE_USER_AGREEMENT_IN_PROJECT](state: State, payload: DocumentsData) {
    if (state.currentProject) {
      state.currentProject = {
        ...state.currentProject,
        documents: { ...state.currentProject.documents, user_agreement: payload },
      };
    }
  },
  [ProjectsMutationTypes.UPDATE_PROJECT_FILE](state: State, payload: UpdateFilePayload) {
    if (state.currentProject) {
      state.currentProject = {
        ...state.currentProject,
        [payload.fileData.type]: payload.file,
      };
    }
  },

  // Status
  [ProjectsMutationTypes.PROJECTS_LOADING](state) {
    state.status = Status.LOADING;
  },
  [ProjectsMutationTypes.PROJECTS_SUCCEEDED](state) {
    state.status = Status.SUCCEEDED;
  },
  [ProjectsMutationTypes.PROJECTS_ERROR](state: State, error: string) {
    state.error = error;
    state.status = Status.IDLE;
  },

  [ProjectsMutationTypes.CURRENT_PROJECT_LOADING](state: State) {
    state.currentProjectStatus = Status.LOADING;
  },
  [ProjectsMutationTypes.CURRENT_PROJECT_SUCCEEDED](state: State) {
    state.currentProjectStatus = Status.SUCCEEDED;
  },
  [ProjectsMutationTypes.CURRENT_PROJECT_ERROR](state: State) {
    state.currentProjectStatus = Status.FAILED;
  },
};
