export enum ProjectsMutationTypes {
  FETCH_PROJECTS = 'FETCH_PROJECTS',
  SET_YOOMONEY_CREDENTIALS = 'SET_YOOMONEY_CREDENTIALS',
  SET_BITRIX_CREDENTIALS = 'SET_BITRIX_CREDENTIALS',
  DELETE_PROJECT = 'DELETE_PROJECT',
  EDIT_PROJECT = 'EDIT_PROJECT',
  FETCH_ONE_PROJECT = 'FETCH_ONE_PROJECT',
  CLEAR_ONE_PROJECT = 'CLEAR_ONE_PROJECT',
  PUBLISH_PROJECT = 'PUBLISH_PROJECT',
  UN_PUBLISH_PROJECT = 'UN_PUBLISH_PROJECT',
  COPY_PROJECT = 'COPY_PROJECT',
  CREATE_PROJECT = 'CREATE_PROJECT',
  EDIT_THIRD_PARTY_CODE = 'EDIT_THIRD_PARTY_CODE',
  EDIT_TEMPLATE_SEO = 'EDIT_TEMPLATE_SEO',
  GENERATE_SITEMAP = 'GENERATE_SITEMAP',
  EDIT_CONTACTS = 'EDIT_CONTACTS',
  EDIT_BLOG_SETTINGS = 'EDIT_BLOG_SETTINGS',
  CONNECT_DOMAIN_IN_PROJECT = 'CONNECT_DOMAIN_IN_PROJECT',
  REMOVE_DOMAIN_FROM_PROJECT = 'REMOVE_DOMAIN_FROM_PROJECT',
  UPDATE_CONFIDENTIAL_POLICY_IN_PROJECT = 'UPDATE_CONFIDENTIAL_POLICY_IN_PROJECT',
  UPDATE_USER_AGREEMENT_IN_PROJECT = 'UPDATE_USER_AGREEMENT_IN_PROJECT',
  UPDATE_PROJECT_FILE = 'UPDATE_PROJECT_FILE',
  UPDATE_CURRENT_PROJECT = 'UPDATE_CURRENT_PROJECT',

  // Status
  PROJECTS_LOADING = 'PROJECTS_LOADING',
  PROJECTS_SUCCEEDED = 'PROJECTS_SUCCEEDED',
  PROJECTS_ERROR = 'PROJECTS_ERROR',

  CURRENT_PROJECT_LOADING = 'CURRENT_PROJECT_LOADING',
  CURRENT_PROJECT_SUCCEEDED = 'CURRENT_PROJECT_SUCCEEDED',
  CURRENT_PROJECT_ERROR = 'CURRENT_PROJECT_ERROR',
}
