import { IOrder } from '@/@typespaces/interfaces/orders.interface';
import Status from '@/@typespaces/enum/status.enum';

export interface State {
  data: IOrder[];
  ordersIds: string[];
  oneOrder: IOrder | null;
  orderStatus: Status;
  error: string | null;
  page: number;
  pageSize: number;
  total: number;
}

export const state: State = {
  data: [],
  ordersIds: [],
  orderStatus: Status.IDLE,
  error: null,
  oneOrder: null,
  page: 0,
  pageSize: 0,
  total: 0,
};
