import IdentityClient from '@/api/identity.api';
import { RootState } from '@/store';
import { IdentityMutationTypes } from '@/store/modules/identity/mutation-types';
import { ActionContext, ActionTree } from 'vuex';
import { IdentityActionTypes } from './action-types';
import { Mutations } from './mutations';
import { State } from './state';

const client = new IdentityClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [IdentityActionTypes.FETCH_IDENTITY]({ commit }: AugmentedActionContext): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [IdentityActionTypes.FETCH_IDENTITY]({ commit }) {
    commit(IdentityMutationTypes.IDENTITY_LOADING);
    try {
      const identity = await client.getIdentity();
      commit(IdentityMutationTypes.FETCH_IDENTITY, identity);
    } catch (err) {
      commit(IdentityMutationTypes.IDENTITY_ERROR, err as string);
      throw new Error(err as string);
    }
  },
};
