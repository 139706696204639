import { NewsAndBlogStatus } from '@/@typespaces/enum/newsAndBlogStatus.enum';
import ContentSettingsApi from '@/api/contentSettings.api';
import { ActionContext, ActionTree } from 'vuex';
import { RootState, store } from '@/store';
import NewsAndBlogApi from '@/api/newsAndBlog.api';
import { NewsAndBlogsActionTypes } from '@/store/modules/newsAndBlog/action-types';
import { NewsAndBlogMutationTypes } from '@/store/modules/newsAndBlog/mutation-types';
import { Query } from '@/@typespaces/types/query.types';
import { IAuthor, INewsAndBlog, INewsAndBlogSettings } from '@/@typespaces/interfaces/newsAndBlog.interfaces';
import { State } from './state';
import { Mutations } from './mutations';

const api = new NewsAndBlogApi();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  // Authors
  [NewsAndBlogsActionTypes.FETCH_NEWS_AND_BLOGS_AUTHORS](
    { commit }: AugmentedActionContext,
    payload: { query: Query }
  ): void;
  [NewsAndBlogsActionTypes.REMOVE_NEWS_AND_BLOGS_AUTHOR]({ commit }: AugmentedActionContext, payload: string): void;
  [NewsAndBlogsActionTypes.NEWS_AND_BLOGS_EDIT_AUTHOR](
    { commit }: AugmentedActionContext,
    payload: Partial<IAuthor>
  ): void;
  // Blog
  [NewsAndBlogsActionTypes.FETCH_NEWS_AND_BLOGS_DATA](
    { commit }: AugmentedActionContext,
    payload: { projectId: string; query: Query }
  ): void;
  [NewsAndBlogsActionTypes.FETCH_ONE_BLOG]({ commit }: AugmentedActionContext, payload: string): void;
  [NewsAndBlogsActionTypes.EDIT_BLOG]({ commit }: AugmentedActionContext, payload: INewsAndBlog): void;
  [NewsAndBlogsActionTypes.CREATE_BLOG]({ commit }: AugmentedActionContext, payload: INewsAndBlog): void;
  [NewsAndBlogsActionTypes.CREATE_PUBLISH_BLOG]({ commit }: AugmentedActionContext, payload: INewsAndBlog): void;
  [NewsAndBlogsActionTypes.REMOVE_ONE_BLOG]({ commit }: AugmentedActionContext, payload: string): void;
  [NewsAndBlogsActionTypes.COPY_POST]({ commit }: AugmentedActionContext, payload: string): void;
  [NewsAndBlogsActionTypes.UPDATE_POST_COVER](
    { commit }: AugmentedActionContext,
    payload: { postId: string; fileId: string }
  ): void;
  [NewsAndBlogsActionTypes.CLEAR_ONE_POST]({ commit }: AugmentedActionContext): void;
  [NewsAndBlogsActionTypes.SET_STATUS_BLOG](
    { commit }: AugmentedActionContext,
    payload: { blogId: string; status: string }
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [NewsAndBlogsActionTypes.FETCH_NEWS_AND_BLOGS_DATA]({ commit }, payload: { projectId: string; query: Query }) {
    commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_LOADING);
    try {
      const data = await api.fetchNewsAndBlog(payload);
      commit(NewsAndBlogMutationTypes.FETCH_NEWS_AND_BLOGS_DATA, data);
      commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_SUCCEEDED);
    } catch (err) {
      commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_ERROR, err as string);
      throw new Error(err as string);
    }
  },
  async [NewsAndBlogsActionTypes.FETCH_ONE_BLOG]({ commit }, payload: string) {
    commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_LOADING);
    try {
      const data = await api.getOneBlog(payload);
      commit(NewsAndBlogMutationTypes.FETCH_ONE_BLOG, data);
      commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_SUCCEEDED);
    } catch (err) {
      commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_ERROR, err as string);
      throw new Error(err as string);
    }
  },
  async [NewsAndBlogsActionTypes.EDIT_BLOG]({ commit }, payload: INewsAndBlog) {
    commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_LOADING);
    try {
      await api.editBlog(payload);
      commit(NewsAndBlogMutationTypes.EDIT_BLOG, payload);
      commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_SUCCEEDED);
    } catch (err) {
      commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_ERROR, err as string);
      throw new Error(err as string);
    }
  },
  async [NewsAndBlogsActionTypes.CREATE_BLOG]({ commit }, payload: INewsAndBlog) {
    commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_LOADING);
    try {
      const data = await api.createBlog(payload);
      commit(NewsAndBlogMutationTypes.CREATE_BLOG, data);
      commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_SUCCEEDED);
    } catch (err) {
      commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_ERROR, err as string);
      throw new Error(err as string);
    }
  },
  async [NewsAndBlogsActionTypes.CREATE_PUBLISH_BLOG]({ commit }, payload: INewsAndBlog) {
    commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_LOADING);
    try {
      const data = await api.createBlog(payload);
      await store.dispatch(NewsAndBlogsActionTypes.SET_STATUS_BLOG, {
        blogId: data._id,
        status: NewsAndBlogStatus.PUBLISH,
      });
      commit(NewsAndBlogMutationTypes.CREATE_BLOG, data);
      commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_SUCCEEDED);
    } catch (err) {
      commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_ERROR, err as string);
      throw new Error(err as string);
    }
  },
  async [NewsAndBlogsActionTypes.REMOVE_ONE_BLOG]({ commit }, payload: string) {
    commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_LOADING);
    try {
      await api.deleteBlog(payload);
      commit(NewsAndBlogMutationTypes.REMOVE_ONE_BLOG, payload);
      commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_SUCCEEDED);
    } catch (err) {
      commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_ERROR, err as string);
      throw new Error(err as string);
    }
  },
  async [NewsAndBlogsActionTypes.COPY_POST]({ commit }, payload: string) {
    commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_LOADING);
    try {
      const response = await api.copyPost(payload);
      commit(NewsAndBlogMutationTypes.COPY_POST, response);
      commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_SUCCEEDED);
    } catch (err) {
      commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_ERROR, err as string);
      throw new Error(err as string);
    }
  },
  async [NewsAndBlogsActionTypes.SET_STATUS_BLOG]({ commit }, payload: { blogId: string; status: string }) {
    commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_LOADING);
    try {
      await api.setStatus(payload);
      commit(NewsAndBlogMutationTypes.SET_STATUS_BLOG, payload);
      commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_SUCCEEDED);
    } catch (err) {
      commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_ERROR, err as string);
      throw new Error(err as string);
    }
  },

  // Authors
  async [NewsAndBlogsActionTypes.NEWS_AND_BLOGS_EDIT_AUTHOR]({ commit }, payload: Partial<IAuthor>) {
    commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_LOADING);
    try {
      await api.newsAndBlogEditAuthor(payload);
      commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_EDIT_AUTHOR, payload);
      commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_SUCCEEDED);
    } catch (err) {
      commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_ERROR, err as string);
      throw new Error(err as string);
    }
  },
  async [NewsAndBlogsActionTypes.FETCH_NEWS_AND_BLOGS_AUTHORS]({ commit }, payload: { query: Query }) {
    commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_LOADING);
    try {
      const data = await api.fetchNewsAndBlogAuthors(payload);
      commit(NewsAndBlogMutationTypes.FETCH_NEWS_AND_BLOGS_AUTHORS, data);
      commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_SUCCEEDED);
    } catch (err) {
      commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_ERROR, err as string);
      throw new Error(err as string);
    }
  },

  async [NewsAndBlogsActionTypes.REMOVE_NEWS_AND_BLOGS_AUTHOR]({ commit }, payload: string) {
    commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_LOADING);
    try {
      await api.deleteAuthor(payload);
      commit(NewsAndBlogMutationTypes.REMOVE_NEWS_AND_BLOGS_AUTHOR, payload);
      commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_SUCCEEDED);
    } catch (err) {
      commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_ERROR, err as string);
      throw new Error(err as string);
    }
  },

  async [NewsAndBlogsActionTypes.UPDATE_POST_COVER]({ commit }, payload: { postId: string; fileId: string }) {
    commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_LOADING);
    try {
      await api.updateCover(payload);
    } catch (err) {
      commit(NewsAndBlogMutationTypes.NEWS_AND_BLOGS_ERROR, err as string);
      throw new Error(err as string);
    }
  },
  [NewsAndBlogsActionTypes.CLEAR_ONE_POST]({ commit }) {
    commit(NewsAndBlogMutationTypes.CLEAR_ONE_POST);
  },
};
