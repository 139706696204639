import { Answer, Review, ReviewData, ReviewSettings } from '@/@typespaces/interfaces/review';
import { Query } from '@/@typespaces/types/query.types';
import { ReviewsAnswer } from '@/@typespaces/types/form/reviews.types';
import InstanceHttpClient from '../instance';

export default class ReviewsClient extends InstanceHttpClient {
  constructor() {
    super('shop');
  }

  async getReviews(data?: { projectId: string; query: Query }): Promise<ReviewData> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: 'reviews',
        params: data?.query,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async getOneReview(id: string): Promise<Review> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: `reviews/${id}`,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async removeReview(id: string) {
    try {
      await this.apiCall({
        method: 'DELETE',
        url: `reviews/${id}`,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async publishReview(id: string) {
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `reviews/${id}/publish`,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async unPublishReview(id: string) {
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `reviews/${id}/unpublish`,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async createReviewAnswer(data: ReviewsAnswer): Promise<Answer> {
    try {
      return await this.apiCall({
        method: 'POST',
        url: 'review-answers',
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async removeReviewAnswer(id: string) {
    try {
      await this.apiCall({
        method: 'DELETE',
        url: `review-answers/${id}`,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async editReviewAnswer(payload: { id: string; data: ReviewsAnswer }) {
    const { id, data } = payload;
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `review-answers/${id}`,
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async getReviewsSettings(): Promise<ReviewSettings> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: `review-state`,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async editReviewsSettings(payload: { id: string; data: ReviewSettings }): Promise<ReviewSettings> {
    const { id, data } = payload;
    try {
      return await this.apiCall({
        method: 'PATCH',
        url: `review-state/${id}`,
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
