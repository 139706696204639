export enum WarehousesMutationTypes {
  FETCH_WAREHOUSES = 'FETCH_WAREHOUSES',
  FETCH_ONE_WAREHOUSE = 'FETCH_ONE_WAREHOUSE',
  EDIT_WAREHOUSE = 'EDIT_WAREHOUSE',
  CREATE_WAREHOUSE = 'CREATE_WAREHOUSE',
  BLOCK_WAREHOUSE = 'BLOCK_WAREHOUSE',
  UN_BLOCK_WAREHOUSE = 'UN_BLOCK_WAREHOUSE',
  DELETE_WAREHOUSE = 'DELETE_WAREHOUSE',
  // Status
  WAREHOUSES_LOADING = 'WAREHOUSES_LOADING',
  WAREHOUSES_SUCCEEDED = 'WAREHOUSES_SUCCEEDED',
  WAREHOUSES_ERROR = 'WAREHOUSES_ERROR',
}
