import { Franchise } from '@/@typespaces/interfaces/franchise.interface';
import store from '@/store';
import axios from 'axios';
import { createApp } from 'vue';
import Antd from 'ant-design-vue';
import Maska from 'maska';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
// @ts-ignore

import { abilitiesPlugin, Can } from '@casl/vue';
import { IRole } from '@/@typespaces/interfaces/rolesAndAccess.interface';
import VIntersection from '@/directives/VIntersection';
// @ts-ignore
// import VueColor from '@ckpack/vue-color';
import App from './App.vue';
import router from './router';
import { buildAbilityFor } from './services/ability';
import 'ant-design-vue/dist/antd.css';
import '@/style/index.css';

const checkMainDomain = async () => {
  const { host } = window.location;
  if (host) {
    const url = `https://${host}/api/general/franchise`;
    try {
      const response = await axios.get<Franchise>(url);
      const mainDashboardDomain = response.data.domain_settings.main_dashboard_domain.url;
      if (mainDashboardDomain && host !== mainDashboardDomain) {
        window.location.href = `https://${mainDashboardDomain}`;
      }
    } catch (error) {
      console.error('Сетевая ошибка при получении данных франшизы');
    }
  }
};

checkMainDomain();

const ability = buildAbilityFor({} as IRole);

const app = createApp(App);

app.directive('intersection', VIntersection);

app
  .use(store())
  .use(router)
  .use(Antd)
  .use(Maska)
  .use(abilitiesPlugin, ability)
  .component(Can.name, Can)
  .component('QuillEditor', QuillEditor)
  .mount('#app');
