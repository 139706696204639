import {
  EmailTemplate,
  ProjectNotifications,
  RestoreEmailTemplate,
  SmsTemplate,
} from '@/@typespaces/interfaces/notifications.interface';
import {
  EditEmailTemplatePayload,
  GetEmailTemplate,
  NotificationsRecipientsPayload,
  NotificationSwitcherPayload,
  RestoreEmailTemplatePayload,
  RestoreSmsTemplatePayload,
  SendTestEmailPayload,
  SendTestSmsPayload,
  SmsTemplatePayload,
  UpdateEmailSettingsPayload,
  UpdateSmsPayload,
  UpdateSmsSettingsPayload,
} from '@/@typespaces/types/form/notifications-form.type';
import InstanceHttpClient from './instance';

export default class NotificationApi extends InstanceHttpClient {
  constructor() {
    super('notifications');
  }

  async getNotifications(projectId: string): Promise<ProjectNotifications> {
    try {
      return await this.apiCall({
        method: 'GET',
        url: projectId,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async changeEmailNotificationsSwitchers(body: NotificationSwitcherPayload) {
    const { projectId, data } = body;
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `${projectId}/change-email-send-state`,
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async changeSmsNotificationsSwitchers(body: NotificationSwitcherPayload) {
    const { projectId, data } = body;
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `${projectId}/change-sms-send-state`,
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async changeNotificationsRecipients(body: NotificationsRecipientsPayload) {
    const { projectId, data } = body;
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `${projectId}/change-email-receiving-state`,
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async fetchEmailTemplate(body: GetEmailTemplate): Promise<EmailTemplate> {
    const { projectId, emailTemplateId } = body;
    try {
      return await this.apiCall({
        method: 'POST',
        url: `${projectId}/get-email-template`,
        data: { emailTemplateId },
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async restoreEmailTemplate(body: RestoreEmailTemplatePayload): Promise<RestoreEmailTemplate> {
    const { projectId, data } = body;
    try {
      return await this.apiCall({
        method: 'POST',
        url: `${projectId}/restore-email-template`,
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async editEmailTemplate(body: EditEmailTemplatePayload) {
    const { projectId, data } = body;
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `${projectId}/update-email-template`,
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async updateEmailClientSettings(body: UpdateEmailSettingsPayload) {
    const { projectId, data } = body;
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `${projectId}/update-email-client-settings`,
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async sendTestEmail(body: SendTestEmailPayload) {
    const { projectId, email } = body;
    try {
      await this.apiCall({
        method: 'POST',
        url: `${projectId}/send-test-email`,
        data: { email },
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  // SMS
  async getSmsTemplate(body: SmsTemplatePayload): Promise<SmsTemplate> {
    const { projectId, smsTemplateId } = body;
    try {
      return await this.apiCall({
        method: 'POST',
        url: `${projectId}/get-sms-template`,
        data: { smsTemplateId },
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async restoreSmsTemplate(body: RestoreSmsTemplatePayload): Promise<{ markup: string }> {
    const { projectId, data } = body;
    try {
      return await this.apiCall({
        method: 'POST',
        url: `${projectId}/restore-sms-template`,
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async sendTestSms(body: SendTestSmsPayload) {
    const { projectId, phone } = body;
    try {
      await this.apiCall({
        method: 'POST',
        url: `${projectId}/send-test-sms`,
        data: { phone },
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async updateSmsTemplate(body: UpdateSmsPayload) {
    const { projectId, data } = body;
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `${projectId}/update-sms-template`,
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async updateSmsSettings(body: UpdateSmsSettingsPayload) {
    const { projectId, from } = body;
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `${projectId}/update-sms-client-settings`,
        data: { from },
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
