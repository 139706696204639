import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { Product } from '@/@typespaces/interfaces/products.interface';
import { PaginationInfo } from '@/@typespaces/types/paginationInfo';
import Status from '@/@typespaces/enum/status.enum';
import { State } from './state';

export type Getters = {
  getProducts(state: State): Product[];
  getProductsPage(state: State): number;
  getProductsTotal(state: State): number;
  getProductsPageSize(state: State): number;
  getOneProduct(state: State): Product | null;
  getTenant(state: State): string | null;
  getProductById: (state: State) => (id: string) => Product | undefined;
  getProductsLoading(state: State): boolean;
  getProductsPaginationInfo(state: State): PaginationInfo;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getProducts: (state) => state.products,
  getOneProduct: (state) => state.oneProduct,
  getTenant: (state) => state.oneProduct && state.oneProduct.tenant_id,
  getProductById: (state) => (id: string) => state.products.find((product) => product.id === id),
  getProductsLoading: (state) => state.status === Status.LOADING,
  getProductsPage: (state: State) => state.page,
  getProductsTotal: (state: State) => state.count,
  getProductsPageSize: (state: State) => state.limit,
  getProductsPaginationInfo: (state) => ({
    page: state.page,
    pageSize: state.limit,
    total: state.count,
  }),
};
