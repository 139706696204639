import { GetterTree } from 'vuex';
import { RootState } from '@/store';

import { PaginationInfo } from '@/@typespaces/types/paginationInfo';
import Status from '@/@typespaces/enum/status.enum';
import { IAuthor, INewsAndBlog, INewsAndBlogSettings } from '@/@typespaces/interfaces/newsAndBlog.interfaces';
import { State } from './state';

export type Getters = {
  getNewsAndBlogs(state: State): INewsAndBlog[];
  getNewsAndBlogsSettings(state: State): INewsAndBlogSettings | null;
  getNewsAndBlogsAuthors(state: State): IAuthor[];
  getNewsAndBlogsLoading(state: State): boolean;
  getOneBlog(state: State): INewsAndBlog | null;
  getNewsAndBlogsById: (state: State) => (id: string) => INewsAndBlog | undefined;
  getNewsAndBlogPagination(state: State): PaginationInfo;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getNewsAndBlogs: (state) => state.data,
  getNewsAndBlogsAuthors: (state) => state.authors,
  getNewsAndBlogsSettings: (state) => state.settings,
  getNewsAndBlogsLoading: (state) => state.status === Status.LOADING,
  getOneBlog: (state) => state.currentBlog,
  getNewsAndBlogPagination: (state) => ({
    total: state.data.length,
    page: 1,
    pageSize: 10,
  }),
  getNewsAndBlogsById: (state) => (id: string) => state.data.find((item) => item._id === id),
};
