import Status from '@/@typespaces/enum/status.enum';

export interface State {
  accessToken: string | null;
  email: string | null;
  status: Status;
}

export const state: State = {
  accessToken: null,
  email: null,
  status: Status.IDLE,
};
