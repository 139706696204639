export enum ProductsActionTypes {
  GET_PRODUCTS = 'GET_PRODUCTS',
  GET_ONE_PRODUCT = 'GET_ONE_PRODUCT',
  EDIT_PRODUCT = 'EDIT_PRODUCT',
  CREATE_PRODUCT = 'CREATE_PRODUCT',
  EDIT_MANY_PRODUCTS = 'EDIT_PRODUCTS',
  HIDE_PRODUCT = 'HIDE_PRODUCT',
  SHOW_PRODUCT = 'SHOW_PRODUCT',
  DELETE_PRODUCT = 'DELETE_PRODUCT',
  DELETE_MANY_PRODUCTS = 'DELETE_MANY_PRODUCTS',
  SHOW_MANY_PRODUCTS = 'SHOW_MANY_PRODUCTS',
  HIDE_MANY_PRODUCTS = 'HIDE_MANY_PRODUCTS',
  FILTER_PRODUCT_BY_CATEGORY = 'FILTER_PRODUCT_BY_CATEGORY',
  CLEAR_CURRENT_PRODUCT = 'CLEAR_CURRENT_PRODUCT',
  SELECT_CATEGORY = 'SELECT_CATEGORY',
}
