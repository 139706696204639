import { IRubric, IRubricsData } from '@/@typespaces/interfaces/rubric.interfaces';
import { Query } from '@/@typespaces/types/query.types';
import InstanceHttpClient from './instance';

export default class RubricsApi extends InstanceHttpClient {
  constructor() {
    super('content/categories');
  }

  async fetchRubrics(data: { projectId: string; query: Query }): Promise<IRubricsData> {
    try {
      return await this.apiCall({
        method: 'GET',
        params: data.query,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async createRubric(data: Partial<IRubric>): Promise<IRubric> {
    try {
      return await this.apiCall({
        method: 'POST',
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async editRubric(data: IRubric) {
    try {
      await this.apiCall({
        method: 'PUT',
        url: data._id,
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async removeRubric(id: string) {
    try {
      await this.apiCall({
        method: 'DELETE',
        url: id,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async removeManyRubrics(ids: string[]) {
    try {
      await this.apiCall({
        method: 'GET',
        url: 'https://jsonplaceholder.typicode.com/posts/1/comments', // временно
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
