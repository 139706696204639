import { MutationTree } from 'vuex';
import { IOrder, IOrderData, IOrderStatuses, IUpdateOrderStatuses } from '@/@typespaces/interfaces/orders.interface';
import Status from '@/@typespaces/enum/status.enum';
import { State } from './state';
import { OrdersMutationTypes } from './mutation-types';

export type Mutations<S = State> = {
  [OrdersMutationTypes.GET_ORDERS](state: S, payload: IOrderData): void;
  [OrdersMutationTypes.GET_ONE_ORDER](state: S, payload: IOrder): void;
  [OrdersMutationTypes.ORDER_LOADING](state: S): void;
  [OrdersMutationTypes.ORDER_SUCCEEDED](state: S): void;
  [OrdersMutationTypes.EDIT_ORDER](state: S, payload: IOrder): void;
  [OrdersMutationTypes.UPDATE_ORDER_STATUS](
    state: S,
    payload: { data: IUpdateOrderStatuses; status: IOrderStatuses }
  ): void;
  [OrdersMutationTypes.UPDATE_PAYMENT_STATUS](state: S, payload: IUpdateOrderStatuses): void;
  [OrdersMutationTypes.ORDER_ERROR](state: S, payload: string): void;
  [OrdersMutationTypes.DELETE_ONE_ORDER](state: S, payload: string): void;
  [OrdersMutationTypes.DELETE_MANY_ORDERS](state: S, payload: string[]): void;
  [OrdersMutationTypes.DELETE_PRODUCT_IN_ORDER](state: S, payload: { productId: string; orderId: string }): void;
  [OrdersMutationTypes.DELETE_MANY_PRODUCTS_IN_ORDER](
    state: S,
    payload: { productIds: string[]; orderId: string }
  ): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [OrdersMutationTypes.GET_ORDERS](state: State, data: IOrderData) {
    state.data = data.data.orders;
    state.ordersIds = data.data.ordersIds;
    state.page = data.page;
    state.pageSize = data.pageSize;
    state.total = data.total;
  },
  [OrdersMutationTypes.GET_ONE_ORDER](state: State, data: IOrder) {
    state.oneOrder = data;
  },
  [OrdersMutationTypes.DELETE_ONE_ORDER](state: State, id: string) {
    const removeIndex = state.data.map((item) => item.id).indexOf(id);
    state.data.splice(removeIndex, 1);
  },
  [OrdersMutationTypes.DELETE_MANY_ORDERS](state: State, ids: string[]) {
    state.data = state.data.filter((order) => !ids.includes(order.id));
  },

  [OrdersMutationTypes.UPDATE_ORDER_STATUS](state: State, data) {
    state.data = state.data.map((order) => {
      let newOrder: IOrder = order;
      data.data.statuses.forEach((status) => {
        if (order.id === status.order) {
          newOrder = {
            ...order,
            current_status: status.status,
          };
        }
      });
      if (order.id === newOrder.id) {
        return newOrder;
      }
      return order;
    });
  },
  [OrdersMutationTypes.UPDATE_PAYMENT_STATUS](state: State, data) {
    state.data = state.data.map((order) => {
      let newOrder: IOrder = order;

      data.statuses.forEach((status) => {
        if (order.id === status.order) {
          newOrder = {
            ...order,
            payment_status: status.status,
          };
        }
      });

      if (order.id === newOrder.id) {
        return newOrder;
      }
      return order;
    });
  },

  [OrdersMutationTypes.EDIT_ORDER](state: State, data) {
    state.data = state.data.map((item) => {
      if (item.id === data.id) {
        return data;
      }
      return item;
    });
  },
  [OrdersMutationTypes.DELETE_PRODUCT_IN_ORDER](state: State, data: { productId: string; orderId: string }) {
    if (state.oneOrder) {
      const productIdx = state.oneOrder.products.map((item) => item.id).indexOf(data.productId);
      state.oneOrder.products.splice(productIdx, 1);
    }
  },

  [OrdersMutationTypes.DELETE_MANY_PRODUCTS_IN_ORDER](state: State, data: { productIds: string[]; orderId: string }) {
    state.data = state.data.map((order) => {
      if (order.id === data.orderId) {
        const products = order.products.filter((product) => !data.productIds.includes(product.id));
        return {
          ...order,
          product: products,
        };
      }
      return order;
    });
  },

  // Status
  [OrdersMutationTypes.ORDER_LOADING](state: State) {
    state.orderStatus = Status.LOADING;
  },
  [OrdersMutationTypes.ORDER_SUCCEEDED](state: State) {
    state.orderStatus = Status.SUCCEEDED;
  },
  [OrdersMutationTypes.ORDER_ERROR](state: State, data: string) {
    state.error = data;
    state.orderStatus = Status.IDLE;
  },
};
