import { MutationTree } from 'vuex';
import { Answer, Review, ReviewData, ReviewSettings } from '@/@typespaces/interfaces/review';
import Status from '@/@typespaces/enum/status.enum';
import { State } from './state';
import { ReviewsMutationTypes } from './mutation-types';

export type Mutations<S = State> = {
  [ReviewsMutationTypes.GET_REVIEWS](state: S, payload: ReviewData): void;
  [ReviewsMutationTypes.REMOVE_REVIEW](state: S, payload: string): void;
  [ReviewsMutationTypes.GET_CURRENT_REVIEW](state: S, payload: Review): void;
  [ReviewsMutationTypes.PUBLISH_REVIEW](state: S, payload: string): void;
  [ReviewsMutationTypes.UN_PUBLISH_REVIEW](state: S, payload: string): void;
  [ReviewsMutationTypes.CREATE_REVIEW_ANSWER](state: S, payload: Answer): void;
  [ReviewsMutationTypes.EDIT_REVIEW_ANSWER](state: S, payload: string): void;
  [ReviewsMutationTypes.REMOVE_REVIEW_ANSWER](state: S): void;
  [ReviewsMutationTypes.GET_REVIEW_SETTINGS](state: S, payload: ReviewSettings): void;
  [ReviewsMutationTypes.EDIT_REVIEW_SETTINGS](state: S, payload: ReviewSettings): void;

  // Status
  [ReviewsMutationTypes.REVIEWS_LOADING](state: S): void;
  [ReviewsMutationTypes.REVIEWS_SUCCEEDED](state: S): void;
  [ReviewsMutationTypes.REVIEWS_ERROR](state: S): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [ReviewsMutationTypes.GET_REVIEWS](state: State, payload: ReviewData) {
    state.data = payload.data.reviews;
    state.pagination.page = payload.page;
    state.pagination.pageSize = payload.pageSize;
    state.pagination.total = payload.total;
  },

  [ReviewsMutationTypes.GET_CURRENT_REVIEW](state: State, payload: Review) {
    state.currentReview = payload;
  },

  [ReviewsMutationTypes.REMOVE_REVIEW](state: State, id: string) {
    state.data = state.data.filter((item) => item.id !== id);
    state.pagination.total -= 1;
  },

  [ReviewsMutationTypes.PUBLISH_REVIEW](state: State, payload: string) {
    state.data = state.data.map((item) => {
      if (item.id === payload) {
        return {
          ...item,
          published: true,
        };
      }
      return item;
    });

    if (state.currentReview) {
      state.currentReview = { ...state.currentReview, published: true };
    }
  },

  [ReviewsMutationTypes.UN_PUBLISH_REVIEW](state: State, payload: string) {
    state.data = state.data.map((item) => {
      if (item.id === payload) {
        return {
          ...item,
          published: false,
        };
      }
      return item;
    });

    if (state.currentReview) {
      state.currentReview = { ...state.currentReview, published: false };
    }
  },

  [ReviewsMutationTypes.CREATE_REVIEW_ANSWER](state: State, payload: Answer) {
    if (state.currentReview) {
      state.currentReview = { ...state.currentReview, answer: payload };
    }
  },

  [ReviewsMutationTypes.EDIT_REVIEW_ANSWER](state: State, payload: string) {
    if (state.currentReview && state.currentReview.answer) {
      state.currentReview = {
        ...state.currentReview,
        answer: { ...state.currentReview.answer, text: payload },
      };
    }
  },

  [ReviewsMutationTypes.REMOVE_REVIEW_ANSWER](state: State) {
    if (state.currentReview) {
      state.currentReview = { ...state.currentReview, answer: null };
    }
  },

  [ReviewsMutationTypes.GET_REVIEW_SETTINGS](state: State, payload: ReviewSettings) {
    state.settings = payload;
  },

  [ReviewsMutationTypes.EDIT_REVIEW_SETTINGS](state: State, payload: ReviewSettings) {
    state.settings = payload;
  },

  // Status
  [ReviewsMutationTypes.REVIEWS_ERROR](state: State) {
    state.status = Status.FAILED;
  },
  [ReviewsMutationTypes.REVIEWS_SUCCEEDED](state: State) {
    state.status = Status.SUCCEEDED;
  },
  [ReviewsMutationTypes.REVIEWS_LOADING](state: State) {
    state.status = Status.LOADING;
  },
};
