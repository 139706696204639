import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store';
import { Profile } from '@/@typespaces/interfaces/profile.interface';
import ProfileClient from '@/api/profile.api';
import { State } from './state';
import { Mutations } from './mutations';
import { ProfileMutationTypes } from './mutation-types';
import { ProfileActionTypes } from './action-types';

const client = new ProfileClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [ProfileActionTypes.FETCH_PROFILE_DATA]({ commit }: AugmentedActionContext, email: string): void;

  [ProfileActionTypes.EDIT_PROFILE]({ commit }: AugmentedActionContext, data: Profile): void;

  [ProfileActionTypes.FETCH_USER_ROLE_FOR_PROJECT]({ commit }: AugmentedActionContext, payload: string): void;

  [ProfileActionTypes.FETCH_USER_ROLE_FOR_DASHBOARD]({ commit }: AugmentedActionContext): void;

  [ProfileActionTypes.CLEAR_PROFILE_DATA]({ commit }: AugmentedActionContext): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ProfileActionTypes.FETCH_PROFILE_DATA]({ commit }, email: string) {
    try {
      const data = await client.getProfile(email);
      commit(ProfileMutationTypes.FETCH_PROFILE_DATA, data);
    } catch (e) {
      throw new Error(e as string);
    }
  },
  async [ProfileActionTypes.EDIT_PROFILE]({ commit }, data: Profile) {
    try {
      const response = await client.editProfile(data);
      if (response.success) {
        commit(ProfileMutationTypes.EDIT_PROFILE, data);
      }
    } catch (e) {
      throw new Error(e as string);
    }
  },
  async [ProfileActionTypes.FETCH_USER_ROLE_FOR_PROJECT]({ commit }, payload: string) {
    try {
      const data = await client.getUserRoleForProject(payload);
      commit(ProfileMutationTypes.FETCH_USER_ROLE_FOR_PROJECT, data);
    } catch (error) {
      throw new Error(error as string);
    }
  },
  async [ProfileActionTypes.FETCH_USER_ROLE_FOR_DASHBOARD]({ commit }) {
    try {
      const data = await client.getUserRoleForDashboard();
      commit(ProfileMutationTypes.FETCH_USER_ROLE_FOR_DASHBOARD, data);
    } catch (error) {
      throw new Error(error as string);
    }
  },
  [ProfileActionTypes.CLEAR_PROFILE_DATA]({ commit }) {
    commit(ProfileMutationTypes.CLEAR_PROFILE_DATA);
  },
};
