import { ThirdPartyCodePayload } from '@/@typespaces/types/form/thirdPartyCode';
import InstanceHttpClient from './instance';

export default class ThirdPartyCodeClient extends InstanceHttpClient {
  constructor() {
    super('third-party-code');
  }

  async editCode(body: ThirdPartyCodePayload) {
    const { projectId, data } = body;
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `/${projectId}`,
        data,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
