import { createLogger, createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';
import { AuthStore, State as IAuthState, store as auth } from './modules/auth';
import { ProfileStore, State as IProfileStore, store as profile } from './modules/profile';
import { ProjectsStore, State as IProjects, store as projects } from './modules/projects';
import { CommentsStore, State as ICommentStore, store as comments } from './modules/comments';
import { ReviewsStore, State as IReviewStore, store as reviews } from './modules/reviews';
import { CustomersStore, State as ICustomersStore, store as customers } from './modules/customers';
import { OrdersStore, State as IOrdersStore, store as orders } from './modules/orders';
import { NotificationStore, State as INotificationStore, store as notification } from './modules/notification';
import { RolesAndAccessStore, State as IRolesAndAccessStore, store as rolesAndAccess } from './modules/rolesAndAccess';
import { ProductsStore, State as IProductsStore, store as products } from './modules/products';
import { CategoriesStore, State as ICategoriesStore, store as categories } from './modules/categories';
import { WarehousesStore, State as IWarehousesStore, store as warehouses } from './modules/warehouses';
import { LocationStore, State as ILocationStore, store as location } from './modules/location';
import { ServiceStore, State as IServiceStore, store as service } from './modules/service';
import { NewsAndBlogStore, State as INewsAndBlogStore, store as newsAndBlog } from './modules/newsAndBlog';
import { RubricsStore, State as IRubricsStore, store as rubrics } from './modules/rubrics';
import {
  ProjectSettingsStore,
  State as IProjectSettingsStore,
  store as projectSettings,
} from './modules/projectSettings';
import { IdentityStore, State as IIdentityStore, store as identity } from './modules/identity';
import { TemplatesStore, State as ITemplatesStore, store as templates } from './modules/templates';
import { UsersStore, State as IUsersStore, store as users } from './modules/users';
import { DomainStore, State as IDomainStore, store as domain } from './modules/domain';
import { MediaStore, State as IMediaStore, store as media } from './modules/media';
import { DeliveryStore, State as IDeliveryStore, store as delivery } from './modules/delivery';
import { StatisticsStore, State as IStatisticsStore, store as statistics } from './modules/statistics';

export type RootState = {
  auth: IAuthState;
  projects: IProjects;
  profile: IProfileStore;
  comments: ICommentStore;
  reviews: IReviewStore;
  customers: ICustomersStore;
  orders: IOrdersStore;
  notification: INotificationStore;
  rolesAndAccess: IRolesAndAccessStore;
  products: IProductsStore;
  categories: ICategoriesStore;
  warehouses: IWarehousesStore;
  projectSettings: IProjectSettingsStore;
  location: ILocationStore;
  service: IServiceStore;
  newsAndBlog: INewsAndBlogStore;
  rubrics: IRubricsStore;
  identity: IIdentityStore;
  templates: ITemplatesStore;
  users: IUsersStore;
  domain: IDomainStore;
  media: IMediaStore;
  delivery: IDeliveryStore;
  statistics: IStatisticsStore;
};

export type Store = AuthStore<Pick<RootState, 'auth'>> &
  ProjectsStore<Pick<RootState, 'projects'>> &
  CommentsStore<Pick<RootState, 'comments'>> &
  ReviewsStore<Pick<RootState, 'reviews'>> &
  CustomersStore<Pick<RootState, 'customers'>> &
  OrdersStore<Pick<RootState, 'orders'>> &
  NotificationStore<Pick<RootState, 'notification'>> &
  RolesAndAccessStore<Pick<RootState, 'rolesAndAccess'>> &
  ProductsStore<Pick<RootState, 'products'>> &
  CategoriesStore<Pick<RootState, 'categories'>> &
  ProfileStore<Pick<RootState, 'profile'>> &
  WarehousesStore<Pick<RootState, 'warehouses'>> &
  ProjectSettingsStore<Pick<RootState, 'projectSettings'>> &
  LocationStore<Pick<RootState, 'location'>> &
  ServiceStore<Pick<RootState, 'service'>> &
  NewsAndBlogStore<Pick<RootState, 'newsAndBlog'>> &
  RubricsStore<Pick<RootState, 'rubrics'>> &
  IdentityStore<Pick<RootState, 'identity'>> &
  DomainStore<Pick<RootState, 'domain'>> &
  MediaStore<Pick<RootState, 'media'>> &
  DeliveryStore<Pick<RootState, 'delivery'>> &
  StatisticsStore<Pick<RootState, 'statistics'>> &
  UsersStore<Pick<RootState, 'users'>>;

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state: RootState) => ({
    auth: state.auth,
    projects: state.projects,
    service: state.service,
    profile: state.profile,
    rolesAndAccess: state.rolesAndAccess,
    identity: state.identity,
  }),
});

// Plug in logger when in development environment
const debug = process.env.NODE_ENV !== 'production';
const plugins = debug ? [createLogger({}), vuexLocal.plugin] : [vuexLocal.plugin];

export const store = createStore({
  plugins,
  modules: {
    auth,
    projects,
    profile,
    comments,
    reviews,
    customers,
    orders,
    notification,
    rolesAndAccess,
    products,
    categories,
    warehouses,
    projectSettings,
    location,
    service,
    newsAndBlog,
    rubrics,
    identity,
    templates,
    users,
    domain,
    media,
    delivery,
    statistics,
  },
});

export default function useStore(): Store {
  return store as Store;
}
