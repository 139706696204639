import { DeliveryData } from '@/@typespaces/interfaces/delivery.interface';
import { MutationTree } from 'vuex';
import Status from '@/@typespaces/enum/status.enum';
import { DeliveryMutationTypes } from './mutation-types';
import { State } from './state';

export type Mutations<S = State> = {
  [DeliveryMutationTypes.GET_DELIVERY_DATA](state: S, payload: DeliveryData): void;

  // Status
  [DeliveryMutationTypes.DELIVERY_DATA_LOADING](state: S): void;
  [DeliveryMutationTypes.DELIVERY_DATA_SUCCEEDED](state: S): void;
  [DeliveryMutationTypes.DELIVERY_DATA_ERROR](state: S): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [DeliveryMutationTypes.GET_DELIVERY_DATA](state: State, data: DeliveryData) {
    state.data = data.delivery;
  },

  // Status
  [DeliveryMutationTypes.DELIVERY_DATA_LOADING](state: State) {
    state.status = Status.LOADING;
  },
  [DeliveryMutationTypes.DELIVERY_DATA_SUCCEEDED](state: State) {
    state.status = Status.SUCCEEDED;
  },
  [DeliveryMutationTypes.DELIVERY_DATA_ERROR](state: State) {
    state.status = Status.FAILED;
  },
};
