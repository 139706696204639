<template>
  <div class="loading-container">
    <div class="spin-container">
      <a-spin size="large" />
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.loading-container {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
