import Status from '@/@typespaces/enum/status.enum';
import { EmailTemplate, Notifications, SmsTemplate } from '@/@typespaces/interfaces/notifications.interface';

export interface State {
  data: Notifications | null;
  error: string | null;
  status: Status;
  emailTemplate: EmailTemplate | null;
  smsTemplate: SmsTemplate | null;
}

export const state: State = {
  data: null,
  error: null,
  status: Status.IDLE,
  emailTemplate: null,
  smsTemplate: null,
};
