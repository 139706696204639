import { Project, ShortProject } from '@/@typespaces/interfaces/projects.interface';
import Status from '@/@typespaces/enum/status.enum';

export interface State {
  data: ShortProject[];
  projectsIds: string[];
  page: number;
  pageSize: number;
  total: number;
  currentProject: Project | null;
  error: string | null;
  status: Status;
  currentProjectStatus: Status;
}

export const state: State = {
  data: [],
  projectsIds: [],
  currentProject: null,
  page: 0,
  pageSize: 0,
  total: 0,
  error: null,
  status: Status.IDLE,
  currentProjectStatus: Status.IDLE,
};
