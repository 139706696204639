import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store';
import { ServiceActionTypes } from '@/store/modules/service/action-types';
import { ServiceMutationTypes } from '@/store/modules/service/mutation-types';
import { FiltersQuery } from '@/@typespaces/types/query.types';
import { State } from './state';
import { Mutations } from './mutations';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [ServiceActionTypes.SET_SEARCH_STRING]({ commit }: AugmentedActionContext, payload: string): void;

  [ServiceActionTypes.CLEAR_SEARCH_STRING]({ commit }: AugmentedActionContext): void;

  [ServiceActionTypes.SET_FILTER_VALUE]({ commit }: AugmentedActionContext, payload: FiltersQuery): void;

  [ServiceActionTypes.REMOVE_FILTER_VALUE]({ commit }: AugmentedActionContext, payload: string): void;

  [ServiceActionTypes.CLEAR_FILTER_VALUE]({ commit }: AugmentedActionContext): void;

  [ServiceActionTypes.COLLAPSE_MENU]({ commit }: AugmentedActionContext, payload: boolean): void;

  [ServiceActionTypes.SHOW_CONFIRM_MODAL]({ commit }: AugmentedActionContext, payload: boolean): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  [ServiceActionTypes.SET_SEARCH_STRING]({ commit }, payload: string) {
    commit(ServiceMutationTypes.SET_SEARCH_STRING, payload);
  },
  [ServiceActionTypes.CLEAR_SEARCH_STRING]({ commit }) {
    commit(ServiceMutationTypes.CLEAR_SEARCH_STRING);
  },
  [ServiceActionTypes.SET_FILTER_VALUE]({ commit }, payload: FiltersQuery) {
    commit(ServiceMutationTypes.SET_FILTER_VALUE, payload);
  },
  [ServiceActionTypes.REMOVE_FILTER_VALUE]({ commit }, payload: string) {
    commit(ServiceMutationTypes.REMOVE_FILTER_VALUE, payload);
  },
  [ServiceActionTypes.CLEAR_FILTER_VALUE]({ commit }) {
    commit(ServiceMutationTypes.CLEAR_FILTER_VALUE);
  },
  [ServiceActionTypes.COLLAPSE_MENU]({ commit }, payload: boolean) {
    commit(ServiceMutationTypes.COLLAPSE_MENU, payload);
  },
  [ServiceActionTypes.SHOW_CONFIRM_MODAL]({ commit }, payload: boolean) {
    commit(ServiceMutationTypes.SHOW_CONFIRM_MODAL, payload);
  },
};
