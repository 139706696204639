import { Modal } from 'ant-design-vue';

export const blockOne = <T>(item: T, title: string, cb: (item: T) => void): void => {
  Modal.confirm({
    title,
    okText: 'Да, заблокировать',
    cancelText: 'Отменить',
    onOk: () => cb(item),
    onCancel: () => Modal.destroyAll(),
  });
};

export const unBlockOne = <T>(item: T, title: string, cb: (item: T) => void): void => {
  Modal.confirm({
    title,
    okText: 'Да, разблокировать',
    cancelText: 'Отменить',
    onOk: () => cb(item),
    onCancel: () => Modal.destroyAll(),
  });
};

export const deleteOne = <T>(
  item: T,
  title: string,
  cb: (item: T) => void,
  content?: string,
  okText?: string
): void => {
  Modal.confirm({
    title,
    content: content || 'Это действие невозможно отменить',
    okText: okText || 'Да, удалить',
    cancelText: 'Отменить',
    onOk: () => cb(item),
    onCancel: () => Modal.destroyAll(),
  });
};

export const deleteAll = (title: string, cb: () => void): void => {
  Modal.confirm({
    title,
    content: 'Это действие невозможно отменить',
    okText: 'Да, удалить',
    cancelText: 'Отменить',
    onOk: () => cb(),
    onCancel: () => Modal.destroyAll(),
  });
};

export const updateOne = (title: string, cb: () => void): void => {
  Modal.confirm({
    title,
    content: 'Это действие невозможно отменить',
    okText: 'Подтвердить',
    cancelText: 'Отменить',
    onOk: () => cb(),
    onCancel: () => Modal.destroyAll(),
  });
};

export const blockAll = (title: string, cb: () => void): void => {
  Modal.confirm({
    title,
    okText: 'Да, заблокировать',
    cancelText: 'Отменить',
    onOk: () => cb(),
    onCancel: () => Modal.destroyAll(),
  });
};

export const unBlockAll = (title: string, cb: () => void): void => {
  Modal.confirm({
    title,
    okText: 'Да, разблокировать',
    cancelText: 'Отменить',
    onOk: () => cb(),
    onCancel: () => Modal.destroyAll(),
  });
};

export const showAll = (title: string, cb: () => void): void => {
  Modal.confirm({
    title,
    okText: 'Да, показать',
    cancelText: 'Отменить',
    onOk: () => cb(),
    onCancel: () => Modal.destroyAll(),
  });
};

export const copyOne = (title: string, cb: () => void, okText?: string): void => {
  Modal.confirm({
    title,
    okText: okText || 'Да, дублировать',
    cancelText: 'Отменить',
    onOk: () => cb(),
    onCancel: () => Modal.destroyAll(),
  });
};
export const publicOne = (title: string, cb: () => void): void => {
  Modal.confirm({
    title,
    okText: 'Да, опубликовать',
    cancelText: 'Отменить',
    onOk: () => cb(),
    onCancel: () => Modal.destroyAll(),
  });
};
export const unPublicOne = (title: string, cb: () => void): void => {
  Modal.confirm({
    title,
    okText: 'Да, снять с публикации',
    cancelText: 'Отменить',
    onOk: () => cb(),
    onCancel: () => Modal.destroyAll(),
  });
};
export const hideAll = (title: string, cb: () => void): void => {
  Modal.confirm({
    title,
    okText: 'Да, скрыть',
    cancelText: 'Отменить',
    onOk: () => cb(),
    onCancel: () => Modal.destroyAll(),
  });
};

export const leavePage = (cb: (arg?: boolean) => void) => {
  Modal.confirm({
    title: 'Вы уверены, что хотите покинуть страницу?',
    content: 'Данные будут потеряны',
    okText: 'Да, покинуть',
    cancelText: 'Отменить',
    onOk: () => cb(),
    onCancel: () => {
      cb(false);
      Modal.destroyAll();
    },
  });
};
