// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum OrdersActionTypes {
  GET_ORDERS = 'GET_ORDERS',
  GET_ONE_ORDER = 'GET_ONE_ORDER',
  CREATE_ORDER = 'CREATE_ORDER',
  DELETE_MANY_ORDERS = 'DELETE_MANY_ORDERS',
  DELETE_ONE_ORDER = 'DELETE_ONE_ORDER',
  UPDATE_PAYMENT_STATUS = 'UPDATE_PAYMENT_STATUS',
  UPDATE_ORDER_STATUS = 'UPDATE_ORDER_STATUS',
  DELETE_MANY_PRODUCTS_IN_ORDER = 'DELETE_MANY_PRODUCTS_IN_ORDER',
  DELETE_PRODUCT_IN_ORDER = 'DELETE_PRODUCT_IN_ORDER',
  EDIT_ORDER = 'EDIT_ORDER',
}
