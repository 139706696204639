import { DeliveryData } from '@/@typespaces/interfaces/delivery.interface';
import DeliveryApi from '@/api/delivery.api';
import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store';
import { DeliveryActionTypes } from './action-types';
import { DeliveryMutationTypes } from './mutation-types';
import { State } from './state';
import { Mutations } from './mutations';

const api = new DeliveryApi();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [DeliveryActionTypes.GET_DELIVERY_DATA]({ commit }: AugmentedActionContext): void;

  [DeliveryActionTypes.EDIT_DELIVERY_DATA]({ commit }: AugmentedActionContext, payload: DeliveryData): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [DeliveryActionTypes.GET_DELIVERY_DATA]({ commit }) {
    commit(DeliveryMutationTypes.DELIVERY_DATA_LOADING);
    try {
      const data = await api.getDeliveryData();
      commit(DeliveryMutationTypes.GET_DELIVERY_DATA, data);
      commit(DeliveryMutationTypes.DELIVERY_DATA_SUCCEEDED);
    } catch (error) {
      commit(DeliveryMutationTypes.DELIVERY_DATA_ERROR);
      throw new Error(error as string);
    }
  },

  async [DeliveryActionTypes.EDIT_DELIVERY_DATA]({ commit }, payload: DeliveryData) {
    try {
      await api.editDeliveryData(payload);
    } catch (error) {
      commit(DeliveryMutationTypes.DELIVERY_DATA_ERROR);
      throw new Error(error as string);
    }
  },
};
