import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store';
import LocationApi from '@/api/location.api';
import { LocationActionTypes } from '@/store/modules/location/action-types';
import { LocationsMutationTypes } from '@/store/modules/location/mutation-types';
import { State } from './state';
import { Mutations } from './mutations';

const api = new LocationApi();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [LocationActionTypes.GET_LOCATIONS]({ commit }: AugmentedActionContext): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [LocationActionTypes.GET_LOCATIONS]({ commit }) {
    commit(LocationsMutationTypes.LOCATIONS_LOADING);
    try {
      const data = await api.getLocations();
      commit(LocationsMutationTypes.GET_LOCATIONS, data);
      commit(LocationsMutationTypes.LOCATIONS_SUCCEEDED);
    } catch (e) {
      commit(LocationsMutationTypes.LOCATIONS_ERROR, e as string);
      throw new Error(e as string);
    }
  },
};
