export enum NotificationActionTypes {
  FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS',
  EDIT_EMAIL_NOTIFICATION_SWITCHER = 'EDIT_EMAIL_NOTIFICATION_SWITCHER',
  EDIT_SMS_NOTIFICATION_SWITCHER = 'EDIT_SMS_NOTIFICATION_SWITCHER',
  EDIT_EMAIL_RECIPIENTS = 'EDIT_EMAIL_RECIPIENTS',
  FETCH_EMAIL_TEMPLATE = 'FETCH_EMAIL_TEMPLATE',
  RESTORE_EMAIL_TEMPLATE_SUBJECT = 'RESTORE_EMAIL_TEMPLATE_SUBJECT',
  RESTORE_EMAIL_TEMPLATE_MARKUP = 'RESTORE_EMAIL_TEMPLATE_MARKUP',
  EDIT_EMAIL_TEMPLATE = 'EDIT_EMAIL_TEMPLATE',
  UPDATE_EMAIL_TEMPLATE_SETTINGS = 'UPDATE_EMAIL_TEMPLATE_SETTINGS',
  SEND_TEST_EMAIL = 'SEND_TEST_EMAIL',
  CLEAR_EMAIL_TEMPLATE = 'CLEAR_EMAIL_TEMPLATE',

  // Sms
  GET_SMS_TEMPLATE = 'GET_SMS_TEMPLATE',
  RESTORE_SMS_TEMPLATE = 'RESTORE_SMS_TEMPLATE',
  SEND_TEST_SMS = 'SEND_TEST_SMS',
  UPDATE_SMS_TEMPLATE = 'UPDATE_SMS_TEMPLATE',
  UPDATE_SMS_SETTINGS = 'UPDATE_SMS_SETTINGS',
  CLEAR_SMS_TEMPLATE = 'CLEAR_SMS_TEMPLATE',
}
