import Status from '@/@typespaces/enum/status.enum';
import { MainDomain } from '@/@typespaces/interfaces/domain.interface';
import { MutationTree } from 'vuex';
import { DomainMutationTypes } from './mutation-types';
import { State } from './state';

export type Mutations<S = State> = {
  [DomainMutationTypes.CONNECT_DOMAIN](state: S, payload: MainDomain): void;
  [DomainMutationTypes.CHECK_HTTPS_ACCESS](state: S, payload: MainDomain): void;
  [DomainMutationTypes.REMOVE_DOMAIN](state: S): void;
  [DomainMutationTypes.CLEAR_DOMAIN_STORE](state: S): void;

  // Status
  [DomainMutationTypes.DOMAIN_ERROR](state: S, payload: string): void;
  [DomainMutationTypes.DOMAIN_SUCCEEDED](state: S): void;
  [DomainMutationTypes.DOMAIN_LOADING](state: S): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [DomainMutationTypes.CONNECT_DOMAIN](state: State, data: MainDomain) {
    state.domain = data;
  },
  [DomainMutationTypes.CHECK_HTTPS_ACCESS](state: State, data: MainDomain) {
    state.domain = data;
  },
  [DomainMutationTypes.REMOVE_DOMAIN](state: State) {
    state.domain = null;
  },
  [DomainMutationTypes.CLEAR_DOMAIN_STORE](state: State) {
    state.domain = null;
  },

  // Status
  [DomainMutationTypes.DOMAIN_LOADING](state) {
    state.status = Status.LOADING;
  },
  [DomainMutationTypes.DOMAIN_SUCCEEDED](state) {
    state.status = Status.SUCCEEDED;
  },
  [DomainMutationTypes.DOMAIN_ERROR](state: State, error: string) {
    state.error = error;
    state.status = Status.IDLE;
  },
};
