import { Query } from '@/@typespaces/types/query.types';
import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store';
import CommentsClient from '@/api/comments.api';
import { IAnswer, IComment } from '@/@typespaces/interfaces/comment.interface';
import { State } from './state';
import { Mutations } from './mutations';
import { CommentMutationTypes } from './mutation-types';
import { CommentActionTypes } from './action-types';

const client = new CommentsClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [CommentActionTypes.GET_COMMENTS]({ commit }: AugmentedActionContext, payload: { query: Query }): void;
  [CommentActionTypes.GET_ONE_COMMENT]({ commit }: AugmentedActionContext, payload: string): void;
  [CommentActionTypes.CREATE_COMMENT]({ commit }: AugmentedActionContext, payload: Partial<IComment>): void;
  [CommentActionTypes.REMOVE_COMMENT]({ commit }: AugmentedActionContext, payload: string): void;
  [CommentActionTypes.CREATE_ANSWER](
    { commit }: AugmentedActionContext,
    payload: { id: string; answer: IAnswer }
  ): void;
  [CommentActionTypes.SET_COMMENT_STATUS](
    { commit }: AugmentedActionContext,
    payload: { id: string; status: string }
  ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [CommentActionTypes.GET_COMMENTS]({ commit }, payload: { query: Query }) {
    commit(CommentMutationTypes.COMMENTS_LOADING);
    try {
      const data = await client.getComments(payload);
      commit(CommentMutationTypes.GET_COMMENTS, data);
      commit(CommentMutationTypes.COMMENTS_SUCCEEDED);
    } catch (error) {
      commit(CommentMutationTypes.COMMENTS_ERROR, String(error));
      throw new Error(String(error));
    }
  },
  async [CommentActionTypes.GET_ONE_COMMENT]({ commit }, payload: string) {
    commit(CommentMutationTypes.COMMENTS_LOADING);
    try {
      const data = await client.getOneComment(payload);
      commit(CommentMutationTypes.GET_ONE_COMMENT, data);
      commit(CommentMutationTypes.COMMENTS_SUCCEEDED);
    } catch (error) {
      commit(CommentMutationTypes.COMMENTS_ERROR, String(error));
      throw new Error(String(error));
    }
  },
  async [CommentActionTypes.CREATE_COMMENT]({ commit }, payload: Partial<IComment>) {
    commit(CommentMutationTypes.COMMENTS_LOADING);
    try {
      const data = await client.createComment(payload);
      commit(CommentMutationTypes.CREATE_COMMENT, data);
      commit(CommentMutationTypes.COMMENTS_SUCCEEDED);
    } catch (error) {
      commit(CommentMutationTypes.COMMENTS_ERROR, String(error));
      throw new Error(String(error));
    }
  },
  async [CommentActionTypes.REMOVE_COMMENT]({ commit }, payload: string) {
    commit(CommentMutationTypes.COMMENTS_LOADING);
    try {
      await client.removeComment(payload);
      commit(CommentMutationTypes.REMOVE_COMMENT, payload);
      commit(CommentMutationTypes.COMMENTS_SUCCEEDED);
    } catch (error) {
      commit(CommentMutationTypes.COMMENTS_ERROR, String(error));
      throw new Error(String(error));
    }
  },
  async [CommentActionTypes.CREATE_ANSWER]({ commit }, payload: { id: string; answer: IAnswer }) {
    commit(CommentMutationTypes.COMMENTS_LOADING);
    try {
      await client.addAnswer(payload);
      commit(CommentMutationTypes.CREATE_ANSWER, payload);
      commit(CommentMutationTypes.COMMENTS_SUCCEEDED);
    } catch (error) {
      commit(CommentMutationTypes.COMMENTS_ERROR, String(error));
      throw new Error(String(error));
    }
  },
  async [CommentActionTypes.SET_COMMENT_STATUS]({ commit }, payload: { id: string; status: string }) {
    commit(CommentMutationTypes.COMMENTS_LOADING);
    try {
      await client.updateStatusComment(payload);
      commit(CommentMutationTypes.SET_COMMENT_STATUS, payload);
      commit(CommentMutationTypes.COMMENTS_SUCCEEDED);
    } catch (error) {
      commit(CommentMutationTypes.COMMENTS_ERROR, String(error));
      throw new Error(String(error));
    }
  },
};
