import { IRolesAndAccessData } from '@/@typespaces/interfaces/rolesAndAccess.interface';
import { Query } from '@/@typespaces/types/query.types';
import InstanceHttpClient from './instance';

export default class RolesAndAccessClient extends InstanceHttpClient {
  constructor() {
    super('roles-and-access');
  }

  async getUsers(data: { projectId: string; query: Query }): Promise<IRolesAndAccessData> {
    const { projectId, query } = data;
    try {
      return await this.apiCall({
        method: 'GET',
        params: query,
        url: `${projectId}/users`,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async inviteUser(data: { projectId: string; invite: { email: string; roleId: string } }): Promise<{ success: true }> {
    const { projectId, invite } = data;
    try {
      return await this.apiCall({
        method: 'POST',
        url: `${projectId}/users/invite`,
        data: invite,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async deleteUser(data: { projectId: string; id: string }) {
    const { projectId, id } = data;
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `${projectId}/users/${id}/remove`,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async blockUser(data: { projectId: string; id: string }) {
    const { projectId, id } = data;
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `${projectId}/users/${id}/block`,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async unBlockUser(data: { projectId: string; id: string }) {
    const { projectId, id } = data;
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `${projectId}/users/${id}/unblock`,
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }

  async changeUserRole(data: { userId: string; roleId: string; projectId: string }) {
    const { userId, roleId, projectId } = data;
    try {
      await this.apiCall({
        method: 'PATCH',
        url: `${projectId}/users/${userId}/change-role`,
        data: { roleId },
      });
    } catch (err) {
      throw new Error(err as string);
    }
  }
}
