import { IRole } from '@/@typespaces/interfaces/rolesAndAccess.interface';
import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { Profile } from '@/@typespaces/interfaces/profile.interface';
import { State } from './state';

export type Getters = {
  getProfile(state: State): Profile | null;
  getProfileConfirm(state: State): boolean;
  getUserRole(state: State): IRole | null;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getProfile: (state) => state.data,
  getProfileConfirm: (state) => state.data?.isConfirm || false,
  getUserRole: (state) => state.role,
};
