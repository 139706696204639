import RoleClient from '@/api/role.api';
import { ActionContext, ActionTree } from 'vuex';
import { RootState } from '@/store';
import { RolesAndAccessActionTypes } from '@/store/modules/rolesAndAccess/action-types';
import RolesAndAccessClient from '@/api/rolesAndAccess.api';
import { RolesAndAccessMutationTypes } from '@/store/modules/rolesAndAccess/mutation-types';
import { Query } from '@/@typespaces/types/query.types';
import { State } from './state';
import { Mutations } from './mutations';

const client = new RolesAndAccessClient();
const rolesClient = new RoleClient();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>;

export interface Actions {
  [RolesAndAccessActionTypes.FETCH_USERS](
    { commit }: AugmentedActionContext,
    data: { projectId: string; query: Query }
  ): void;

  [RolesAndAccessActionTypes.GET_ROLES_PROJECT_GROUP]({ commit }: AugmentedActionContext): void;

  [RolesAndAccessActionTypes.INVITE_USER](
    { commit }: AugmentedActionContext,
    data: { projectId: string; invite: { email: string; roleId: string } }
  ): void;

  [RolesAndAccessActionTypes.DELETE_ONE_USER](
    { commit }: AugmentedActionContext,
    data: { projectId: string; id: string }
  ): void;

  [RolesAndAccessActionTypes.BLOCK_USER](
    { commit }: AugmentedActionContext,
    data: { projectId: string; id: string }
  ): void;

  [RolesAndAccessActionTypes.UNBLOCK_USER](
    { commit }: AugmentedActionContext,
    data: { projectId: string; id: string }
  ): void;

  [RolesAndAccessActionTypes.EDIT_USER_ROLE](
    { commit }: AugmentedActionContext,
    data: { userId: string; roleId: string; projectId: string }
  ): void;

  [RolesAndAccessActionTypes.CLEAR_USER_ROLE]({ commit }: AugmentedActionContext): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [RolesAndAccessActionTypes.FETCH_USERS]({ commit }, payload: { projectId: string; query: Query }) {
    commit(RolesAndAccessMutationTypes.USERS_LOADING);
    try {
      const data = await client.getUsers(payload);
      commit(RolesAndAccessMutationTypes.FETCH_USERS, data);
      commit(RolesAndAccessMutationTypes.USERS_SUCCEEDED);
    } catch (e) {
      commit(RolesAndAccessMutationTypes.USERS_ERROR, e as string);
      throw new Error();
    }
  },
  async [RolesAndAccessActionTypes.GET_ROLES_PROJECT_GROUP]({ commit }) {
    try {
      const data = await rolesClient.getProjectGroupRoles();
      commit(RolesAndAccessMutationTypes.GET_ROLES_PROJECT_GROUP, data);
    } catch (e) {
      commit(RolesAndAccessMutationTypes.USERS_ERROR, e as string);
      throw new Error();
    }
  },
  [RolesAndAccessActionTypes.CLEAR_USER_ROLE]({ commit }) {
    commit(RolesAndAccessMutationTypes.CLEAR_USER_ROLE);
  },
  async [RolesAndAccessActionTypes.INVITE_USER](
    { commit },
    data: { projectId: string; invite: { email: string; roleId: string } }
  ) {
    try {
      const response = await client.inviteUser(data);
      if (response.success) {
        commit(RolesAndAccessMutationTypes.INVITE_USER);
      }
    } catch (e) {
      commit(RolesAndAccessMutationTypes.USERS_ERROR, e as string);
      throw new Error();
    }
  },

  async [RolesAndAccessActionTypes.DELETE_ONE_USER]({ commit }, data: { projectId: string; id: string }) {
    commit(RolesAndAccessMutationTypes.USERS_LOADING);
    try {
      await client.deleteUser(data);
      commit(RolesAndAccessMutationTypes.DELETE_ONE_USER, data.id);
      commit(RolesAndAccessMutationTypes.USERS_SUCCEEDED);
    } catch (e) {
      commit(RolesAndAccessMutationTypes.USERS_ERROR, e as string);
      throw new Error();
    }
  },

  async [RolesAndAccessActionTypes.BLOCK_USER]({ commit }, data: { projectId: string; id: string }) {
    commit(RolesAndAccessMutationTypes.USERS_LOADING);
    try {
      await client.blockUser(data);
      commit(RolesAndAccessMutationTypes.BLOCK_USER, data.id);
      commit(RolesAndAccessMutationTypes.USERS_SUCCEEDED);
    } catch (e) {
      commit(RolesAndAccessMutationTypes.USERS_ERROR, e as string);
      throw new Error();
    }
  },
  async [RolesAndAccessActionTypes.UNBLOCK_USER]({ commit }, data: { projectId: string; id: string }) {
    commit(RolesAndAccessMutationTypes.USERS_LOADING);
    try {
      await client.unBlockUser(data);
      commit(RolesAndAccessMutationTypes.UNBLOCK_USER, data.id);
      commit(RolesAndAccessMutationTypes.USERS_SUCCEEDED);
    } catch (e) {
      commit(RolesAndAccessMutationTypes.USERS_ERROR, e as string);
      throw new Error();
    }
  },

  async [RolesAndAccessActionTypes.EDIT_USER_ROLE](
    { commit },
    data: { userId: string; roleId: string; projectId: string }
  ) {
    try {
      await client.changeUserRole(data);
      commit(RolesAndAccessMutationTypes.EDIT_USER_ROLE, data);
    } catch (e) {
      commit(RolesAndAccessMutationTypes.USERS_ERROR, e as string);
      throw new Error();
    }
  },
};
