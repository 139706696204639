// eslint-disable-next-line import/prefer-default-export
export const convertBase64ToFileHelper = (base64str: string, filename: string, mimetype: string): [string, File] => {
  const byteCharacters = atob(base64str);
  const byteNumbers = new Array(byteCharacters.length);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: mimetype });
  return [byteCharacters, new File([blob], filename, { type: mimetype })];
};
