import Status from '@/@typespaces/enum/status.enum';
import { Identity } from '@/@typespaces/interfaces/identity.interface';

export interface State {
  error: string | null;
  status: Status;
  identity: Identity | null;
}

export const state: State = {
  identity: null,
  error: null,
  status: Status.IDLE,
};
