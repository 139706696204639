import Status from '@/@typespaces/enum/status.enum';
import { Delivery } from '@/@typespaces/interfaces/delivery.interface';

export interface State {
  data: Delivery[];
  status: Status;
}

export const state: State = {
  data: [],
  status: Status.IDLE,
};
