import { ProjectType } from '@/@typespaces/enum/projectType.enum';
import Status from '@/@typespaces/enum/status.enum';
import { Contacts } from '@/@typespaces/interfaces/contacts.interface';
import { Notifications } from '@/@typespaces/interfaces/notifications.interface';
import { Project, ShortProject } from '@/@typespaces/interfaces/projects.interface';
import { Seo, Template } from '@/@typespaces/interfaces/template.interface';
import { ThirdPartyCode } from '@/@typespaces/interfaces/thirdPartyCode.interface';
import { PaginationInfo } from '@/@typespaces/types/paginationInfo';
import { RootState } from '@/store';
import { GetterTree } from 'vuex';
import { State } from './state';

export type Getters = {
  getProjects(state: State): ShortProject[];
  getProjectsPaginationInfo(state: State): PaginationInfo;
  getCurrentProject(state: State): Project | null;
  getCurrentProjectTemplate(state: State): Template | undefined;
  getCurrentProjectTenant(state: State): string | undefined;
  getCurrentProjectStatus(state: State): boolean;
  getProjectsLoading(state: State): boolean;
  getCurrentProjectsLoading(state: State): boolean;
  getTotalProject(state: State): number;

  // Notifications
  getCurrentProjectNotifications(state: State): Notifications | undefined;

  // ThirdPartyCode
  getCurrentProjectThirdPartyCode(state: State): ThirdPartyCode | undefined;

  // seo
  getCurrentProjectSeo(state: State): Seo | undefined;

  // Contacts
  getCurrentProjectContacts(state: State): Contacts | undefined;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getProjects: (state) => state.data,
  getProjectsLoading: (state) => state.status === Status.LOADING,
  getCurrentProjectsLoading: (state) => state.currentProjectStatus === Status.LOADING,
  getCurrentProject: (state) => state.currentProject,
  getCurrentProjectTemplate: (state) => state.currentProject?.template,
  getCurrentProjectTenant: (state) => state.currentProject?.project_tenant_id,
  getIsCurrentProjectBlog: (state) => {
    if (state.currentProject) {
      return state.currentProject?.modules.includes(ProjectType.BLOG);
    }
    return false;
  },
  getIsCurrentProjectCommerce: (state) => {
    if (state.currentProject) {
      return state.currentProject?.modules.includes(ProjectType.E_COMMERCE);
    }
    return false;
  },
  getIsCurrentProjectLanding: (state) => {
    if (state.currentProject) {
      return state.currentProject?.modules.length === 0;
    }
    return false;
  },
  getCurrentProjectStatus: (state) => {
    if (state.currentProject) {
      return state.currentProject.blocked;
    }
    return false;
  },
  getTotalProject: (state) => state.total,
  getProjectsPaginationInfo: (state) => ({
    page: state.page,
    pageSize: state.pageSize,
    total: state.total,
  }),

  // Notifications
  getCurrentProjectNotifications: (state) => state.currentProject?.notifications,

  // ThirdPartyCode
  getCurrentProjectThirdPartyCode: (state) => state.currentProject?.template.third_party_code,

  // Seo
  getCurrentProjectSeo: (state) => state.currentProject?.template.seo,

  // Contacts
  getCurrentProjectContacts: (state) => state.currentProject?.contacts,
};
