import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { IProjectSettings } from '@/@typespaces/interfaces/projectSettings.interface';
import { State } from './state';

export type Getters = {
  getProjectSettings(state: State): IProjectSettings | null;
};

export const getters: GetterTree<State, RootState> & Getters = {
  getProjectSettings: (state) => state.data,
};
