import { IRole } from '@/@typespaces/interfaces/rolesAndAccess.interface';
import { MutationTree } from 'vuex';
import { Profile } from '@/@typespaces/interfaces/profile.interface';
import { State } from './state';
import { ProfileMutationTypes } from './mutation-types';

export type Mutations<S = State> = {
  [ProfileMutationTypes.FETCH_PROFILE_DATA](state: S, payload: Profile): void;
  [ProfileMutationTypes.EDIT_PROFILE](state: S, payload: Profile): void;
  [ProfileMutationTypes.FETCH_USER_ROLE_FOR_PROJECT](state: S, payload: IRole): void;
  [ProfileMutationTypes.FETCH_USER_ROLE_FOR_DASHBOARD](state: S, payload: IRole): void;
  [ProfileMutationTypes.CLEAR_PROFILE_DATA](state: S): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [ProfileMutationTypes.FETCH_PROFILE_DATA](state: State, data: Profile) {
    state.data = { ...data };
  },

  [ProfileMutationTypes.EDIT_PROFILE](state: State, data: Profile) {
    state.data = { ...state.data, ...data };
  },
  [ProfileMutationTypes.FETCH_USER_ROLE_FOR_PROJECT](state: State, data: IRole) {
    state.role = data;
  },
  [ProfileMutationTypes.FETCH_USER_ROLE_FOR_DASHBOARD](state: State, data: IRole) {
    state.role = data;
  },
  [ProfileMutationTypes.CLEAR_PROFILE_DATA](state: State) {
    state.role = null;
    state.data = null;
  },
};
