import Status from '@/@typespaces/enum/status.enum';
import { Category, CategoryFull } from '@/@typespaces/interfaces/category.interface';

export interface State {
  data: Category[];
  category: CategoryFull | null;
  status: Status;
  selectedCategory: string | null;
  categoryForExpand: string | null;
  searchCategoriesExpand: string[] | null;
}

export const state: State = {
  data: [],
  category: null,
  status: Status.IDLE,
  selectedCategory: null,
  categoryForExpand: null,
  searchCategoriesExpand: null,
};
